import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { IPharmacy } from '../../../../interface/PharmacyInterface';
import { endpoints } from '../../../../utils/URL';
import { addToast } from '../../../../utils/toastNotifications';
import { AppContext } from '../../../../context/AppContext';
import Loader from '../../../common/Loader';

const PharmacyDetails = () => {
  const [pharmacy, setPharmacy] = useState<IPharmacy | undefined>();
  const { appState } = useContext(AppContext);

  const getPharmacy = useCallback(async () => {
    if (appState.selectedPharmacy.pharmacyId === 0) {
      return;
    }

    const pharmacyId = appState.selectedPharmacy.pharmacyId;
    const url = `${endpoints.pharmacies.mainUrl}/${pharmacyId}`;
    try {
      const response = await axios.get(url);
      setPharmacy(response.data);
    } catch (error) {
      addToast('An error occured');
    }
  }, [appState.selectedPharmacy]);

  useEffect(() => {
    getPharmacy();
  }, [getPharmacy]);

  return (
    <section>
      <h3>Pharmacy details</h3>
      {pharmacy ? (
        <div className='card prescription-card'>
          <div className='card-body'>
            <div className='row'>
              <div className='form-group col-sm-4'>
                <label>Pharmacy Code</label>
                <h6 className='card-title '>{pharmacy.pharmacyCode}</h6>
              </div>
              <div className='form-group col-sm-4'>
                <label>Pharmacy name</label>
                <h6 className='card-title '>{pharmacy.pharmacyName}</h6>
              </div>
              <div className='form-group col-sm-4'>
                <label>Phone number</label>
                <h6 className='card-title '>{pharmacy.phone}</h6>
              </div>
            </div>
            <br />

            <div className='row'>
              <div className='form-group col-sm-4'>
                <label>Contact name</label>
                <h6 className='card-title '>{pharmacy.contacts}</h6>
              </div>
              <div className='form-group col-sm-4'>
                <label>Email</label>
                <h6 className='card-title '>{pharmacy.email}</h6>
              </div>
              <div className='form-group col-sm-4'>
                <label>Sender Id</label>
                <h6 className='card-title '>{pharmacy.sender}</h6>
              </div>
            </div>
            <br />

            <div className='row'>
              <div className='form-group col-sm-4'>
                <label>Country code</label>
                <h6 className='card-title '>{pharmacy.countryCode}</h6>
              </div>
              <div className='form-group col-sm-4'>
                <label>State Of Premise</label>
                <h6 className='card-title '>{pharmacy.stateOfPremise}</h6>
              </div>
              <div className='form-group col-sm-4'>
                <label>LGA</label>
                <h6 className='card-title '>{pharmacy.lga}</h6>
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='form-group col-sm-4'>
                <label>Pharmacy Address</label>
                <h6 className='card-title '>{pharmacy.pharmacyAddress}</h6>
              </div>
              <div className='form-group col-sm-4'>
                <label>Location area</label>
                <h6 className='card-title '>{pharmacy.locationArea}</h6>
              </div>
              <div className='form-group col-sm-4'>
                <label>Status</label>
                <h6 className='card-title '>{pharmacy.status}</h6>
              </div>
            </div>

            <br />
            <div className='row'>
              <div className='form-group col-sm-4'>
                <label>Default acute days</label>
                <h6 className='card-title '>{pharmacy.defaultAcuteDays}</h6>
              </div>
              <div className='form-group col-sm-4'>
                <label>SMS Balance</label>
                <h6 className='card-title '>{pharmacy.smsBalance}</h6>
              </div>
              <div className='form-group col-sm-4'>
                <label>SMS Provider</label>
                <h6 className='card-title '>{pharmacy.smsprovider}</h6>
              </div>
            </div>

            <br />
            <div className='row'>
              {/* <div className='form-group col-sm-4'>
              <label>Use gateway</label>
              <h6 className='card-title '>{pharmacy.useGateway}</h6>
            </div>
            <div className='form-group col-sm-4'>
              <label>Acquisition Channel</label>
              <h6 className='card-title '>{pharmacy.acquisitionChannel}</h6>
            </div> */}
              <div className='form-group col-sm-4'>
                <label>Package</label>
                <h6 className='card-title '>{pharmacy.package}</h6>
              </div>

              {/* <div className='row'>
            <div className='form-group col-sm-4'>
              <label>Reg Method</label>
              <h6 className='card-title '>{pharmacy.regMethod}</h6>
            </div> */}

              <div className='form-group col-sm-4'>
                <label>Location Longitude</label>
                <h6 className='card-title '>{pharmacy.locationLongitude}</h6>
              </div>
              <div className='form-group col-sm-4'>
                <label>Location Latitude</label>
                <h6 className='card-title '>{pharmacy.locationLatitude}</h6>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='align-grid-items-middle'>
          <Loader />
        </div>
      )}
    </section>
  );
};

export default PharmacyDetails;
