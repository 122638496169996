import { Link, Route } from 'react-router-dom';
import { IPrivateRouteProps } from '../../interface/AppInterface';
import RefreshToast from '../common/RefreshToast';
import Vitallogo from '../../assets/images/vitals/logo.svg';

const VitalsLayout = ({ component: Component, ...rest }: IPrivateRouteProps) => {
  return (
    <div className=''>
      {/* <ScreenLoading /> */}
      <RefreshToast />
      <header>
        <nav className='navbar navbar-expand-lg navbar-light'>
          <div className='container'>
            <div className='col-md-3'>
              <Link className='navbar-brand' to='/'>
                <img className='logo-header img-fluid' alt='logo' src={Vitallogo} />
              </Link>
              <button
                className='navbar-toggler float-end'
                type='button'
                data-toggle='collapse'
                data-target='#topmenu'
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span
                  className='navbar-toggler-icon'
                  data-toggle='collapse'
                  data-target='#topmenu'
                ></span>
              </button>
            </div>
            <div className='col-md-9'>
              <div
                id='topmenu'
                className='mt-3 mt-sm-0 collapse navbar-collapse float-md-end float-start'
              >
                <ul className='navbar-nav me-auto mt-2 mt-lg-0'>
                  <li className='nav-item'>
                    <Link className='nav-link' to='/entry'>
                      Record
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <span
        id='request-spinner'
        className='float-right spinner-border spinner-border-sm text-success request-spinner d-none'
        role='status'
        aria-hidden='true'
      ></span>

      <div className='container font-utile'>
        <div className='row justify-content-center'>
          <Route {...rest} render={(props) => <Component {...props} />} />
        </div>
      </div>
    </div>
  );
};

export default VitalsLayout;
