import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    //go to top
    const topElement = document.getElementById('scroll-here') as HTMLElement;
    topElement.scrollIntoView();
  }, [location]);
  return <div className='d-none'>.</div>;
};

export default ScrollToTop;
