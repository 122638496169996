import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { defaultPageInfo } from '../../../interface/CommonInterface';
import { IPatientMessage } from '../../../interface/MessageInterface';
import { formatDateTime } from '../../../utils/dateTimeUtils';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import { IPatientTabProps } from '../../../interface/PatientInterface';
import Pagination from '../../common/Pagination';
import { PatientTabs } from '../../../utils/mappings';
import Loader from '../../common/Loader';
import NoDataView from '../../common/NoData';

const MessagesScreen = ({ patientId, tabKey }: IPatientTabProps) => {
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [messages, setMessages] = useState<IPatientMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getMessages = useCallback(
    async (pageIndex: number) => {
      if (patientId === 0) {
        return;
      }

      const url = `${endpoints.messaging.getByPatient}/${patientId}?pageIndex=${pageIndex}&pageSize=10`;
      setIsLoading(true);
      try {
        const response = await axios.get(url);
        setMessages(response.data.data);

        const responsePageInfo = {
          pageIndex: response.data.pageIndex,
          pageSize: response.data.pageSize,
          pageCount: response.data.pageCount,
        };
        //set page info
        setPageInfo(responsePageInfo);
      } catch (error) {
        addToast('An error occured');
      } finally {
        setIsLoading(false);
      }
    },
    [patientId]
  );

  const requeueMessage = (messageId: number) => {
    const response = window.confirm('Are you sure you want to requeue this message?');
    if (!response) {
      return;
    }
    addToast('Message successfully requeued');
  };

  const cancelMessage = (messageId: number) => {
    const response = window.confirm('Are you sure you want to cancel this message?');
    if (!response) {
      return;
    }
    addToast('Message successfully cancelled', 'success');
  };

  useEffect(() => {
    if (tabKey !== PatientTabs.messages) {
      return;
    }
    getMessages(1);
  }, [patientId, tabKey, getMessages]);

  const dataView = (
    <div>
      <div className='table-responsive'>
        <table className='table table-hover table-bordered'>
          <thead>
            <tr>
              <th>Phone</th>
              <th>Delivery Status</th>
              <th>Message Content</th>
              <th>Queue date</th>
              <th>Actions </th>
            </tr>
          </thead>
          <tbody>
            {messages.map((message) => (
              <tr key={message.messageId}>
                <td>{message.phoneNumber}</td>
                <td>{message.deliveryStatus}</td>
                <td>{message.messageContent}</td>
                <td>{formatDateTime(message.queueDate)}</td>
                <td>
                  <span
                    className='bi bi-recycle custom-group-icon text-info'
                    title='Re-dispense'
                    onClick={() => requeueMessage(message.messageId)}
                  ></span>
                  {message.deliveryStatus === 'Queued' ? (
                    <span
                      className='bi bi-x-circle custom-group-icon n text-danger'
                      title='Cancel'
                      onClick={() => cancelMessage(message.messageId)}
                    ></span>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPageNumber={pageInfo.pageIndex}
        numberOfPages={pageInfo.pageCount}
        callback={getMessages}
      />
    </div>
  );

  return (
    <section className='mt-5'>
      <h5 className='mb-4'>Messages</h5>
      {isLoading && <Loader />}
      {!isLoading && messages.length > 0 && dataView}
      {!isLoading && messages.length === 0 && <NoDataView />}
    </section>
  );
};

export default MessagesScreen;
