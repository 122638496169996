export const formatDate = (dateInput?: string) => {
  if (dateInput === null || typeof dateInput === 'undefined') {
    return '';
  } else {
    return new Date(dateInput).toLocaleDateString();
  }
};

export const formatDateTime = (dateTimeInput?: string) => {
  if (dateTimeInput === null || typeof dateTimeInput === 'undefined') {
    return '';
  } else {
    return new Date(dateTimeInput).toLocaleString([], { hour12: true });
  }
};
