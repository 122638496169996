import axios from 'axios';
import React, { FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import {
  initialNotificationSettings,
  INotificationSettings,
} from '../../../interface/NotificationsInterface';
import { NOTIFICATION_SETTINGS } from '../../../utils/mappings';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';

const NotificationSettings = () => {
  const { appState } = useContext(AppContext);
  const pharmacyId = appState.pharmacyId;

  const [notificationSetting, setNotificationSettting] = useState<INotificationSettings>(
    initialNotificationSettings
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input: string) => (event: React.ChangeEvent<HTMLSelectElement>) => {
    setNotificationSettting({
      ...notificationSetting,
      [input]: event.target.value,
    });
  };

  const loadNotificationSettings = useCallback(async () => {
    const url = `${endpoints.notificationSettings.getByPharmacy}/${pharmacyId}`;
    try {
      const response = await axios.get(url);
      setNotificationSettting(response.data);
    } catch (error) {
      addToast('Error occured while fetching notifications', 'error');
    }
  }, [pharmacyId]);

  const saveNotificationSettings = async (event: FormEvent) => {
    event.preventDefault();

    const pharmacyId = appState.pharmacyId;
    notificationSetting.pharmacyId = pharmacyId;
    const url = `${endpoints.notificationSettings.mainUrl}`;
    setIsLoading(true);
    try {
      await axios.post(url, notificationSetting);
      addToast('Settings saved successfully', 'success');
    } catch (error) {
      addToast('Error occured while saving settings', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadNotificationSettings();
  }, [loadNotificationSettings]);

  return (
    <div className='mt-4 card pt-5 pb-5 ps-4 pe-4'>
      <br />
      <h4 className='text-center mt-4'>Notifications Settings</h4>
      <div className='row justify-content-center mt-4'>
        <div className='col-lg-4 col-md-6 col-sm-8'>
          <form>
            <fieldset disabled={isLoading}>
              <div className='form-group'>
                <label>Account expiry</label>
                <select
                  className='form-select'
                  value={notificationSetting.accountExpiry}
                  onChange={handleChange('accountExpiry')}
                >
                  {NOTIFICATION_SETTINGS.map((setting) => (
                    <option key={setting.key} value={setting.value}>
                      {setting.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group mt-4'>
                <label>Low Sms Credit</label>
                <select
                  className='form-select'
                  value={notificationSetting.lowCredit}
                  onChange={handleChange('lowCredit')}
                >
                  {NOTIFICATION_SETTINGS.map((setting) => (
                    <option key={setting.key} value={setting.value}>
                      {setting.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group mt-4'>
                <label>Weekly Statistics</label>
                <select
                  className='form-select'
                  value={notificationSetting.weeklyStats}
                  onChange={handleChange('weeklyStats')}
                >
                  {NOTIFICATION_SETTINGS.map((setting) => (
                    <option key={setting.key} value={setting.value}>
                      {setting.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group mt-4'>
                <label>Unread tasks</label>
                <select
                  className='form-select'
                  value={notificationSetting.notifications}
                  onChange={handleChange('notifications')}
                >
                  {NOTIFICATION_SETTINGS.map((setting) => (
                    <option key={setting.key} value={setting.value}>
                      {setting.text}
                    </option>
                  ))}
                </select>
              </div>
              <br />
              <div className='mt-2 d-grid'>
                <button
                  type='submit'
                  className='btn btn-primary btn-block'
                  onClick={saveNotificationSettings}
                >
                  Save Changes
                  {isLoading ? <ButtonLoader /> : ''}
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
