import axios from 'axios';
import { useEffect, useState } from 'react';
import { IDrugInteraction } from '../../../interface/MedicationEntryInterface';
import TableLoader from '../../../components/common/TableLoader';
import NoTableData from '../../../components/common/NoTableData';

const DrugInteraction = () => {
  const [drugInteractions, setDrugInteractions] = useState<IDrugInteraction[] | undefined>();

  const loadInteractions = async () => {
    //get the drugs, split on +

    const searchParam = new URLSearchParams(window.location.search);
    const drugs = searchParam.get('drugs');

    let allDrugInteractions: IDrugInteraction[] = [];

    if (drugs === '' || drugs === null) {
      setDrugInteractions(allDrugInteractions);
      return;
    }

    const url = `https://rxnav.nlm.nih.gov/REST/interaction/list.json?rxcuis=${drugs}`;

    //call api
    try {
      const response = await axios.get(url);

      const data = response.data;

      if (data.fullInteractionTypeGroup && data.fullInteractionTypeGroup.length > 0) {
        //checks if interaction exists and all drugs have rxcui
        for (
          let fullType = 0;
          fullType < data.fullInteractionTypeGroup[0].fullInteractionType.length;
          fullType++
        ) {
          for (
            let pair = 0;
            pair <
            data.fullInteractionTypeGroup[0].fullInteractionType[fullType].interactionPair.length;
            pair++
          ) {
            const drugA =
              data.fullInteractionTypeGroup[0].fullInteractionType[fullType].interactionPair[pair]
                .interactionConcept[0].minConceptItem.name;
            const drugB =
              data.fullInteractionTypeGroup[0].fullInteractionType[fullType].interactionPair[pair]
                .interactionConcept[1].minConceptItem.name;
            const interaction =
              data.fullInteractionTypeGroup[0].fullInteractionType[fullType].comment;
            const contraIndication =
              data.fullInteractionTypeGroup[0].fullInteractionType[fullType].interactionPair[pair]
                .description;

            const drugInteraction: IDrugInteraction = {
              drugA: drugA,
              drugB: drugB,
              interaction: interaction,
              contraIndication: contraIndication,
            };

            allDrugInteractions.push(drugInteraction);
          }
        }

        setDrugInteractions(allDrugInteractions);
      } else {
        setDrugInteractions(allDrugInteractions);
      }

      //get the data we need .
    } catch (error) {
      console.log(error);
    }

    //display result
  };

  useEffect(() => {
    loadInteractions();
  }, []);

  return (
    <section className='row justify-content-center mb-5 content-section'>
      <section className='col-11 mt-4'>
        This interaction is provided by DrugBank with the following disclaimer DrugBank Disclaimer:
        <div className='text-warning'>
          DrugBank is intended for educational and scientific research purposes only and you
          expressly acknowledge and agree that use of DrugBank is at your sole risk. The accuracy of
          DrugBank information is not guaranteed and reliance on DrugBank shall be at your sole
          risk.
          <strong className='ms-1 text-danger'>
            DrugBank is not intended as a substitute for professional medical advice, diagnosis or
            treatment.
          </strong>
        </div>
      </section>
      <section className='col-11 tabe-responsive mt-5'>
        <table className='table table-condensed table-bordered'>
          <thead>
            <tr>
              <th>Drug A</th>
              <th>Drug B</th>
              <th>Interaction</th>
              <th>Contra-indication</th>
            </tr>
          </thead>
          {drugInteractions ? (
            <tbody>
              {drugInteractions.length > 0 ? (
                drugInteractions.map((drug, key) => (
                  <tr>
                    <td>{drug.drugA}</td>
                    <td>{drug.drugB}</td>
                    <td>{drug.interaction}</td>
                    <td>{drug.contraIndication}</td>
                  </tr>
                ))
              ) : (
                <NoTableData />
              )}
            </tbody>
          ) : (
            <TableLoader />
          )}
        </table>
        <div className='font-italic'>
          "This product uses publicly available data from the U.S. National Library of Medicine
          (NLM), National Institutes of Health, Department of Health and Human Services; NLM is not
          responsible for the product and does not endorse or recommend this or any other product."
        </div>
      </section>
    </section>
  );
};

export default DrugInteraction;
