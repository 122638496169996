import { useContext, useState } from 'react';
import ButtonLoader from '../common/ButtonLoader';
import { AppContext } from '../../context/AppContext';
import { IAuthToken, initialLoginState } from '../../interface/AppInterface';
import { isFormValidated } from '../../utils/formUtils';
import setAuthToken from '../../utils/setAuthToken';
import { addToast } from '../../utils/toastNotifications';
import { endpoints } from '../../utils/URL';
import axios from 'axios';
import { IPharmacyListDTO } from '../../interface/PharmacyInterface';
import { Link } from 'react-router-dom';

const Login = () => {
  const { dispatch } = useContext(AppContext);

  const [loginState, setLoginState] = useState(initialLoginState);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState({
      ...loginState,
      [input]: event.target.value,
    });
  };

  const login = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!isFormValidated('login-form')) {
      return;
    }
    setDisabled(true);
    setLoading(true);
    try {
      const response = await axios.post(endpoints.auth.getToken, loginState);
      const data = response.data as IAuthToken;
      if (response.status === 200) {
        const token = data.token;
        dispatch({
          type: 'LOG_IN',
          payload: data,
        });
        const selectedPharmacy: IPharmacyListDTO = {
          pharmacyCode: data.pharmacyCode,
          pharmacyId: data.pharmacyId,
          address: '',
          pharmacyName: data.pharmacyName,
          clientId: data.clientId,
        };
        dispatch({
          type: 'SELECT_PHARMACY',
          payload: selectedPharmacy,
        });

        setAuthToken(token);

        window.location.href = '/entry';
      } else {
        addToast('Error logging in. Please try again.', 'error');
      }
      setDisabled(false);
      setLoading(false);
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        addToast('Username or password is incorrect', 'error');
        setLoading(false);
        setDisabled(false);
      } else {
        addToast('There was a problem connecting. Please try again', 'error');
        setLoading(false);
        setDisabled(false);
      }
    }
  };

  return (
    <div className='row d-flex justify-content-center align-items-center'>
      <div className='col-md-8 col-lg-4 card pt-5 pb-5 ps-4 pe-4'>
        <h3 className='text-center'>
          <span className='bi bi-person' style={{ fontSize: '5rem' }}></span>
        </h3>
        <form className='form' id='login-form' onSubmit={login}>
          <div className='form-group mb-3 mt-4'>
            {/* <label htmlFor='username'>Username</label> */}
            <input
              className='form-control ps-5'
              type='text'
              value={loginState.username}
              onChange={handleChange('username')}
              placeholder='Username'
              required
              disabled={disabled}
            />
            <div className='bi bi-person icon-inset-left'></div>
            <div className='invalid-feedback'>Please fill-in Username</div>
          </div>
          <div className='form-group mt-5'>
            {/* <label htmlFor='password'>Password</label> */}
            <input
              className='form-control ps-5'
              type={showPassword ? 'text' : 'password'}
              value={loginState.password}
              onChange={handleChange('password')}
              placeholder='Password'
              required
              disabled={disabled}
            />
            <div className='bi bi-key icon-inset-left'></div>
            <div
              className={
                showPassword ? 'bi bi-eye-slash icon-inset-right' : 'bi bi-eye icon-inset-right'
              }
              onClick={() => togglePassword()}
            ></div>

            <div className='invalid-feedback'>Please fill-in Password</div>
          </div>
          <br />
          <div className='d-grid gap-2 mt-4'>
            <button type='submit' className='btn btn-primary btn-block'>
              Login
              {loading ? <ButtonLoader /> : ''}
            </button>
          </div>
        </form>
        <div className='mt-3 text-center'>
          <Link to='/auth/forgotpassword'>Forgot password</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
