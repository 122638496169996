import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { defaultPageInfo } from '../../../interface/CommonInterface';
import { IBloodPressureReading } from '../../../interface/BloodPressureInterface';
import { formatDateTime } from '../../../utils/dateTimeUtils';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import Pagination from '../../common/Pagination';
import BloodPressureModal from './BloodPressureModal';
import { IPatientTabProps } from '../../../interface/PatientInterface';
import { PatientTabs } from '../../../utils/mappings';
import Loader from '../../common/Loader';
import NoDataView from '../../common/NoData';

const BloodPressureScreen = ({ patientId, tabKey }: IPatientTabProps) => {
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [bloodPressures, setBloodPressures] = useState<IBloodPressureReading[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getBloodPressureReadings = useCallback(
    async (pageIndex: number) => {
      if (patientId === 0) {
        return;
      }
      setIsLoading(true);
      const url = `${endpoints.bloodPressures.getByPatient}/${patientId}?pageIndex=${pageIndex}&pageSize=10`;
      try {
        const response = await axios.get(url);
        setBloodPressures(response.data.data);

        const responsePageInfo = {
          pageIndex: response.data.pageIndex,
          pageSize: response.data.pageSize,
          pageCount: response.data.pageCount,
        };
        //set page info
        setPageInfo(responsePageInfo);
      } catch (error) {
        addToast('An error occured');
      } finally {
        setIsLoading(false);
      }
    },
    [patientId]
  );

  const deleteReading = (readingId: number) => {
    const response = window.confirm('Are you sure you want to delete this reading?');
    if (!response) {
      return;
    }
    addToast('Reading successfully deleted', 'success');
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (tabKey !== PatientTabs.bloodPressure) {
      return;
    }
    getBloodPressureReadings(1);
  }, [patientId, tabKey, getBloodPressureReadings]);

  let bloodPressureModal = (
    <BloodPressureModal
      show={showModal}
      close={toggleModal}
      patientId={patientId}
      callback={() => getBloodPressureReadings(1)}
    />
  );

  const dataView = (
    <div>
      <div className='table-responsive'>
        <table className='table table-hover table-bordered'>
          <thead>
            <tr>
              <th>Systolic (mm/Hg)</th>
              <th>Diastolic (mm/Hg)</th>
              <th>Heart rate (bpm)</th>
              <th>Reading date</th>
              <th>Actions </th>
            </tr>
          </thead>
          <tbody>
            {bloodPressures.map((bloodPressure) => (
              <tr key={bloodPressure.readingId}>
                <td>{bloodPressure.systolic}</td>
                <td>{bloodPressure.diastolic}</td>
                <td>{bloodPressure.heartRate}</td>
                <td>{formatDateTime(bloodPressure.readingTime)}</td>
                <td>
                  <span
                    className='bi bi-trash custom-group-icon text-danger'
                    title='Delete reading'
                    onClick={() => deleteReading(bloodPressure.readingId)}
                  ></span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPageNumber={pageInfo.pageIndex}
        numberOfPages={pageInfo.pageCount}
        callback={getBloodPressureReadings}
      />
    </div>
  );

  return (
    <section className='mt-5'>
      {bloodPressureModal}
      <h5 className='mb-4'>Blood Pressure Readings</h5>
      <br />
      <button className='btn btn-primary' onClick={toggleModal}>
        Add Reading
      </button>
      <div className='mt-3'></div>
      {isLoading && <Loader />}
      {!isLoading && bloodPressures.length > 0 && dataView}
      {!isLoading && bloodPressures.length === 0 && <NoDataView />}
    </section>
  );
};

export default BloodPressureScreen;
