import axios from 'axios';
import { FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import { defaultPageInfo } from '../../../../interface/CommonInterface';
import { IMessageSearchParams, IPatientMessage } from '../../../../interface/MessageInterface';
import { formatDateTime } from '../../../../utils/dateTimeUtils';
import { addToast } from '../../../../utils/toastNotifications';
import { endpoints } from '../../../../utils/URL';
import Pagination from '../../../common/Pagination';
import { MESSAGE_ACTION, MESSAGE_STATUS } from '../../../../utils/mappings';
import { AppContext } from '../../../../context/AppContext';
import ButtonLoader from '../../../common/ButtonLoader';
import NoDataView from '../../../common/NoData';
import Loader from '../../../common/Loader';

const Messages = () => {
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [messages, setMessages] = useState<IPatientMessage[]>([]);
  const { appState } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const [searchLoading, setSearchLoading] = useState(false);

  const [searchParams, setSearchParams] = useState<IMessageSearchParams>({
    phoneNumber: '',
    startDate: '',
    endDate: '',
    status: MESSAGE_STATUS[0].value,
  });

  let selectedValues: number[] = [];

  const getMessages = async (url: string) => {
    setTableLoading(true);
    try {
      const response = await axios.get(url);
      setMessages(response.data.data);

      const responsePageInfo = {
        pageIndex: response.data.pageIndex,
        pageSize: response.data.pageSize,
        pageCount: response.data.pageCount,
      };
      //set page info
      setPageInfo(responsePageInfo);
    } catch (error) {
      addToast('An error occured', 'error');
    } finally {
      setTableLoading(false);
    }
  };

  const getAllMessages = useCallback(
    async (pageIndex: number) => {
      if (appState.selectedPharmacy.pharmacyId === 0) {
        return;
      }

      const pharmacyId = appState.selectedPharmacy.pharmacyId;
      const url = `${endpoints.messages.getByPharmacy}/${pharmacyId}?pageIndex=${pageIndex}&pageSize=10`;

      await getMessages(url);
    },
    [appState.selectedPharmacy]
  );

  const setAction = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const messageAction = event.target.value;
    const actionText = event.target.options[event.target.selectedIndex].text;

    if (selectedValues.length === 0) {
      addToast('Please select some messages', 'error');
      return;
    }
    if (event.target.value === '') {
      return;
    }

    const response = window.confirm(
      `Are you sure you want to ${actionText} ${selectedValues.length} messages ?`
    );
    if (!response) {
      return;
    }

    const payload = {
      selectedId: selectedValues,
    };

    const url = `${endpoints.messaging.queue}?queueType=${messageAction}`;

    setLoading(true);
    try {
      await axios.post(url, payload);
      uncheckAllRows();
      // reload
      getAllMessages(pageInfo.pageIndex);
      addToast(`Messages successfully ${actionText}`, 'success');
    } catch (error) {
      addToast(`Error occured ${actionText} list`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const checkAllRows = (event: React.ChangeEvent<HTMLInputElement>) => {
    let singleRows = document.getElementsByClassName(
      'single'
    ) as HTMLCollectionOf<HTMLInputElement>;
    //if checked,
    //clear all selections, clear array, select all rows and check them
    if (event.target.checked) {
      selectedValues = [];

      for (let i = 0; i < singleRows.length; i++) {
        singleRows[i].checked = true;
        let rowValue = parseInt(singleRows[i].getAttribute('id') as string);
        selectedValues.push(rowValue);
      }
    }
    //else, clear all selections, clear array
    else {
      uncheckAllRows();
    }
  };

  const uncheckAllRows = () => {
    let singleRows = document.getElementsByClassName(
      'single'
    ) as HTMLCollectionOf<HTMLInputElement>;
    for (let i = 0; i < singleRows.length; i++) {
      singleRows[i].checked = false;
    }
    selectedValues = [];
  };

  const checkSingleRow = (event: React.ChangeEvent<HTMLInputElement>) => {
    //get value of checkbox
    let rowValue = parseInt(event.target.value);

    //if in checked state, add value to list of selected
    if (event.target.checked) {
      selectedValues.push(rowValue);
    }
    //else remove value from list of selected
    else {
      selectedValues = selectedValues.filter((x) => x !== rowValue);
    }
  };

  const handleChange = (input: string) => (event: any) => {
    setSearchParams({
      ...searchParams,
      [input]: event.target.value,
    });
  };

  const searchMessages = async (event: FormEvent) => {
    event.preventDefault();

    if (appState.selectedPharmacy.pharmacyId === 0) {
      return;
    }

    const pharmacyId = appState.selectedPharmacy.pharmacyId;

    let url = `${endpoints.messages.getByFilter}/${pharmacyId}?`;

    //build url
    if (searchParams.phoneNumber !== '') {
      url += `phoneNumber=${searchParams.phoneNumber}`;
    }
    if (searchParams.startDate !== '') {
      url += `&startDate=${searchParams.startDate}`;
    }
    if (searchParams.endDate !== '') {
      url += `&endDate=${searchParams.endDate}`;
    }
    if (searchParams.status !== '') {
      url += `&status=${searchParams.status}`;
    }
    setSearchLoading(true);
    await getMessages(url);
    setSearchLoading(false);
  };

  useEffect(() => {
    getAllMessages(1);
  }, [getAllMessages]);

  const dataView = (
    <div className='card pt-5 pb-5 pe-4 ps-4'>
      <div className='table-responsive'>
        <table className='table table-hover table-bordered'>
          <thead>
            <tr>
              <th>Phone number</th>
              <th>Delivery Status</th>
              <th>Message Content</th>
              <th>Queue date</th>
              <th>
                Select all
                <input className='ms-4' type='checkbox' id='all-rows' onChange={checkAllRows} />
                <select className='form-select' onChange={setAction} disabled={loading}>
                  {MESSAGE_ACTION.map((action) => (
                    <option key={action.key} value={action.value}>
                      {action.text}
                    </option>
                  ))}
                </select>
              </th>
            </tr>
          </thead>
          <tbody>
            {messages.map((message) => (
              <tr key={message.messageId}>
                <td>{message.phoneNumber}</td>
                <td>{message.deliveryStatus}</td>
                <td>{message.messageContent}</td>
                <td>{formatDateTime(message.queueDate)}</td>
                <td>
                  <input
                    id={message.messageId.toString()}
                    type='checkbox'
                    className='single'
                    value={message.messageId}
                    onChange={checkSingleRow}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPageNumber={pageInfo.pageIndex}
        numberOfPages={pageInfo.pageCount}
        callback={getAllMessages}
      />
    </div>
  );

  return (
    <section className=''>
      <h4>Messages</h4>
      <br />
      <fieldset className='row' disabled={searchLoading}>
        <div className='col-sm-3 form-group'>
          <label>Phone number</label>
          <input
            type='text'
            className='form-control'
            placeholder='Phone number'
            value={searchParams.phoneNumber}
            onChange={handleChange('phoneNumber')}
          />
        </div>
        <div className='col-sm-2 form-group'>
          <label>Start Date</label>
          <input
            type='date'
            className='form-control'
            value={searchParams.startDate}
            onChange={handleChange('startDate')}
          />
        </div>
        <div className='col-sm-2 form-group'>
          <label>End Date</label>
          <input
            type='date'
            className='form-control'
            value={searchParams.endDate}
            onChange={handleChange('endDate')}
          />
        </div>
        <div className='col-sm-3 form-group'>
          <label>Status</label>
          <select
            className='form-select'
            value={searchParams.status}
            onChange={handleChange('status')}
          >
            {MESSAGE_STATUS.map((status) => (
              <option key={status.key} value={status.value}>
                {status.text}
              </option>
            ))}
          </select>
        </div>
        <div className='col-sm-2 d-grid'>
          <label className='invisible'>.</label>
          <button className='btn btn-primary btn-block' onClick={searchMessages}>
            Search
            {searchLoading ? <ButtonLoader /> : ''}
          </button>
        </div>
      </fieldset>
      <br />
      <div className='mt-3'></div>
      {tableLoading && <Loader />}
      {!tableLoading && messages.length > 0 && dataView}
      {!tableLoading && messages.length === 0 && <NoDataView />}
    </section>
  );
};

export default Messages;
