import Header from '../layout/Header';
import { Route } from 'react-router-dom';
import { IPrivateRouteProps } from '../../interface/AppInterface';

const Layout = ({ component: Component, ...rest }: IPrivateRouteProps) => {
  return (
    <div>
      <Header />
      <div className='container' style={{ marginTop: '70px' }}>
        <Route {...rest} render={(props) => <Component {...props} />} />
      </div>
    </div>
  );
};

export default Layout;
