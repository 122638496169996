import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';

const Header = () => {
  return (
    <header>
      <nav className='navbar navbar-expand-lg navbar-light'>
        <div className='container'>
          <div className='col-md-3'>
            <Link className='navbar-brand' to='/'>
              <img alt='logo' src={logo} />
            </Link>
            <button
              className='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navbarTogglerDemo02'
              aria-controls='navbarTogglerDemo02'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon'></span>
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
