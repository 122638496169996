import axios from 'axios';
import React, { FormEvent, useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  defaultCholesterolReading,
  ICholesterolReadingDTO,
} from '../../../interface/CholesterolInterface';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';
import { IPatientModal } from '../../../interface/CommonInterface';

const CholesterolModal = ({ show, close, patientId, callback }: IPatientModal) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cholesterol, setCholesterol] = useState<ICholesterolReadingDTO>(defaultCholesterolReading);

  const handleChange = (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setCholesterol({
      ...cholesterol,
      [input]: event.target.value,
    });
  };

  const saveReading = async (event: FormEvent) => {
    if (!isFormValidated('add-lipid-modal')) {
      addToast('Please fill all fields', 'warning');
      return;
    }
    event.preventDefault();
    cholesterol.patientId = patientId;

    setIsLoading(true);
    const url = `${endpoints.cholesterol.mainUrl}`;

    try {
      await axios.post(url, cholesterol);
      addToast('Cholesterol reading added successfully', 'success');
      if (callback) {
        callback();
      }
      close();
    } catch (error) {
      addToast('An error occured while saving data', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={close} backdrop={isLoading ? 'static' : true}>
      <form id='add-lipid-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Add Cholesterol Reading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={isLoading}>
            <div className='form-group'>
              <label className='required'>Low density lipids</label>
              <input
                type='number'
                className='form-control'
                value={cholesterol.lowDensityLipids}
                onChange={handleChange('lowDensityLipids')}
                required
                min={1}
                max={300}
                step={0.01}
              />
            </div>
            <div className='form-group'>
              <label className='required'>High density lipids</label>
              <input
                type='number'
                className='form-control'
                value={cholesterol.highDensityLipids}
                onChange={handleChange('highDensityLipids')}
                required
                min={1}
                max={300}
                step={0.01}
              />
            </div>
            <div className='form-group'>
              <label className='required'>Triglycerides</label>
              <input
                type='number'
                className='form-control'
                value={cholesterol.triglycerides}
                onChange={handleChange('triglycerides')}
                required
                min={1}
                max={300}
                step={0.01}
              />
            </div>
            <div className='form-group'>
              <label className='required'>Total Cholesterol</label>
              <input
                type='number'
                className='form-control'
                value={cholesterol.totalCholesterol}
                onChange={handleChange('totalCholesterol')}
                required
                min={1}
                max={300}
                step={0.01}
              />
            </div>
            <div className='form-group'>
              <label>Reading date</label>
              <input
                type='date'
                className='form-control'
                value={cholesterol.readingDate}
                onChange={handleChange('readingDate')}
                required
              />
            </div>
            <div className='form-group'>
              <label>Reading time</label>
              <input
                type='time'
                className='form-control'
                value={cholesterol.readingTime}
                onChange={handleChange('readingTime')}
                required
              />
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' type='submit' onClick={saveReading}>
            Save
            {isLoading ? <ButtonLoader /> : ''}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CholesterolModal;
