import { useState } from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import { plansData } from '../constants';
import Stars from './Stars';
import check from '../../../../assets/images/icons/check.svg';
import white from '../../../../assets/images/icons/whitegood.svg';

const PricingComponent = () => {
  const [isYearly, setIsYearly] = useState(false);

  const plans = plansData.map((plan, index) => ({
    ...plan,
    price: isYearly ? plan.priceYearly * plan.discount : plan.priceMonthly,
    stars: index === 0 ? 2 : index + 2, // Adjusted the stars based on the index
  }));

  const handleToggle = () => {
    setIsYearly(!isYearly);
  };

  return (
    <Container className='mt-5'>
      <h3 className='text-center display-6 mb-2 fs-4 fw-bold'>Pricing</h3>
      <p className='text-center fs-5 mb-4'>
        Choose a plan that suits your needs and embark on a <br /> journey of seamless, efficient
        solutions tailored just for you.
      </p>

      <div className='d-flex align-items-center justify-content-center gap-3 mb-5 pb-5'>
        <p className={`mb-0 fw-bold ${!isYearly ? 'text-success' : 'text-secondary'}`}>Monthly</p>
        <Form className='text-center'>
          <Form.Check
            type='switch'
            id='custom-switch'
            label=''
            checked={isYearly}
            onChange={handleToggle}
          />
        </Form>
        <p className={`mb-0 fw-bold ${isYearly ? 'text-success' : 'text-secondary'}`}>
          Yearly
          <span className='savings p-2 mx-2 rounded-pill'>Save up to 25%</span>
        </p>
      </div>

      <div>
        <Row className='justify-content-around'>
          {plans.map((plan) => (
            <Col key={plan.title} xs={12} md={6} lg={3} className='mb-3'>
              <Card
                className={`shadow h-100 ${plan.isHighlighted ? 'popular-card' : ''}`}
                style={{ height: '100%' }}
              >
                <Card.Body className={`${plan.isHighlighted ? 'text-white' : ''}`}>
                  <Card.Title>
                    {plan.isHighlighted ? (
                      <div className='d-flex justify-content-between'>
                        <Stars count={plan.stars} className='text-light' />
                        <p className='bg-light py-1 px-2 rounded-4 text-dark-emphasis'>
                          Most Popular
                        </p>
                      </div>
                    ) : (
                      <Stars count={plan.stars} />
                    )}
                  </Card.Title>
                  <Card.Subtitle>{plan.title}</Card.Subtitle>
                  <Card.Subtitle className='mt-3'>
                    {`₦${plan.price} /`}
                    <span className=''>{isYearly ? 'year' : 'month'}</span>
                  </Card.Subtitle>
                  <Card.Text>
                    <p className='mt-3' style={{ fontSize: '10px' }}>
                      Billed Monthly
                    </p>
                    <div className='mt-3'>
                      <p className='mt-3' style={{ fontSize: '12px' }}>
                        {plan.benefitTitle}
                      </p>

                      {plan.benefits.map((benefit, index) => (
                        <div key={index}>
                          {plan.isHighlighted ? (
                            <img src={white} alt='benefiit' />
                          ) : (
                            <img src={check} alt='benefit' />
                          )}
                          <span style={{ fontSize: '13px' }}>{benefit}</span>
                        </div>
                      ))}
                    </div>
                  </Card.Text>
                </Card.Body>
                <Card.Footer className={`${plan.isHighlighted ? 'popular-card' : 'bg-white'}`}>
                  <div className='d-grid'>
                    <button
                      type='button'
                      className='btn wellapartner text-white'
                    >{`Get ${plan.title}`}</button>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
};

export default PricingComponent;
