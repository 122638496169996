import { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../context/AppContext';
import WalletFragment from './WalletFragment';
import Loader from '../../common/Loader';

const WalletsHome = () => {
  const { appState } = useContext(AppContext);
  const [pharmacyCode, setPharmacyCode] = useState('');
  const [clientId, setClientId] = useState(0);

  useEffect(() => {
    let clientId = appState.clientId;
    let pharmacyCode = appState.pharmacyCode;
    setPharmacyCode(pharmacyCode);
    setClientId(clientId);
  }, [appState.clientId, appState.pharmacyCode]);

  let walletFragment = <WalletFragment pharmacyCode={pharmacyCode} clientId={clientId} />;

  return (
    <div className='col-md-10  content-section'>
      {pharmacyCode !== '' && clientId > 0 ? walletFragment : <Loader />}
    </div>
  );
};

export default WalletsHome;
