import { addToast } from '../../../utils/toastNotifications';
import { validFileType } from '../../../utils/fileHelper';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import ButtonLoader from '../../common/ButtonLoader';
import { useContext, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import { IAppState } from '../../../interface/AppInterface';
import PatientUploadModal from './PatientUploadModal';
import { IPatientUploadData } from '../../../interface/PatientInterface';
import { isFormValidated } from '../../../utils/formUtils';

const PatientsUpload = () => {
  const [showModal, setShowModal] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const { appState }: { appState: IAppState } = useContext(AppContext);
  const [patientList, setPatientList] = useState<IPatientUploadData[]>();
  const [modalData, setModalData] = useState<IPatientUploadData[]>();
  const [progressState, setProgressState] = useState('progress-bar');
  const [uploadResult, setUploadResult] = useState({
    uploadStatus: 'mt',
    uploadMessage: '',
  });

  const uploadCsv = async (event: any) => {
    if (!isFormValidated('patient-upload-form')) {
      return;
    }

    const confirm = window.confirm('Do you want to upload this file?');

    if (!confirm) {
      event.preventDefault();
      return;
    }
    event.preventDefault();
    // clearFulfilments();

    let fileUpload: any = document.getElementById('patient-file');

    let uploadedFiles = fileUpload.files;

    if (uploadedFiles.length === 0) {
      addToast('No files selected. Please select a text or csv file', 'warning');
      return;
    }
    if (!validFileType(uploadedFiles[0])) {
      addToast('Wrong file. Upload text or csv file', 'warning');
      return;
    }

    if (uploadedFiles[0].size > 2097152) {
      //if exceeding 2mb
      addToast('Exceed file limit. Max limit is 2MB', 'warning');
      return;
    }

    event.preventDefault();
    setBtnLoading(true);
    setProgressState('progress-bar ');

    const formData = new FormData();
    formData.append('formFile', uploadedFiles[0]);
    formData.append('pharmacyCode', appState.pharmacyCode);

    let url = `${endpoints.patients.mainUrl}/${appState.pharmacyId}/upload`;
    let progressBar = document.getElementById('progress-bar') as HTMLElement;
    progressBar.style.width = `0%`;

    var config = {
      onUploadProgress: function (progressEvent: any) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        progressBar.style.width = `${[percentCompleted]}%`;
      },
    };
    try {
      const response = await axios.post(url, formData, config);
      setProgressState('progress-bar bg-success');
      const data = response.data;
      setPatientList(data);
      addToast('File successfully uploaded and processed', 'success');
      setUploadResult({
        uploadMessage: 'File successfully uploaded and processed',
        uploadStatus: 'mt-5 text-success',
      });
    } catch (error: any) {
      setProgressState('progress-bar bg-danger');
      progressBar.style.width = `100%`;

      let uploadMessage = 'An error occured while processing your file';
      if (Array.isArray(error.response.data)) {
        uploadMessage = error.response.data;
      } else {
        uploadMessage = error.response.data.detail;
      }

      setUploadResult({
        uploadMessage: uploadMessage,
        uploadStatus: 'mt-5 text-danger',
      });
    } finally {
      setBtnLoading(false);
    }
  };

  const clearForm = (event: any) => {
    event.preventDefault();

    setBtnLoading(false);
    const form = document.getElementById!('patient-upload-form') as HTMLFormElement;
    form.reset();
    let progressBar = document.getElementById('progress-bar') as HTMLElement;
    progressBar.style.width = `0%`;
    setModalData([]);
    setPatientList([]);
    setUploadResult({
      uploadMessage: '',
      uploadStatus: '',
    });
  };

  const openModal = (isDataValid: boolean) => {
    const filteredData = patientList?.filter((x) => x.isDataValid === isDataValid);
    setModalData(filteredData);
    setShowModal(!showModal);
  };

  return (
    <div className='col-12 content-section' style={{ minHeight: '700px' }}>
      <PatientUploadModal patients={modalData} show={showModal} close={openModal} />

      <h4>Patients Upload</h4>
      <div>Instructions</div>
      <ol>
        <li>
          Download the file format
          <a href='/Patient List - Sample.csv' target='_blank' className='ms-1'>
            here
          </a>
        </li>
        <li>Copy your patient data to the file downloaded</li>
        <li>All column headers in the file must not be edited</li>
        <li>Patient name and phone number are compulsory</li>
        <li>The format for date of birth is yyyy-mm-dd e.g 2002-11-23</li>
        <li>The format for date for gender is 'Male' or 'Female'</li>
        <li>To upload, select the file and click upload</li>
        <li>Confirm your upload and the file will be processed</li>
        <li>A summary of the process will be displayed once the process is completed</li>
        <li>Click 'Clear' to start all over</li>
        <li>You can watch a video of this process here</li>
      </ol>
      <div className='mt-5'>
        <form className='row' id='patient-upload-form'>
          <div className='col-sm-6'>
            <input
              type='file'
              className='form-control'
              id='patient-file'
              accept='.txt, .csv,' // application/vnd.ms-excel'
              required
            />
            <small>Allowed file types (.csv)</small>
            <div className='progress' style={{ height: '3px', marginTop: '10px' }}>
              <div
                id='progress-bar'
                className={progressState}
                role='progressbar'
                style={{ width: '0%' }}
                aria-valuenow={0}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
          </div>
          <div className='col-sm-6'>
            <button className='btn btn-primary' onClick={uploadCsv}>
              Upload <span className='bi bi-upload'></span>
              {btnLoading ? <ButtonLoader /> : ''}
            </button>
            <button className='btn btn-danger ms-2' onClick={clearForm}>
              Clear <span className='bi bi-x '></span>
            </button>
          </div>
        </form>
        <div className={uploadResult.uploadStatus}>{uploadResult.uploadMessage}</div>
      </div>
      {patientList && patientList.length > 0 ? (
        <div className='mt-5'>
          <h5>Summary</h5>
          <div className='row'>
            <div className='col-sm-4 pointer' onClick={() => openModal(true)}>
              <div className='card card-shadow p-4 text-success'>
                <div>Processed information</div>
                <small>(Valid dataset that was processed)</small>
                <h4 className='mt-3'>{patientList.filter((x) => x.isDataValid).length}</h4>
                <small className='mt-2'>Click to view</small>
              </div>
            </div>

            <div className='col-sm-4 pointer' onClick={() => openModal(false)}>
              <div className='card card-shadow p-4 text-danger'>
                <div>Invalid data</div>
                <small>(Dataset that was not processed)</small>
                <h4 className='mt-3'>{patientList.filter((x) => !x.isDataValid).length}</h4>
                <small className='mt-2'>Click to view</small>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PatientsUpload;
