import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

let sentryDsn = process.env.REACT_APP_SENTRY_DSN;

// Create Custom Sentry Route component
const history = createBrowserHistory();

if (sentryDsn) {
  Sentry.init({
    ignoreErrors: [
      'Error: NotSupportedError: The element has no supported sources',
      'The play method is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
      "NotAllowedError: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD",
      'The media resource indicated by the src attribute or assigned media provider object was not suitable.',
    ],
    dsn: sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    //enable to show user event UI for all errors. not ideal
    // beforeSend(event, hint) {
    //   // Check if it is an exception, and if so, show the report dialog
    //   if (event.exception) {
    //     Sentry.showReportDialog({ eventId: event.event_id });
    //   }
    //   return event;
    // },

    maxBreadcrumbs: 50,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <span id='scroll-here'></span>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
