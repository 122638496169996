// import React from 'react'
import NavBar from '../components/NavBar';
import PricingComponent from '../components/PricingComponent';
import PlanComparison from '../components/PlanComparison';
import FooterComponent from '../components/FooterComponent';
import FAQComponent from '../components/FAQComponent';

const Pricing = () => {
  return (
    <div className='bg-white'>
      <NavBar />
      <PricingComponent />
      <PlanComparison />
      <FAQComponent />
      <FooterComponent />
    </div>
  );
};

export { Pricing };
