import { Container, Row, Col } from 'react-bootstrap';
import development from '../../../../assets/images/development.png';
import { development_offerings } from '../constants';

const DevelopmentComponent = () => {
  return (
    <Container fluid className='development_container py-5 d-flex flex-column align-items-center'>
      <p className='text-center fs-4 mb-2'>
        Continuous Support <span className='fs-4 fw-bold'>for</span>
      </p>
      <h2 className='text-center fs-4 fw-semibold mb-4'>Business development</h2>
      <Col xs={12} md={6}>
        <img src={development} alt='Stats' className='img-fluid' />
      </Col>

      <Container className='mb-3'>
        <Row className='justify-content-center'>
          <Col lg={6}>
            <p className='text-center fs-4 mt-2'>
              Whether you are a startup or an established company, having the right support can make
              a significant difference
            </p>
          </Col>
        </Row>
      </Container>

      <a
        href=' https://forms.gle/YCPcR9CLVwFB3Rxi8'
        className='btn wellapartner px-4 py-2 d-flex align-items-center gap-3'
      >
        <span className='fs-5'>Get Started</span>
        <span className='material-symbols-outlined fs-5'>
          <i className='bi bi-arrow-right' />
        </span>
      </a>

      <Container className='m-5'>
        <Row className='justify-content-center'>
          {development_offerings.map((offering, index) => (
            <Col
              key={index}
              xs={12}
              lg={4}
              className={`mb-${index === development_offerings.length - 1 ? '0' : '5'} text-center`}
            >
              <div className='d-flex flex-column align-items-center align-items-md-start'>
                <div className='d-flex align-items-center gap-2 my-3'>
                  <img src={offering.icon} alt='' />
                  <p className='fw-bold m-0'>{offering.title}</p>
                </div>

                <p className='text-center text-md-start'>{offering.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default DevelopmentComponent;
