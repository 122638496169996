export interface IMedicationDispense {
  patientId: number;
  pharmacyId: number;
  sendSms: boolean;
  patientName: string;
  patientPhone: string;
  countryCode: string;
  drugs: Array<IMedicationDetail>;
}

export interface IMedicationDetail {
  medicationId: number;
  drugName: string;
  drugInstructions: string;
  drugFrequency: string;
  drugDosage: string;
  drugStrength: string;
  isConfidential: boolean;
  isChronic: boolean;
  prescribedDays: number;
}

export interface IMedicationModal {
  show: boolean;
  close: () => void;
  addMedication: Function;
  selectedMedication: IMedicationDetail | undefined;
}

export interface IDrugInteraction {
  drugA: string;
  drugB: string;
  interaction: string;
  contraIndication: string;
}

export interface IDrugRxcui {
  rxcui: number;
  drugId: number;
}

export interface IDrugSearchBar {
  callback?: (drugName: string) => void;
  inputStyle?: string;
}

export const defaultMedication: IMedicationDetail = {
  medicationId: 0,
  drugName: '',
  drugInstructions: '',
  drugFrequency: '',
  drugStrength: '',
  isConfidential: false,
  isChronic: false,
  prescribedDays: 3,
  drugDosage: '',
};
