import axios from 'axios';
import React, { FormEvent, useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  defaultBloodSugarReading,
  IBloodSugarReadingDTO,
} from '../../../interface/BloodSugarInterface';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';
import { IPatientModal } from '../../../interface/CommonInterface';
import { BloodSugarType } from '../../../utils/mappings';

const BloodSugarModal = ({ show, close, patientId, callback }: IPatientModal) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bloodSugar, setBloodSugar] = useState<IBloodSugarReadingDTO>(defaultBloodSugarReading);

  const handleChange = (input: string) => (event: any) => {
    setBloodSugar({
      ...bloodSugar,
      [input]: event.target.value,
    });
  };

  const saveReading = async (event: FormEvent) => {
    if (!isFormValidated('add-bs-modal')) {
      addToast('Please fill all fields', 'warning');
      return;
    }
    event.preventDefault();
    bloodSugar.patientId = patientId;

    setIsLoading(true);
    const url = `${endpoints.bloodSugar.mainUrl}`;

    try {
      await axios.post(url, bloodSugar);
      addToast('Blood sugar reading added successfully', 'success');
      if (callback) {
        callback();
      }
      close();
    } catch (error) {
      addToast('An error occured while saving data', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={close} backdrop={isLoading ? 'static' : true}>
      <form id='add-bs-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Add Blood Sugar Reading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={isLoading}>
            <div className='form-group'>
              <label className='required'>Reading Type</label>
              <select
                required
                className='custom-select'
                value={bloodSugar.readingType}
                onChange={handleChange('readingType')}
              >
                {BloodSugarType.map((x) => (
                  <option value={x.value}>{x.text}</option>
                ))}
              </select>
            </div>
            <div className='form-group'>
              <label className='required'>Glucose (mmol/l)</label>
              <input
                type='number'
                className='form-control'
                value={bloodSugar.glucose}
                onChange={handleChange('glucose')}
                required
                min={1}
                max={20}
                step={0.01}
              />
            </div>
            <div className='form-group'>
              <label>Reading date</label>
              <input
                type='date'
                className='form-control'
                value={bloodSugar.readingDate}
                onChange={handleChange('readingDate')}
                required
              />
            </div>
            <div className='form-group'>
              <label>Reading time</label>
              <input
                type='time'
                className='form-control'
                value={bloodSugar.readingTime}
                onChange={handleChange('readingTime')}
                required
              />
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' type='submit' onClick={saveReading}>
            Save
            {isLoading ? <ButtonLoader /> : ''}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default BloodSugarModal;
