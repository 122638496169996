import { BloodGroup } from '../utils/mappings';

export interface IHealthDetail {
  detailId: number;
  height: number;
  weight: number;
  bloodGroup: string;
  allergies: string;
  patientId: number;
  dateCreated: string;
}

export const defaultHealthDetail: IHealthDetail = {
  detailId: 0,
  height: 0,
  weight: 0,
  patientId: 0,
  bloodGroup: BloodGroup[0].value,
  allergies: '',
  dateCreated: new Date().toISOString().split('T')[0],
};
