import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import { AppProvider } from './context/AppContext';
import { ToastProvider, DefaultToastContainer } from 'react-toast-notifications';
import RefreshToast from './components/common/RefreshToast';

//pages import
import { Home } from './components/pages/wellapartner/pages/Home';
import { Testimonial } from './components/pages/wellapartner/pages/Testimonial';
import { Pricing } from './components/pages/wellapartner/pages/Pricing';
import Login from './components/auth/Login';
import Layout from './components/layout/Layout';
import AuthenticatedLayout from './components/layout/AuthenticatedLayout';
import EntryPage from './components/pages/entry/EntryPage';
import RefillsTable from './components/pages/refills/RefillsTable';
import NotificationsTable from './components/pages/notifications/NotificationsTable';
import Settings from './components/pages/settings/Settings';
import Patients from './components/pages/patients/Patients';
import DrugInteraction from './components/pages/entry/DrugInteraction';
import WalletsHome from './components/pages/wallets/WalletsHome';

//admin pages and layout
import AdminLayout from './components/admin_layout/AdminLayout';
import PharmacyDetails from './components/pages/admin/pharmacy/PharmacyDetails';
import PharmacyList from './components/pages/admin/pharmacy/PharmacyList';
import Messages from './components/pages/admin/messages/Messages';
import Templates from './components/pages/admin/Templates';

// stylesheet imports
import './components/base.css';
import './components/style.css';

import Users from './components/pages/admin/users/Users';
import Messaging from './components/pages/messaging/Messaging';
import ScrollToTop from './components/common/ScrollToTop';
import SubscriptionList from './components/pages/admin/subscriptions/SubscriptionList';
import AdminWallet from './components/pages/admin/wallet/AdminWallet';
import PatientsUpload from './components/pages/patients/PatientsUpload';

//sentry
import * as Sentry from '@sentry/react';
import { Alert } from 'react-bootstrap';
import SysAdminLayout from './components/admin_layout/SysAdminLayout';
import AuthenticatedSimpleLayout from './components/layout/AuthenticatedSimpleLayout';
import VaccinationForm from './components/pages/vaccination/VaccinationForm';
import LandingPage from './components/pages/vaccination/LandingPage';
import VaccinationGuide from './components/pages/vaccination/VaccinationGuide';
import Reports from './components/pages/admin/Reports';
import VaccinationReport from './components/pages/vaccination/VaccinationReport';
import VitalsLayout from './components/layout/VitalsLayout';

// //adds the context to window so we can access anywhere
declare global {
  interface Window {
    __react_toast_provider: any;
  }
}

window.__react_toast_provider = React.createRef();
// create a default container so we can override the styles
const ToastContainer = (props: any) => (
  <DefaultToastContainer style={{ zIndex: '1900' }} {...props} />
);

function App() {
  return (
    <Sentry.ErrorBoundary
      showDialog={true}
      fallback={
        <div className='text-center'>
          <Alert variant='danger'>A Serious Error Occured. Please Contact Admin.</Alert>
        </div>
      }
    >
      <ToastProvider components={{ ToastContainer }} ref={window.__react_toast_provider}>
        <AppProvider>
          {/* <ErrorBoundary> */}
          <BrowserRouter>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/testimonial' component={Testimonial} />
              <Route exact path='/pricing' component={Pricing} />
              <Layout exact path='/login' component={Login} />
              <AuthenticatedLayout exact path='/entry' component={EntryPage} />
              <AuthenticatedLayout
                exact
                path='/entry/druginteractions'
                component={DrugInteraction}
              />
              <AuthenticatedLayout exact path='/druginteractions' component={DrugInteraction} />
              <AuthenticatedLayout exact path='/refills' component={RefillsTable} />
              <AuthenticatedLayout exact path='/notifications' component={NotificationsTable} />
              <AuthenticatedLayout exact path='/settings' component={Settings} />
              <AuthenticatedLayout exact path='/patients' component={Patients} />
              <AuthenticatedLayout exact path='/patients/upload' component={PatientsUpload} />
              <AuthenticatedLayout exact path='/messaging' component={Messaging} />
              <AuthenticatedLayout exact path='/wallets' component={WalletsHome} />
              {/* Admin Routes */}
              <AdminLayout exact path='/admin/pharmacy/details' component={PharmacyDetails} />
              <SysAdminLayout exact path='/admin/pharmacy/list' component={PharmacyList} />
              <SysAdminLayout exact path='/admin/vitals' component={Reports} />

              <AdminLayout exact path='/admin/messages' component={Messages} />
              <AdminLayout exact path='/admin/users' component={Users} />
              <AdminLayout exact path='/admin/templates' component={Templates} />
              <AdminLayout exact path='/admin/wallets' component={AdminWallet} />
              <AdminLayout
                exact
                path='/admin/:pharmacyId/subscriptions'
                component={SubscriptionList}
              />
              {/*Non app routes*/}
              <AuthenticatedSimpleLayout exact path='/vitals/hpv' component={VaccinationForm} />
              <AuthenticatedSimpleLayout exact path='/vitals/home' component={LandingPage} />
              <AuthenticatedSimpleLayout exact path='/vitals/guide' component={VaccinationGuide} />
              <VitalsLayout exact path='/vitals/report' component={VaccinationReport} />
            </Switch>
            <RefreshToast />
            <ScrollToTop />
          </BrowserRouter>
          {/* </ErrorBoundary> */}
        </AppProvider>
      </ToastProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
