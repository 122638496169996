export interface WalletAccount {
  totalCreditsToday: number;
  totalDebitsToday: number;
  walletBalance: number;
  totalCredits: number;
  totalDebits: number;
}

export interface WalletTransaction {
  transactionType: string;
  transactionStatus: string;
  amount: number;
  transactionDate: string;
  transactionName: string;
  providerName: string;
  narration: string;
}

export interface WalletTransactionHistory {
  transactionDate: string;
  transactions: Array<WalletTransaction>;
}

export interface WalletAccountDetails {
  pharmacyName: string;
  pharmacyCode: string;
  accountId: string;
  walletBalance: number;
  totalCredits: number;
  totalDebits: number;
  bankName: string;
  bankAccountNumber: string;
  bankAccountName: string;
}

export interface WalletFragmentInterface {
  pharmacyCode: string;
  clientId: number;
}

export const initialWalletDetails: WalletAccount = {
  totalCredits: 0,
  totalCreditsToday: 0,
  totalDebits: 0,
  totalDebitsToday: 0,
  walletBalance: 0,
};
