import { useCallback, useContext, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import {
  IPatientModal,
  defaultPatientData,
  IPatientData,
} from '../../../interface/PatientInterface';
import { useState } from 'react';
import ButtonLoader from '../../common/ButtonLoader';
import { isFormValidated } from '../../../utils/formUtils';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import { AppContext } from '../../../context/AppContext';
import { IAppState } from '../../../interface/AppInterface';
import Loader from '../../common/Loader';
import { CountryCodes } from '../../../utils/mappings';

const PatientModal = ({ show, close, selectedPatient, callback }: IPatientModal) => {
  const [patient, setPatient] = useState<IPatientData | undefined>();
  const [isBtnLoading, setBtnLoading] = useState(false);

  const { appState } = useContext(AppContext);

  const handleChange = (input: string) => (event: any) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setPatient({
      ...patient!,
      [input]: value,
    });
  };

  const savePatientInfo = async (event: any) => {
    if (!isFormValidated('patient-modal')) {
      return;
    }

    if (!patient) {
      return;
    }

    event.preventDefault();

    const applicationState = appState as IAppState;
    patient!.pharmacyId = applicationState.pharmacyId;

    //if id > 0, put,
    if (patient!.patientId > 0) {
      updatePatientInfo();
      return;
    }

    event.preventDefault();

    setBtnLoading(true);
    //else post

    const url = endpoints.patients.mainUrl;
    try {
      const response = await axios.post(url, patient);
      addToast('Patient data saved successfully', 'success');
      if (callback) {
        callback(response.data);
      }
      close();
      //return patient details ??
    } catch (error) {
      addToast('An error occured while adding the patient', 'error');
    } finally {
      setBtnLoading(false);
    }
  };

  const updatePatientInfo = async () => {
    setBtnLoading(true);
    //else post
    const url = `${endpoints.patients.mainUrl}/${patient!.patientId}`;
    try {
      await axios.put(url, patient);
      addToast('Patient data updated successfully', 'success');
      if (callback) {
        selectedPatient!.patientName = patient!.patientName;
        selectedPatient!.phoneNumber = patient!.phone;
        callback(selectedPatient!);
      }
      close();
    } catch (error) {
      addToast('An error occured while updating the patient', 'error');
    } finally {
      setBtnLoading(false);
    }
  };

  const getPatientData = useCallback(async () => {
    if (selectedPatient?.patientId === 0) {
      return;
    }

    const url = `${endpoints.patients.mainUrl}/${selectedPatient?.patientId}`;

    try {
      const response = await axios.get(url);
      setPatient(response.data);
    } catch (error) {
      addToast('Error occured while getting patient data', 'error');
    }
  }, [selectedPatient?.patientId]);

  useEffect(() => {
    if (show) {
      if (selectedPatient) {
        //get patient details
        setPatient(undefined);
        getPatientData();
        //
      } else {
        setPatient(defaultPatientData);
      }
    }
  }, [show, selectedPatient, getPatientData]);

  return (
    <Modal
      show={show}
      onHide={isBtnLoading ? undefined : () => close()}
      backdrop={isBtnLoading ? 'static' : true}
    >
      <form id='patient-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Add new patient</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {patient ? (
            <fieldset disabled={isBtnLoading}>
              <div className='form-group'>
                <label className='required'>Patient name</label>
                <input
                  type='text'
                  className='form-control'
                  value={patient.patientName}
                  onChange={handleChange('patientName')}
                  required
                />
              </div>
              <div className='form-group mt-3'>
                <label className='required'>Phone Number</label>

                <div className='row'>
                  <div className='col-4'>
                    <select className='form-select col-sm-4' value={patient.countryCode}>
                      {CountryCodes.map((data) => (
                        <option key={data.value} value={data.value}>
                          {data.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='col-8'>
                    <input
                      type='tel'
                      className='form-control col-sm-8'
                      value={patient.phone}
                      onChange={handleChange('phone')}
                      pattern='[0-9]{10}|[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                      required
                    />
                  </div>
                </div>
              </div>
              <div className='form-group mt-3'>
                <label>Email Address</label>
                <input
                  type='email'
                  className='form-control'
                  value={patient.email}
                  onChange={handleChange('email')}
                />
              </div>
              <div className='form-group mt-3'>
                <label>Date Of Birth</label>
                <input
                  type='date'
                  className='form-control'
                  value={patient.dateOfBirth !== null ? patient.dateOfBirth.split('T')[0] : ''}
                  onChange={handleChange('dateOfBirth')}
                />
              </div>

              <div className='form-group mt-3'>
                <label>Home Address</label>
                <textarea
                  className='form-control'
                  value={patient.patientAddress}
                  onChange={handleChange('patientAddress')}
                ></textarea>
              </div>
            </fieldset>
          ) : (
            <Loader />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' type='submit' onClick={savePatientInfo}>
            {selectedPatient ? 'Update Patient' : 'Add Patient'}
            {isBtnLoading ? <ButtonLoader /> : ''}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default PatientModal;
