import { useState, useCallback, useEffect } from 'react';
import { formatNumber } from '../../../utils/formatNumber';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import Loader from '../../common/Loader';
import {
  WalletAccount,
  WalletFragmentInterface,
  WalletTransactionHistory,
  initialWalletDetails,
} from '../../../interface/WalletInterface';
import { formatDateTime } from '../../../utils/dateTimeUtils';
import WithdrawalModal from './WithdrawalModal';
import { IPharmacyListDTO } from '../../../interface/PharmacyInterface';
import ButtonLoader from '../../common/ButtonLoader';
import PageTour from '../../common/PageTour';
import { walletTourSteps } from '../../../utils/pageTourSteps';

const WalletFragment = (fragmentData: WalletFragmentInterface) => {
  const [walletData, setWalletData] = useState<WalletAccount>(initialWalletDetails);
  const [walletLoading, setWalletLoading] = useState(false);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [openWithdrawalModal, setOpenWithdrawalModal] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState<WalletTransactionHistory[]>([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pharmacyList, setPharmacyList] = useState<IPharmacyListDTO[]>([]);
  const [pharmacyCode, setPharmacyCode] = useState('');

  const getTransactionHistory = useCallback(
    async (pharmacyCode: string, clientId: number, pageIndex = 1) => {
      let url = `${endpoints.wallets.walletTransactions.mainUrl}/${pharmacyCode}?pageIndex=${pageIndex}`;
      if (pharmacyCode === '') {
        url = `${endpoints.wallets.walletTransactions.mainUrl}/client/${clientId}?pageIndex=${pageIndex}`;
      }

      setTransactionLoading(true);

      try {
        const response = await axios.get(url);
        const transactionData: WalletTransactionHistory[] = response.data;

        setTransactionHistory((old) => [...old, ...transactionData]);
      } catch (error) {
        addToast('Error getting transaction history', 'error');
        setTransactionHistory([]);
      } finally {
        setTransactionLoading(false);
      }
    },
    []
  );

  const getPharmacyList = useCallback(async (clientId: number) => {
    try {
      const response = await axios.get(`${endpoints.pharmacies.mainUrl}/client/${clientId}/list`);
      const pharmacies = response.data as IPharmacyListDTO[];
      setPharmacyList(pharmacies);
    } catch (error) {
      addToast('Error fetching pharmacy list', 'error');
    }
  }, []);

  const getWalletBalance = useCallback(async (pharmacyCode: string, clientId: number) => {
    let url = `${endpoints.wallets.walletAccounts.mainUrl}/${pharmacyCode}`;

    if (pharmacyCode === '') {
      url = `${endpoints.wallets.walletAccounts.mainUrl}/client/${clientId}`;
    }
    setWalletLoading(true);
    try {
      const response = await axios.get(url);
      setWalletData(response.data);
    } catch (error) {
      addToast('Error getting wallet balances', 'error');
      setWalletData(initialWalletDetails);
    } finally {
      setWalletLoading(false);
    }
  }, []);

  const changePharmacy = (event: any) => {
    const pharmacyCode = event.target.value;
    setPharmacyCode(pharmacyCode);
    setTransactionHistory([]);
    getTransactionHistory(pharmacyCode, fragmentData.clientId);
    getWalletBalance(pharmacyCode, fragmentData.clientId);
  };

  const loadMore = () => {
    const updatedPageIndex = pageIndex + 1;
    setPageIndex(updatedPageIndex);
    getTransactionHistory(pharmacyCode, fragmentData.clientId, updatedPageIndex);
  };

  useEffect(() => {
    getTransactionHistory(fragmentData.pharmacyCode, fragmentData.clientId);
    getWalletBalance(fragmentData.pharmacyCode, fragmentData.clientId);
    getPharmacyList(fragmentData.clientId);
    setPharmacyCode(fragmentData.pharmacyCode);
  }, [
    getTransactionHistory,
    getWalletBalance,
    getPharmacyList,
    fragmentData.clientId,
    fragmentData.pharmacyCode,
  ]);

  let withdrawalModal = (
    <WithdrawalModal
      open={openWithdrawalModal}
      close={() => setOpenWithdrawalModal(false)}
      clientId={fragmentData.clientId}
    />
  );

  let transactions = (
    <div className='row'>
      {transactionHistory.map((groupData, key) => (
        <div key={key} className='col-12 mt-3'>
          <div style={{ fontWeight: 'bold' }}>{groupData.transactionDate.split('T')[0]}</div>
          {groupData.transactions.map((data, key2) => (
            <div
              key={key2}
              title={data.transactionType}
              className={`card shadow ${
                data.transactionType === 'Credit' ? 'text-success' : 'text-danger'
              }`}
            >
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-2'>
                    {data.transactionType === 'Credit' ? (
                      <span className='bi bi-arrow-down-left-circle'>{data.transactionType}</span>
                    ) : (
                      <span className='bi bi-arrow-up-right-circle'>{data.transactionType}</span>
                    )}
                  </div>
                  {/* <div className='d-none d-sm-block col-sm-3'>
                    {formatDateTime(data.transactionDate)}
                  </div> */}
                  {/* <div className='d-none d-sm-block col-sm-2'>{data.transactionType}</div> */}

                  <strong className='col-md-2'>{`₦ ${data.amount.toLocaleString()}`}</strong>
                  {/* <div className='col-3 d-sm-none d-block '>
                  {data.transactionType === 'Credit' ? (
                    <span className='bi bi-arrow-down-left-circle'></span>
                  ) : (
                    <span className='bi bi-arrow-up-right-circle'></span>
                  )}
                </div> */}
                  {/* <div className='col-sm-2'>{data.transactionStatus}</div> */}
                  <div className='col-md-4'>
                    <small className='text-dark'>Description: </small> {data.transactionName}
                  </div>
                  <div className='col-md-4'>
                    <small className='text-dark'>Recipient: </small>
                    {data.providerName}
                  </div>
                  {/* <div className='d-sm-none d-block col-sm-3'>
                    {formatDateTime(data.transactionDate)}
                  </div> */}
                </div>
                <div className='row mt-sm-4 mt-2'>
                  <small className='col-6 text-start'>{formatDateTime(data.transactionDate)}</small>
                  <small className='col-6 text-end'>{data.transactionStatus}</small>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  return (
    <div className='container'>
      <PageTour
        steps={walletTourSteps}
        page='wallet'
        run={localStorage.getItem('walletTour') === 'completed' ? false : true}
      />
      {withdrawalModal}
      {/* Pharmacy Dropdown */}
      <div className='row ps-3 pe-3'>
        <div className='col-sm-6'>
          <div className='pharmacy-view'>
            <label>Select a Pharmacy Wallet to view</label>
            <select className='form-select' value={pharmacyCode} onChange={changePharmacy}>
              <option value=''>All Pharmacies</option>
              {pharmacyList.map((pharmacy) => (
                <option key={pharmacy.pharmacyId} value={pharmacy.pharmacyCode}>
                  {pharmacy.pharmacyName}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Withdraw Button */}
        <div className='col-sm-6 float-end'>
          <div className='mt-4'>
            <button
              onClick={() => setOpenWithdrawalModal(true)}
              className='float-end btn btn-success'
            >
              Withdraw
            </button>
          </div>
        </div>
      </div>
      {/* Wallet Balance Cards */}
      <div className='col-12 my-5'>
        {walletLoading ? (
          <Loader />
        ) : (
          <div className='row'>
            <div className='col-md-4 balance-view'>
              <div className='card shadow'>
                <div className='card-body'>
                  <div className='text-start h5'>Balance</div>
                  <div className='text-center h5 text-primary'>{`₦${formatNumber(
                    walletData.walletBalance
                  )}`}</div>
                </div>
              </div>
            </div>
            <div className='col-md-4 total-inflow'>
              <div className='card shadow'>
                <div className='card-body'>
                  <div className='text-start h5'>Total Inflow</div>
                  <div className='text-center h5 text-success'>{`₦${formatNumber(
                    walletData.totalCredits
                  )}`}</div>
                </div>
              </div>
            </div>
            <div className='col-md-4 total-outflow'>
              <div className='card shadow'>
                <div className='card-body'>
                  <div className='text-start h5'>Total Outflow</div>
                  <div className='text-center h5 text-danger'>{`₦${formatNumber(
                    walletData.totalDebits
                  )}`}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Transactions */}
      <div className='col-12'>
        {transactionLoading && transactionHistory.length === 0 ? (
          <Loader />
        ) : (
          <div className='transaction-history'>
            <h4>Transactions</h4>
            <div className='justify-content-start'>{transactions}</div>
            {transactionHistory.length > 0 ? (
              <div className='mt-2'>
                <button className='btn btn-primary' onClick={loadMore}>
                  Load more
                  {transactionLoading ? <ButtonLoader /> : ''}
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default WalletFragment;
