import { Link } from 'react-router-dom';
import shield from '../../../assets/images/vitals/shield.png';
import { useCallback, useEffect, useState } from 'react';
import { defaultSummary, IVaccinationSummary } from '../../../interface/VaccinationInterface';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';

const LandingPage = () => {
  const [summary, setSummary] = useState<IVaccinationSummary>(defaultSummary);

  const getSummary = useCallback(async () => {
    const url = `${endpoints.vaccination.mainUrl}/summary`;
    try {
      const response = await axios.get(url);
      setSummary(response.data);
    } catch (error: any) {
      addToast('Error occured when fetching data');
    }
  }, []);

  useEffect(() => {
    getSummary();
  }, [getSummary]);
  return (
    <section className='pt-5 content-section pb-5 mb-5 rounded-0'>
      <h3 className='text-center'>
        <img
          src={require('../../../assets/images/vitals/logo.svg').default}
          className='img-fluid'
          alt=''
        />
      </h3>

      <div className='mt-5 text-center'>
        <img src={shield} className='img-fluid' alt='wellahealth' />
      </div>
      <div className='mt-5'>
        <h2 className='d-flex align-items-center'>
          <img
            src={require('../../../assets/images/vitals/female.svg').default}
            className='img-fluid'
            alt=''
          />
          <span className='ms-3 fw-500'>Impact so far</span>
        </h2>

        <div className='row mt-5'>
          <div className='col-md-6'>
            <div className='card vitals-bg-secondary p-md-5 p-3'>
              <Link to='/vitals/home' className='d-flex justify-content-between align-items-center'>
                <div>
                  <h4 className='vitals-text-dark fs-20 mb-0'>You have engaged</h4>
                  <div className='fs-38 fw-700 vitals-text-primary'>
                    {summary.totalForPharmacy} Caregivers
                  </div>
                </div>
                <div>
                  <img
                    src={require('../../../assets/images/vitals/crown.svg').default}
                    className='img-fluid vitals-img-70'
                    alt=''
                  />
                </div>
              </Link>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='card vitals-bg-primary p-md-5 p-3'>
              <Link
                to='/vitals/home'
                className='d-flex justify-content-between text-white align-items-center'
              >
                <div>
                  <h4 className='text-white fs-20 mb-0'>Campaign has engaged</h4>
                  <div className='fs-38 fw-700'>{summary.totalCount} Caregivers</div>
                </div>
                <div>
                  <img
                    src={require('../../../assets/images/vitals/star.svg').default}
                    className='img-fluid vitals-img-70'
                    alt=''
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='row justify-content-end'>
        <div className='col-md-6'>
          <div className='mt-4 row'>
            <div className='col-sm-6 d-grid mt-3'>
              <Link
                to='/vitals/guide'
                className='btn btn-outline-secondary vitals-bg-white fs-18 fw-500 vitals-text-primary vitals-border-primary px-5'
              >
                View Guide
              </Link>
            </div>
            <div className='col-sm-6 d-grid mt-3'>
              <Link
                to='/vitals/hpv'
                className='btn btn-secondary text-white vitals-bg-primary fs-18 fw-500 px-4'
              >
                Record Engagement
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingPage;
