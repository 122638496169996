import amela from '../../../assets/images/partners/Amela.svg';
import justrite from '../../../assets/images/partners/Justrite.svg';
import lifestores from '../../../assets/images/partners/LifeStores.svg';
import medplus from '../../../assets/images/partners/MedPlus.svg';
import nettPharmacy from '../../../assets/images/partners/NettPharmacy.svg';
import novomed from '../../../assets/images/partners/Novomed.svg';
import safari from '../../../assets/images/partners/Safari.svg';
import tonia from '../../../assets/images/partners/Tonia.svg';
import vanguard from '../../../assets/images/partners/Vanguard.svg';
import drug_refill from '../../../assets/images/icons/DrugRefill.svg';
import basket from '../../../assets/images/icons/Basket.svg';
import hospital from '../../../assets/images/icons/Hospital.svg';
import googlePlay from '../../../assets/images/icons/GooglePlayLogo.svg';
import patient from '../../../assets/images/icons/patient.svg';
import airplay from '../../../assets/images/icons/Airplay.svg';
import happyMask from '../../../assets/images/icons/MaskHappy.svg';
import chatCircle from '../../../assets/images/icons/ChatCircleText.svg';
import firstAid from '../../../assets/images/icons/FirstAid.svg';
import hands from '../../../assets/images/icons/hands.svg';
import customer from '../../../assets/images/icons/customer.svg';
import books from '../../../assets/images/icons/Books.svg';
import addressBook from '../../../assets/images/icons/AddressBook.svg';
import coins from '../../../assets/images/icons/Coins.svg';

export const plansData = [
  {
    title: 'Wellapartner Basic',
    priceMonthly: 1000,
    priceYearly: 12000,
    discount: 0.75,
    benefitTitle: "What's included:",
    benefits: [
      'Hmo Drug Refills',
      'Handle Malaria Claims',
      'Wellapartner App Access',
      'Patient Records Tracking',
      'Business Support/Staff Training',
      'Pharmacy listing on Wella Platform',
      'Drugs Pickups for Telemedicine',
      'Patient access to telemedicine',
    ],
    isHighlighted: false,
  },
  {
    title: 'Wellapartner Standard',
    priceMonthly: 2500,
    priceYearly: 30000,
    discount: 0.75,
    benefitTitle: 'Everything on Basic, plus:',
    benefits: ['Drug pickups for telemedicine'],
    isHighlighted: false,
  },
  {
    title: 'Wellapartner Business',
    priceMonthly: 5000,
    priceYearly: 60000,
    discount: 0.75,
    benefitTitle: 'Everything on Standard, plus:',
    benefits: [
      'Patient Management Portal',
      'Bulk SMS',
      'Automated Patient Follow Up',
      'Chronic Disease Management Dasboard',
    ],
    isHighlighted: true,
  },
  {
    title: 'Wellapartner Premium',
    priceMonthly: 7000,
    priceYearly: 84000,
    discount: 0.75,
    benefitTitle: 'Everything on Business, plus:',
    benefits: ['Medication Group Purchase', 'Inventory Insights', 'Marketing Support'],
    isHighlighted: false,
  },
];

export const partners = [
  {
    name: 'Med Plus',
    image: medplus,
  },
  {
    name: 'Tonia',
    image: tonia,
  },
  {
    name: 'Life Stores',
    image: lifestores,
  },
  {
    name: 'Nett Pharmacy',
    image: nettPharmacy,
  },
  {
    name: 'Justrite',
    image: justrite,
  },
  {
    name: 'Vanguard',
    image: vanguard,
  },
  {
    name: 'Safari',
    image: safari,
  },
  {
    name: 'Novomed',
    image: novomed,
  },
  {
    name: 'Amela',
    image: amela,
  },
];

export const testimonials = [
  {
    id: 1,
    name: 'Pharm Adeyemo',
    testimony:
      'Signing up to wellapartner business has transformed the way i connect with patients and the results have being outstanding for my sales',
    city: 'Abuja',
    country: 'Nigeria',
  },
  {
    id: 2,
    name: 'Pharm Loveth',
    testimony:
      'Partnering with wellahealth has really improved the footfall at my pharmacy with the patients who now come to pick up medications',
    city: 'Port Harcourt',
    country: 'Nigeria',
  },
  {
    id: 3,
    name: 'Pharm Taj',
    testimony:
      'Providing malaria treatment for wellahealth subscribers has improved my visibility in the community and helped my business a lot. The service is great',
    city: 'Kano',
    country: 'Nigeria',
  },
  {
    id: 4,
    name: 'Pharm Bukky',
    testimony:
      'Getting inventory supplies on credit has helped me manage my cash flow and stock properly to meet the needs of my clients, i absolutely recommend',
    city: 'Lagos',
    country: 'Nigeria',
  },
];

export const services = [
  {
    icon: drug_refill,
    title: 'HMO DRUG REFILLS',
    description: 'Supply enrollees of over 25 HMOs in Nigeria.',
  },
  {
    icon: basket,
    title: 'TELEMEDICINE DRUG PICKUPS',
    description: 'List your pharmacy as a medication pick up point for telemedicine services.',
  },
  {
    icon: hospital,
    title: 'HANDLE MALARIA CLAIMS',
    description: 'Get paid for medications and tests provided.',
  },
];

export const offerings = [
  {
    icon: googlePlay,
    title: 'WELLAPARTNER APP ACCESS',
    description:
      'Get access to wellapartner app & enjoy smooth fulfilment & payment flow for wellahealth requests',
  },
  {
    icon: patient,
    title: 'PATIENT RECORDS TRACKING',
    description:
      'Keep track of data for your walk-in patients and leverage that for better and effective patient  engagement.',
  },
  {
    icon: airplay,
    title: 'CHRONICARE DASHBOARD',
    description:
      'Monitor your chronic disease patients effectively with our easy to use patient dashboard and improve care outcomes.',
  },
  {
    icon: happyMask,
    title: 'AUTOMATED PATIENT FOLLOW UP',
    description:
      'Automate your patient follow up process and boost customer satisfaction and retention.',
  },
  {
    icon: chatCircle,
    title: 'BULK SMS',
    description:
      'Engage your pool of clients with discounts, freebies and new product information using via sms.',
  },
  {
    icon: patient,
    title: 'PATIENT ACCESS TO TELEMEDICINE',
    description:
      'Give your clients access to telemedicine offerings right from your pharmacy premise and improve client satisfaction.',
  },
];

export const development_offerings = [
  {
    icon: firstAid,
    title: 'MEDICATION GROUP PURCHASE',
    description:
      'Get financing for medication purchase though our medication bulk purchase arrangement for partner pharmacies. ',
  },
  {
    icon: hands,
    title: 'BUSINESS SUPPORT & STAFF TRAINING',
    description:
      'Get access to business support materials and opportunites as well as training to boost your staff efficiency',
  },
  {
    icon: customer,
    title: 'MARKETING SUPPORT',
    description:
      'Get support for better community engagement through digital and physical channels.',
  },
  {
    icon: books,
    title: 'INVENTORY INSIGHTS',
    description:
      "Leverage pharmacy business data for your business planning from wellahealth's inventory and business database.",
  },
  {
    icon: addressBook,
    title: 'LISTING ON WELLAPARTNER PLATFORM',
    description:
      'Gain visibility to the wider health system and access opportunities by being a part of the wellahealth network.',
  },
  {
    icon: coins,
    title: 'SECURE BUSINESS LOANS',
    description:
      'Our loans provide the financial foundation you need to expand your services, and enhance your overall pharmacy experience.',
  },
];
