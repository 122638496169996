import { IAppState, IDispatch, initialAppState } from '../interface/AppInterface';

const appReducer = (state: IAppState, action: IDispatch) => {
  switch (action.type) {
    case 'LOG_OUT':
      localStorage.removeItem('state');
      sessionStorage.clear();
      window.location.href = '/login';
      return initialAppState;
    case 'SET_PHARMACY_LIST':
      return {
        ...state,
        pharmacyList: action.payload,
      };
    case 'SET_CLIENT_PHARMACY_LIST':
      return {
        ...state,
        clientPharmacyList: action.payload,
      };
    case 'SET_PHARMACY_CODE':
      return {
        ...state,
        pharmacyCode: action.payload,
      };
    case 'LOG_IN':
      return {
        ...state,
        isAuthenticated: true,
        pharmacyId: action.payload.pharmacyId,
        pharmacyCode: action.payload.pharmacyCode,
        pharmacyName: action.payload.pharmacyName,
        userId: action.payload.userId,
        token: action.payload.token,
        entitlements: action.payload.entitlements,
        clientId: action.payload.clientId,
      };
    case 'UPDATE_PROP': //updates a property of the AppState object e.g. partnerName. to use, pass the property name and new value e.g 'partnerName', 'Adam
      return { ...state, [action.payload.propName]: action.payload.propData };

    case 'SET_MENU_ITEMS':
      return { ...state, menuItems: action.payload };

    case 'SELECT_PHARMACY':
      return {
        ...state,
        selectedPharmacy: action.payload,
      };
    case 'GOTO_PHARMACY':
      return {
        ...state,
        pharmacyId: action.payload.pharmacyId,
        pharmacyCode: action.payload.pharmacyCode,
        pharmacyName: action.payload.pharmacyName.split('|')[0],
        clientId: action.payload.clientId,
      };
    default:
      return state;
  }
};

export default appReducer;
