import { FormEvent, useState } from 'react';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import ButtonLoader from '../../common/ButtonLoader';

const Reports = () => {
  const [loading, setLoading] = useState(false);

  const getReport = async (event: FormEvent) => {
    event.preventDefault();

    const url = `${endpoints.vaccination.mainUrl}/export`;
    try {
      setLoading(true);

      await axios.get(url);
      addToast('Data sent to tomi@wellahealth.com', 'success');
    } catch (error) {
      addToast('An error occurred while fetching the report. Please try again', 'error');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='row justify-content-center mt-100'>
      <div className='col-md-4'>
        <h4>Vitals Report</h4>
        <div className='card mt-5'>
          <button className='btn btn-primary' onClick={getReport} disabled={loading}>
            Export to mail
            {loading && <ButtonLoader />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Reports;
