export interface IBloodPressureReading {
  readingId: number;
  readingTime: string;
  systolic: number;
  diastolic: number;
  heartRate: number;
  patientId: number;
  dateCreated: string;
  dateUpdated: string;
}

export interface IBloodPressureReadingDTO {
  patientId: number;
  readingDate: string;
  readingTime: string;
  systolic: number;
  diastolic: number;
  heartRate: number;
}

export const defaultBloodPressureReading: IBloodPressureReadingDTO = {
  patientId: 0,
  readingDate: new Date().toISOString().split('T')[0],
  readingTime: new Date().toLocaleTimeString(),
  systolic: 79,
  diastolic: 119,
  heartRate: 70,
};
