import axios from 'axios';
import { FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import { ITemplateDTO } from '../../../interface/TemplateInterface';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';
import Loader from '../../common/Loader';

const Templates = () => {
  const [template, setTemplate] = useState<ITemplateDTO | undefined>();
  const [isLoading, setLoading] = useState(false);
  const { appState } = useContext(AppContext);
  const [preview, setPreview] = useState({
    acuteWelcomePreview: '',
    acuteFollowUpPreview: '',
    chronicWelcomePreview: '',
    chronicHalfWayPreview: '',
    chronicReminderPreview: '',
  });

  const handleChange = (input: string) => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTemplate({
      ...template!,
      [input]: event.target.value,
    });

    const templatePreview = loadPreview(event.target.value);

    const previewInput = `${input}Preview`;

    setPreview({
      ...preview,
      [previewInput]: templatePreview,
    });
  };

  const loadPreview = useCallback(
    (messageTemplate: string) => {
      const patientName = 'John Doe';
      const drugName = 'Panadol 500mg';
      const dose = '2 tabs 3 times daily';
      const drugdose = 'Panadol 500mg 2 tabs 3 times daily';
      const halfway = '5 days';
      const date = new Date().toISOString().split('T')[0];

      let updatedTemplate = messageTemplate
        .replaceAll('[NAME]', patientName)
        .replaceAll('[PHARMACY]', appState.selectedPharmacy.pharmacyName.split('|')[0])
        .replaceAll('[DRUG]', drugName)
        .replaceAll('[DOSE]', dose)
        .replaceAll('[DRUGDOSE]', drugdose)
        .replaceAll('[HALFWAY]', halfway)
        .replaceAll('[DATE]', date);

      return updatedTemplate;
    },
    [appState.selectedPharmacy.pharmacyName]
  );

  const loadTemplates = useCallback(async () => {
    if (appState.selectedPharmacy.pharmacyId === 0) {
      return;
    }

    const pharmacyId = appState.selectedPharmacy.pharmacyId;
    const url = `${endpoints.templates.getByPharmacy}/${pharmacyId}`;

    try {
      const response = await axios.get(url);
      const data = response.data as ITemplateDTO;
      setTemplate(data);

      //load preview
      //https://reactjs.org/docs/hooks-reference.html#functional-updates
      setPreview((f) => {
        return {
          ...f,
          acuteWelcomePreview: loadPreview(data.acuteWelcome),
          acuteFollowUpPreview: loadPreview(data.acuteFollowUp),
          chronicWelcomePreview: loadPreview(data.chronicWelcome),
          chronicHalfWayPreview: loadPreview(data.chronicHalfWay),
          chronicReminderPreview: loadPreview(data.chronicReminder),
        };
      });
    } catch (error) {
      addToast('An error occured while getting templates', 'error');
    }
  }, [appState.selectedPharmacy, loadPreview]);

  const saveTemplate = async (event: FormEvent) => {
    if (!isFormValidated('template-form')) {
      return;
    }
    event.preventDefault();

    let url = `${endpoints.templates.mainUrl}/${template!.templateId}`;

    if (template!.templateId === 0) {
      url = `${endpoints.templates.mainUrl}`;
    }
    template!.pharmacyId = appState.selectedPharmacy.pharmacyId;

    setLoading(true);
    try {
      if (template!.templateId === 0) {
        await axios.post(url, template);
      } else {
        await axios.put(url, template);
      }

      addToast('Template saved successfully', 'success');
    } catch (error) {
      addToast('An error occured while saving', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTemplates();
  }, [loadTemplates]);

  return (
    <div className='row justify-content-center'>
      {template ? (
        <div className='col-sm-12 card pt-5 pb-5 pr-4 pl-4'>
          <div className='row'>
            <div className='col-sm-6'>
              <h3 className='mb-4'>SMS Templates</h3>
              <div>The fields you can use are:</div>
              <ul>
                <li className='mt-2'>
                  <strong>[NAME]</strong> - The patient's name
                </li>
                <li className='mt-2'>
                  <strong>[PHARMACY]</strong> - Your pharmacy's name
                </li>
                <li className='mt-2'>
                  <strong>[DRUG]</strong> - The prescribed drug name
                </li>
                <li className='mt-2'>
                  <strong>[DOSE]</strong> - The prescribed drug dosage
                </li>
                <li className='mt-2'>
                  <strong>[DRUGDOSE]</strong> - A combination of prescribed drug name and dose
                </li>
                <li className='mt-2'>
                  <strong>[HALFWAY]</strong> - The number of days remaining before a refill
                </li>
                <li className='mt-2'>
                  <strong>[DATE]</strong> - Visit date (dd/mm/yy)
                </li>
              </ul>
            </div>
            <div className='col-sm-6'>
              <h3 className='mb-4'>Preview</h3>
              <div className='mt-2'>
                A preview of the message your patient will get. For example, if a patient has the
                following details
              </div>
              <div className='mt-2'>
                Patient with name <strong>John Doe</strong> visited your pharmacy
                <strong className='ms-1'>{appState.selectedPharmacy.pharmacyName}</strong>
              </div>
              <div className='mt-2'>
                Patient was given <strong>Panadol 500mg</strong> to be taken
                <strong className='ms-1'>3 times daily after meals</strong>
              </div>
              <div className='mt-2'>
                Patient visited your pharmacy on <strong>01/01/2023</strong> and is due for a refill
                in
                <strong>10 days</strong>
              </div>
            </div>
          </div>

          <form id='template-form' className='mt-2'>
            <fieldset disabled={isLoading}>
              <h5 className='text-info'>ACUTE</h5>
              <div className='row mt-1'>
                <div className='form-group col-sm-6'>
                  <strong>WELCOME</strong>
                  <textarea
                    className='form-control'
                    value={template.acuteWelcome}
                    onChange={handleChange('acuteWelcome')}
                    required
                    rows={4}
                  />
                </div>
                <div className='form-group col-sm-6'>
                  <strong>WELCOME (Preview)</strong>
                  <textarea
                    className='form-control auto-height'
                    value={preview.acuteWelcomePreview}
                    readOnly
                    rows={4}
                  />
                </div>
              </div>
              <div className='row mt-5'>
                <div className='form-group col-sm-6'>
                  <strong>FOLLOW UP</strong>
                  <textarea
                    className='form-control'
                    value={template.acuteFollowUp}
                    onChange={handleChange('acuteFollowUp')}
                    required
                    rows={4}
                  />
                </div>
                <div className='form-group col-sm-6'>
                  <strong>FOLLOW UP (Preview)</strong>
                  <textarea
                    className='form-control auto-height'
                    value={preview.acuteFollowUpPreview}
                    readOnly
                    rows={4}
                  />
                </div>
              </div>
              <hr />
              <h5 className='text-info mt-5'>CHRONIC</h5>
              <div className='row'>
                <div className='form-group col-sm-6'>
                  <strong>WELCOME</strong>
                  <textarea
                    className='form-control'
                    value={template.chronicWelcome}
                    onChange={handleChange('chronicWelcome')}
                    required
                    rows={4}
                  />
                </div>
                <div className='form-group col-sm-6'>
                  <strong>WELCOME (Preview)</strong>
                  <textarea
                    className='form-control auto-height'
                    value={preview.chronicWelcomePreview}
                    readOnly
                    rows={4}
                  />
                </div>
              </div>
              <div className='row mt-5'>
                <div className='form-group col-sm-6'>
                  <strong>HALFWAY</strong>
                  <textarea
                    className='form-control'
                    value={template.chronicHalfWay}
                    onChange={handleChange('chronicHalfWay')}
                    required
                    rows={4}
                  />
                </div>
                <div className='form-group col-sm-6'>
                  <strong>HALFWAY (Preview)</strong>
                  <textarea
                    className='form-control auto-height'
                    value={preview.chronicHalfWayPreview}
                    readOnly
                    rows={4}
                  />
                </div>
              </div>
              <div className='row mt-5'>
                <div className='form-group col-sm-6'>
                  <strong>REMINDER</strong>
                  <textarea
                    className='form-control'
                    value={template.chronicReminder}
                    onChange={handleChange('chronicReminder')}
                    required
                    rows={4}
                  />
                </div>
                <div className='form-group col-sm-6'>
                  <strong>REMINDER (Preview)</strong>
                  <textarea
                    className='form-control auto-height'
                    value={preview.chronicReminderPreview}
                    readOnly
                    rows={4}
                  />
                </div>
              </div>
              <div className='row mt-5'>
                <div className='col-sm-4 d-grid'>
                  <button
                    className='btn btn-primary btn-block'
                    type='submit'
                    onClick={saveTemplate}
                  >
                    Save
                    {isLoading ? <ButtonLoader /> : ''}
                  </button>
                </div>
              </div>
            </fieldset>
            <br />
          </form>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Templates;
