import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import AccordionHeader from 'react-bootstrap/AccordionHeader';
import AccordionItem from 'react-bootstrap/AccordionItem';
import AccordionBody from 'react-bootstrap/AccordionBody';

const FAQComponent = () => {
  return (
    <div className='bg-white'>
      <Container fluid className='py-5'>
        <h2 className='text-center fs-4 fw-semibold'>Frequently Asked Questions</h2>
        <div className='mt-5'>
          <div className='d-flex justify-content-center align-items-center'>
            <Col xs={12} md={6}>
              <Accordion defaultActiveKey='0'>
                <AccordionItem eventKey='0'>
                  <AccordionHeader>Who owns wellapartner?</AccordionHeader>
                  <AccordionBody>
                    Wellapartner is wellahealth product targeted at retail pharmacies looking to
                    grow their revenue
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem eventKey='1'>
                  <AccordionHeader>How can I subscribe to the plan</AccordionHeader>
                  <AccordionBody>
                    You can send a mail to
                    <a className='ms-1 me-1' href='mailto:medicalops@wellahealth.com'>
                      medicalops@wellahealth.com
                    </a>
                    or click to fill this
                    <a
                      className='ms-1'
                      href='https://forms.gle/YCPcR9CLVwFB3Rxi8'
                      target='_blank'
                      rel='noreferrer'
                    >
                      form
                    </a>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem eventKey='2'>
                  <AccordionHeader>What is the cost of the plan</AccordionHeader>
                  <AccordionBody>
                    Kindly visit <a href='/pricing'>here </a>
                    to learn more about the pricing
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem eventKey='3'>
                  <AccordionHeader>How long do I have to pay for?</AccordionHeader>
                  <AccordionBody>
                    Paying for a year's subscription is best to ensure you get full value from the
                    product features but you can make payments for 6 months as well.
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </Col>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FAQComponent;
