import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { IHealthDetail } from '../../../interface/HealthDetailInterface';
import {
  IPatientData,
  IPatientSearchResult,
  IPatientTabProps,
} from '../../../interface/PatientInterface';
import { formatDate } from '../../../utils/dateTimeUtils';
import { PatientTabs } from '../../../utils/mappings';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import NoDataView from '../../common/NoData';
import PatientModal from '../entry/PatientModal';
import HealthDetailModal from './HealthDetailModal';

const GeneralInfoScreen = ({ patientId, tabKey }: IPatientTabProps) => {
  const [patientData, setPatientData] = useState<IPatientData | undefined>();
  const [healthInfo, setHealthInfo] = useState<IHealthDetail | undefined>();
  const [showPatientModal, setShowPatientModal] = useState(false);
  const [showHealthInfoModal, setShowHealthInfoModal] = useState(false);

  const [selectedPatient, setSelectedPatient] = useState<IPatientSearchResult | undefined>();

  const getPatientInfo = useCallback(async () => {
    if (patientId === 0) {
      return;
    }

    const url = `${endpoints.patients.mainUrl}/${patientId}`;
    try {
      const response = await axios.get(url);
      setPatientData(response.data);

      //set page info
    } catch (error) {
      addToast('An error occured getting patient information', 'error');
    }
  }, [patientId]);

  const getHealthInfo = useCallback(async () => {
    if (patientId === 0) {
      return;
    }

    const url = `${endpoints.healthDetails.mainUrl}/patient/${patientId}`;
    try {
      const response = await axios.get(url);
      setHealthInfo(response.data);

      //set page info
    } catch (error) {
      addToast('An error occured getting health details', 'error');
    }
  }, [patientId]);

  const editPatientInfo = () => {
    const searchResult: IPatientSearchResult = {
      patientId: patientData!.patientId,
      patientName: patientData!.patientName,
      phoneNumber: patientData!.phone,
    };
    setSelectedPatient(searchResult);
    setShowPatientModal(!showPatientModal);
  };

  const toggleHealthInfo = () => {
    setShowHealthInfoModal(!showHealthInfoModal);
  };

  const closePatientModal = () => {
    setShowPatientModal(false);
  };

  useEffect(() => {
    if (tabKey !== PatientTabs.general) {
      return;
    }
    getPatientInfo();
    getHealthInfo();
  }, [patientId, tabKey, getPatientInfo, getHealthInfo]);

  const patientModal = (
    <PatientModal
      close={closePatientModal}
      show={showPatientModal}
      selectedPatient={selectedPatient}
      callback={getPatientInfo}
    />
  );

  const healthInfoModal = (
    <HealthDetailModal
      close={toggleHealthInfo}
      show={showHealthInfoModal}
      patientId={patientId}
      callback={getHealthInfo}
    />
  );

  return (
    <section className='mt-5'>
      {patientModal}
      {healthInfoModal}
      <section className='row justify-content-center mt-5'>
        <h5 className='col-sm-10'>Basic Info</h5>
        <div className='col-sm-10'>
          {patientData ? (
            <div className='card prescription-card'>
              <div className='card-body'>
                <div className='row'>
                  <h6 className='card-title col-10 col-sm-10'>
                    <span className='font-weight-normal'>Patient Name</span>:
                    <span className='ms-1 font-weight-bold'>{patientData.patientName}</span>
                  </h6>
                  <div className='col-2 col-sm-2' title='Edit' onClick={editPatientInfo}>
                    <span className='bi bi-pencil me-3'></span>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-sm-6 card-text prescription-card-text'>
                    <span>Phone Number</span>:
                    <span className='ms-1 font-weight-bold'>{patientData.phone}</span>
                  </div>
                  <div className='col-sm-6 card-text prescription-card-text'>
                    <span>Date of Birth</span>:
                    <span className='ms-1 font-weight-bold'>
                      {formatDate(patientData.dateOfBirth)}
                    </span>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-sm-6 card-text prescription-card-text'>
                    <span>Address</span>:
                    <span className='ms-1 font-weight-bold'>{patientData.patientAddress}</span>
                  </div>
                  <div className='col-sm-6 card-text prescription-card-text'>
                    <span>Registered</span>:
                    <span className='ms-1 font-weight-bold'>
                      {formatDate(patientData.dateCreated)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </section>
      <section className='row justify-content-center mt-5'>
        <div className='col-sm-10'>
          <div className='row'>
            <h5 className='col-10 col-sm-10'>Health Info</h5>
            <div className='col-2 col-sm-2 text-end' title='Add'>
              <button className='btn btn-primary btn-sm' onClick={toggleHealthInfo}>
                Add
                {/* <span className='bi bi-plus m-auto'></span> */}
              </button>
            </div>
          </div>
        </div>

        <div className='col-sm-10'>
          {healthInfo ? (
            <div className='card prescription-card'>
              <div className='card-body'>
                <div className='row'>
                  <h6 className='card-title col-10 col-sm-10'>
                    <span>BMI</span>:
                    <span className='ms-1 font-weight-bold'>
                      {healthInfo.height > 0
                        ? (
                            healthInfo.weight /
                            ((healthInfo.height / 100) * (healthInfo.height / 100))
                          ).toFixed(2)
                        : 0}
                    </span>
                  </h6>
                </div>
                <div className='row mt-4'>
                  <div className='col-sm-6 card-text prescription-card-text'>
                    <span>Height</span>:
                    <span className='ms-1 font-weight-bold'>{healthInfo.height} cm</span>
                  </div>
                  <div className='col-sm-6 card-text prescription-card-text'>
                    <span>Weight</span>:
                    <span className='ms-1 font-weight-bold'>{healthInfo.weight} kg</span>
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col-sm-6 card-text prescription-card-text'>
                    <span>Blood Group</span>:
                    <span className='ms-1 font-weight-bold'>{healthInfo.bloodGroup}</span>
                  </div>
                  <div className='col-sm-6 card-text prescription-card-text'>
                    <span>Allergies</span>:
                    <span className='ms-1 font-weight-bold'>{healthInfo.allergies}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <NoDataView />
          )}
        </div>
      </section>
    </section>
  );
};

export default GeneralInfoScreen;
