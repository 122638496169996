import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import AccountSettings from './AccountSettings';
import NotificationSettings from './NotificationSettings';

const Settings = () => {
  return (
    <div className='col-md-10 content-section' style={{ minHeight: '700px' }}>
      <div>
        <Tabs fill defaultActiveKey='account' id='uncontrolled-tab-example' className='patient-tab'>
          <Tab eventKey='account' title='Account Settings'>
            <AccountSettings />
          </Tab>
          <Tab eventKey='notifications' title='Notification Settings'>
            <NotificationSettings />
          </Tab>
          {/* <Tab eventKey='printlabel' title='Print Label Settings'>
            <PrintLabelSettings />
          </Tab> */}
        </Tabs>
      </div>
    </div>
  );
};

export default Settings;
