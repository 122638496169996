import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../../common/ButtonLoader';
import {
  CountryCodes,
  PharmacyPackage,
  PharmacyStatus,
  SmsProvider,
} from '../../../../utils/mappings';
import {
  IPharmacy,
  IPharmacyClient,
  IPharmacyModal,
} from '../../../../interface/PharmacyInterface';
import { STATES_LGA } from '../../../../utils/states_lga';
import { isFormValidated } from '../../../../utils/formUtils';
import { addToast } from '../../../../utils/toastNotifications';
import { endpoints } from '../../../../utils/URL';

const PharmacyModal = ({ show, close, pharmacy, callback }: IPharmacyModal) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pharmacyData, setPharmacyData] = useState<IPharmacy>(pharmacy);
  const [clients, setClients] = useState<IPharmacyClient[]>([]);

  const handlePharmacyChange = (input: string) => (event: any) => {
    setPharmacyData({
      ...pharmacyData,
      [input]: event?.target.value,
    });
  };

  const statesMap = STATES_LGA.map((states) => {
    return {
      key: states.id,
      text: states.state,
      value: states.state,
    };
  });

  const stateFilter = STATES_LGA.filter((selectedState) => {
    let stateOfPremise = 'Abia';
    if (pharmacyData.stateOfPremise !== null && pharmacyData.stateOfPremise !== '') {
      stateOfPremise = pharmacyData.stateOfPremise;
    }
    return selectedState.state.toLowerCase() === stateOfPremise.toLowerCase(); //modalData.stateOfPremise?.toLowerCase();
  });

  let lgas = stateFilter[0]?.locals.map((lga) => {
    return {
      key: lga.id,
      text: lga.name,
      value: lga.name,
    };
  });

  const savePharmacy = async (event: any) => {
    if (!isFormValidated('pharmacy-modal')) {
      return;
    }
    event.preventDefault();
    setIsLoading(true);
    const url = endpoints.pharmacies.mainUrl;
    try {
      if (pharmacyData.pharmacyId === 0) {
        await axios.post(url, pharmacyData);
      } else {
        await axios.put(url, pharmacyData);
      }
      addToast('Pharmacy data saved successfully', 'success');
      if (callback) {
        callback();
      }
      close();
    } catch (error) {
      addToast('An error occured while saving pharmacy data', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const loadClients = useCallback(async () => {
    const url = endpoints.pharmacies.client;

    try {
      const response = await axios.get(url);

      setClients(response.data);
    } catch (error) {
      addToast('Error while getting the list of clients', 'error');
    }
  }, []);

  useEffect(() => {
    setPharmacyData(pharmacy);
    loadClients();
  }, [pharmacy, loadClients]);

  return (
    <Modal show={show} onHide={close} size='lg' backdrop={isLoading ? 'static' : true}>
      <form id='pharmacy-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Add / Edit Pharmacy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={isLoading}>
            <div className='row'>
              <div className='form-group col-sm-4 mt-2'>
                <label>Client</label>
                <select
                  className='form-select'
                  value={pharmacyData.clientId}
                  onChange={handlePharmacyChange('clientId')}
                >
                  <option value={0}>-- Create a new Client --</option>
                  {clients.map((client) => (
                    <option value={client.clientId}>{client.clientName}</option>
                  ))}
                </select>
              </div>
              <div className='form-group col-sm-4 mt-2'>
                <label>Pharmacy Code</label>
                <input
                  type='text'
                  className='form-control'
                  value={pharmacyData.pharmacyCode}
                  required
                  disabled
                />
              </div>
              <div className='form-group col-sm-4 mt-2'>
                <label>Pharmacy name</label>
                <input
                  type='text'
                  className='form-control'
                  value={pharmacyData.pharmacyName}
                  onChange={handlePharmacyChange('pharmacyName')}
                  required
                />
              </div>
              <div className='form-group col-sm-4 mt-4'>
                <label>Country Code</label>
                <select
                  className='form-select'
                  value={pharmacyData.countryCode}
                  onChange={handlePharmacyChange('countryCode')}
                >
                  {CountryCodes.map((data, key) => (
                    <option key={key} value={data.value}>
                      {data.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group col-sm-4 mt-4'>
                <label>Phone</label>
                <input
                  type='text'
                  pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                  className='form-control'
                  value={pharmacyData.phone}
                  onChange={handlePharmacyChange('phone')}
                  required
                />
              </div>

              <div className='form-group col-sm-4 mt-4'>
                <label>Email</label>
                <input
                  type='text'
                  className='form-control'
                  value={pharmacyData.email}
                  onChange={handlePharmacyChange('email')}
                  required
                />
              </div>
              <div className='form-group col-sm-4 mt-4'>
                <label>SMS Sender</label>
                <input
                  type='text'
                  className='form-control'
                  value={pharmacyData.sender}
                  onChange={handlePharmacyChange('sender')}
                  required
                />
              </div>
              <div className='form-group col-sm-4 mt-4'>
                <label>SMS Provider</label>
                <select
                  className='form-select'
                  value={pharmacyData.smsprovider}
                  onChange={handlePharmacyChange('smsprovider')}
                  required
                >
                  {SmsProvider.map((data) => (
                    <option value={data.value}>{data.text}</option>
                  ))}
                </select>
              </div>

              <div className='form-group col-sm-4 mt-4'>
                <label>Default acute days</label>
                <input
                  type='number'
                  min={1}
                  max={30}
                  className='form-control'
                  value={pharmacyData.defaultAcuteDays}
                  onChange={handlePharmacyChange('defaultAcuteDays')}
                  required
                />
              </div>
              <div className='form-group col-sm-4 mt-4'>
                <label>SMS Balance</label>
                <input
                  type='number'
                  min={0}
                  className='form-control'
                  value={pharmacyData.smsBalance}
                  onChange={handlePharmacyChange('smsBalance')}
                  required
                />
              </div>
              <div className='form-group col-sm-4 mt-4'>
                <label>Expiry Date</label>
                <input
                  type='date'
                  className='form-control'
                  value={pharmacyData.expiryDate.split('T')[0]}
                  min={pharmacyData.expiryDate.split('T')[0]}
                  onChange={handlePharmacyChange('expiryDate')}
                  required
                />
              </div>
              {/* <div className='form-group col-sm-4 mt-2'>
                <label>Use gateway</label>
                <select
                  className='custom-select'
                  value={pharmacyData.useGateway.toString()}
                  onChange={handlePharmacyChange('useGateway')}
                >
                  {BOOL_MAPPING.map((data) => (
                    <option value={data.value.toString()}>{data.text}</option>
                  ))}
                </select>
              </div> */}
              <div className='form-group col-sm-4 mt-4'>
                <label>Contacts</label>
                <input
                  type='text'
                  className='form-control'
                  value={pharmacyData.contacts}
                  onChange={handlePharmacyChange('contacts')}
                  required
                />
              </div>
              <div className='form-group col-sm-4 mt-4'>
                <label>Package</label>
                <select
                  className='form-select'
                  value={pharmacyData.package}
                  onChange={handlePharmacyChange('package')}
                >
                  {PharmacyPackage.map((data) => (
                    <option value={data.value}>{data.text}</option>
                  ))}
                </select>
              </div>

              <div className='form-group col-sm-4 mt-4'>
                <label>Status</label>
                <select
                  className='form-select'
                  value={pharmacyData.status}
                  onChange={handlePharmacyChange('status')}
                >
                  {PharmacyStatus.map((data) => (
                    <option value={data.value}>{data.text}</option>
                  ))}
                </select>
              </div>

              <div className='form-group col-sm-4 mt-4'>
                <label>State Of Premise</label>
                <select
                  className='form-select'
                  value={pharmacyData.stateOfPremise}
                  onChange={handlePharmacyChange('stateOfPremise')}
                >
                  {statesMap.map((data) => (
                    <option value={data.value}>{data.text}</option>
                  ))}
                </select>
              </div>
              <div className='form-group col-sm-4 mt-4'>
                <label>LGA</label>
                <select
                  className='form-select'
                  value={pharmacyData.lga}
                  onChange={handlePharmacyChange('lga')}
                >
                  {lgas ? (
                    lgas.map((data) => (
                      <option key={data.key} value={data.value}>
                        {data.text}
                      </option>
                    ))
                  ) : (
                    <option>No LGAs</option>
                  )}
                </select>
              </div>

              <div className='form-group col-sm-4 mt-4'>
                <label>Pharmacy Address</label>
                <input
                  type='text'
                  className='form-control'
                  value={pharmacyData.pharmacyAddress}
                  onChange={handlePharmacyChange('pharmacyAddress')}
                  required
                />
              </div>
              <div className='form-group col-sm-4 mt-4'>
                <label>Location area</label>
                <input
                  type='text'
                  className='form-control'
                  value={pharmacyData.locationArea}
                  onChange={handlePharmacyChange('locationArea')}
                  required
                />
              </div>

              <div className='form-group col-sm-4 mt-4'>
                <label>Location Longitude</label>
                <input
                  type='text'
                  className='form-control'
                  value={pharmacyData.locationLongitude}
                  onChange={handlePharmacyChange('locationLongitude')}
                  readOnly
                />
              </div>
              <div className='form-group col-sm-4 mt-4'>
                <label>Location Latitude</label>
                <input
                  type='text'
                  className='form-control'
                  value={pharmacyData.locationLongitude}
                  onChange={handlePharmacyChange('locationLongitude')}
                  readOnly
                />
              </div>
              <div className='form-group col-sm-4 mt-4'>
                <label>Relationship officer</label>
                <input
                  type='text'
                  className='form-control'
                  value={pharmacyData.relationshipOfficer}
                  onChange={handlePharmacyChange('relationshipOfficer')}
                  required
                />
              </div>
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <div className='col-sm-4 d-grid'>
            <button className='btn btn-primary btn-block' type='submit' onClick={savePharmacy}>
              Save
              {isLoading ? <ButtonLoader /> : ''}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default PharmacyModal;
