import { Container, Col, Row } from 'react-bootstrap';
import { partners } from '../constants';

const PartnerSection = () => {
  return (
    <div className='bg-white'>
      <Container className='py-5'>
        <h3 className='text-center display-6 my-4'>We are trusted by over 2000 Pharmacies</h3>
        <p className='text-center fs-4 mb-5'>
          This is a testament to the immense value we bring to businesses in the pharma distribution
          industry
        </p>

        <div className='text-center'>
          <Row xs={3} md={5} className=''>
            {partners.map((partner, index) => (
              <Col className='my-3'>
                <img
                  key={index}
                  src={partner.image}
                  alt={partner.name}
                  className='img-fluid'
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </Col>
            ))}
          </Row>
        </div>

        <h2 className='text-center m-5 fs-4 fw-semibold pt-5'>About WellaPartner</h2>
        <div className='d-flex justify-content-center'>
          <iframe
            title='WellaPartner Video'
            className='img-fluid'
            src={`https://www.youtube.com/embed/zV2_YPYNsXI?si=iniZPj5KGx3teIsW`}
            allowFullScreen
            style={{ width: '500px', height: '250px', borderRadius: '10px' }}
          ></iframe>
        </div>
      </Container>
    </div>
  );
};

export default PartnerSection;
