import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import logo from '../../../../assets/images/logo.svg';
// import "../styles/NavBar.css";

const NavBar = () => {
  return (
    <Navbar expand='lg' sticky='top' className='landing-navbar'>
      <Container>
        <Navbar.Brand>
          <NavLink to='/'>
            <img src={logo} alt='logo' />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav' className=''>
          <Nav className='ms-auto align-items-center'>
            <Nav.Link as={NavLink} to='/'>
              Products
            </Nav.Link>
            <Nav.Link as={NavLink} to='/testimonial'>
              Testimonials
            </Nav.Link>
            <Nav.Link as={NavLink} to='/pricing'>
              Pricing
            </Nav.Link>
            <a href='https://forms.gle/YCPcR9CLVwFB3Rxi8' className='btn wellapartner mx-3'>
              Get Started
            </a>
            <a href='/login' className='btn-primary btn wellapartner mx-3 mt-3 mt-md-0'>
              Login
            </a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
