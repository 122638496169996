import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import { defaultPageInfo } from '../../../interface/CommonInterface';
import { IRefill } from '../../../interface/RefillsInterface';
import { formatDate } from '../../../utils/dateTimeUtils';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import Loader from '../../common/Loader';
import NoDataView from '../../common/NoData';
import Pagination from '../../common/Pagination';

const RefillsTable = () => {
  const [refills, setRefills] = useState<IRefill[]>([]);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [isLoading, setLoading] = useState(false);

  const { appState } = useContext(AppContext);
  const pharmacyId: number = appState.pharmacyId;

  const getRefills = useCallback(
    async (pageIndex: number) => {
      const url = `${endpoints.patientDrugs.refills}/${pharmacyId}?pageIndex=${pageIndex}&pageSize=10`;
      setLoading(true);
      try {
        const response = await axios.get(url);
        setRefills(response.data.data);

        const responsePageInfo = {
          pageIndex: response.data.pageIndex,
          pageSize: response.data.pageSize,
          pageCount: response.data.pageCount,
        };
        //set page info
        setPageInfo(responsePageInfo);
      } catch (error) {
        addToast('An error occured', 'error');
      } finally {
        setLoading(false);
      }
    },
    [pharmacyId]
  );

  useEffect(() => {
    getRefills(1);
  }, [getRefills]);

  const dataView = (
    <div>
      <div className='table-responsive mt-4'>
        <table className='table table-hover table-bordered'>
          <thead>
            <tr>
              <th>Due Date</th>
              <th>Days Left</th>
              <th>Drugs</th>
              <th>Dose</th>
              <th>Patient Name</th>
              <th>Patient Phone</th>
            </tr>
          </thead>
          <tbody>
            {refills.map((refill, key) => (
              <tr key={key}>
                <td>{formatDate(refill.endDate)}</td>
                <td>{refill.daysLeft}</td>
                <td>{refill.drugName}</td>
                <td>{refill.dose}</td>
                <td>{refill.patientName}</td>
                <td>{refill.patientPhone}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPageNumber={pageInfo.pageIndex}
        numberOfPages={pageInfo.pageCount}
        callback={getRefills}
      />
    </div>
  );

  return (
    <section className='col-md-10 content-section'>
      <h4>Refills</h4>
      <div className='mt-4'></div>
      {isLoading && <Loader />}
      {!isLoading && refills.length > 0 && dataView}
      {!isLoading && refills.length === 0 && <NoDataView />}
    </section>
  );
};

export default RefillsTable;
