import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { addToast } from '../../../../utils/toastNotifications';
import { endpoints } from '../../../../utils/URL';
import Loader from '../../../common/Loader';
import NoDataView from '../../../common/NoData';
import { IEngageSubscription } from '../../../../interface/EngageSubscriptionInterface';
import { useParams } from 'react-router-dom';
import AddEditSubscriptionModal from './AddEditSubscriptionModal';
import { AppContext } from '../../../../context/AppContext';
import { Roles } from '../../../../utils/mappings';
import { formatDateTime } from '../../../../utils/dateTimeUtils';

const SubscriptionList = () => {
  const [engageSubscriptions, setEngageSubscriptions] = useState<IEngageSubscription[]>([]);

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { pharmacyId } = useParams() as any;
  const { appState } = useContext(AppContext);

  const getEngageSubscriptions = useCallback(async () => {
    const url = `${endpoints.engageSubscription.mainUrl}/${pharmacyId}`;
    setIsLoading(true);
    try {
      const response = await axios.get(url);
      setEngageSubscriptions(response.data);

      //set page info
    } catch (error) {
      addToast('An error occured while loading susbcription list', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [pharmacyId]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const isSysAdmin = useCallback(() => {
    const userRole: string = appState.entitlements.role;
    return userRole === Roles.SysAdmin;
  }, [appState]);

  let addEditSubscription = (
    <AddEditSubscriptionModal
      show={showModal}
      close={toggleModal}
      dataId={parseInt(pharmacyId)}
      callback={getEngageSubscriptions}
    />
  );

  useEffect(() => {
    getEngageSubscriptions();
  }, [getEngageSubscriptions]);

  const dataView = (
    <div className='card pt-5 pb-5 pe-4 ps-4'>
      <div className='table-responsive'>
        <table className='table table-hover table-bordered'>
          <thead>
            <tr>
              <th>Plan Code</th>
              <th>Plan Name</th>
              <th>Amount</th>
              <th>Start date</th>
              <th>End date</th>
              <th>Payment channel</th>
            </tr>
          </thead>
          <tbody>
            {engageSubscriptions.map((subscription) => (
              <tr key={subscription.subscriptionId}>
                <td>{subscription.planCode}</td>
                <td>{subscription.planName}</td>
                <td>{subscription.amount}</td>
                <td>{formatDateTime(subscription.startDate)}</td>
                <td>{formatDateTime(subscription.endDate)}</td>
                <td>{subscription.paymentChannel}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <section className=''>
      {addEditSubscription}
      <div className='d-flex'>
        <h4 className='me-auto'>Subscriptions</h4>
        {isSysAdmin() ? (
          <button type='button' className='btn btn-primary' onClick={() => toggleModal()}>
            Add new subscription
          </button>
        ) : (
          ''
        )}
      </div>

      <br />
      {isLoading && <Loader />}
      {!isLoading && engageSubscriptions.length > 0 && dataView}
      {!isLoading && engageSubscriptions.length === 0 && <NoDataView />}
    </section>
  );
};

export default SubscriptionList;
