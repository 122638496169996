import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { defaultPageInfo } from '../../../interface/CommonInterface';
import { ICholesterolReading } from '../../../interface/CholesterolInterface';
import { formatDateTime } from '../../../utils/dateTimeUtils';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import Pagination from '../../common/Pagination';
import CholesterolModal from './CholesterolModal';
import { IPatientTabProps } from '../../../interface/PatientInterface';
import { PatientTabs } from '../../../utils/mappings';
import Loader from '../../common/Loader';
import NoDataView from '../../common/NoData';

const CholesterolScreen = ({ patientId, tabKey }: IPatientTabProps) => {
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [cholesterols, setCholesterols] = useState<ICholesterolReading[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getCholesterolReadings = useCallback(
    async (pageIndex: number) => {
      if (patientId === 0) {
        return;
      }
      setIsLoading(true);
      const url = `${endpoints.cholesterol.getByPatient}/${patientId}?pageIndex=${pageIndex}&pageSize=10`;
      try {
        const response = await axios.get(url);
        setCholesterols(response.data.data);

        const responsePageInfo = {
          pageIndex: response.data.pageIndex,
          pageSize: response.data.pageSize,
          pageCount: response.data.pageCount,
        };
        //set page info
        setPageInfo(responsePageInfo);
      } catch (error) {
        addToast('An error occured', 'error');
      } finally {
        setIsLoading(false);
      }
    },
    [patientId]
  );

  const deleteReading = (readingId: number) => {
    const response = window.confirm('Are you sure you want to delete this reading?');
    if (!response) {
      return;
    }
    addToast('Reading successfully deleted', 'success');
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (tabKey !== PatientTabs.cholesterol) {
      return;
    }
    getCholesterolReadings(1);
  }, [patientId, tabKey, getCholesterolReadings]);

  let cholesterolModal = (
    <CholesterolModal
      show={showModal}
      close={toggleModal}
      patientId={patientId}
      callback={() => getCholesterolReadings(1)}
    />
  );

  const dataView = (
    <div>
      <div className='table-responsive'>
        <table className='table table-hover table-bordered'>
          <thead>
            <tr>
              <th>Low Density Lipid</th>
              <th>High Density Lipid</th>
              <th>Triglycerides</th>
              <th>Total Cholesterol</th>
              <th>Reading date</th>
              <th>Actions </th>
            </tr>
          </thead>
          <tbody>
            {cholesterols.map((cholesterol) => (
              <tr key={cholesterol.readingId}>
                <td>{cholesterol.lowDensityLipids}</td>
                <td>{cholesterol.highDensityLipids}</td>
                <td>{cholesterol.triglycerides}</td>
                <td>{cholesterol.totalCholesterol}</td>
                <td>{formatDateTime(cholesterol.readingTime)}</td>
                <td>
                  <span
                    className='bi bi-trash custom-group-icon text-danger'
                    title='Delete reading'
                    onClick={() => deleteReading(cholesterol.readingId)}
                  ></span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPageNumber={pageInfo.pageIndex}
        numberOfPages={pageInfo.pageCount}
        callback={getCholesterolReadings}
      />
    </div>
  );

  return (
    <section className='mt-5'>
      {cholesterolModal}
      <h5 className='mb-4'>Cholesterol Readings</h5>
      <br />
      <button className='btn btn-primary' onClick={toggleModal}>
        Add Reading
      </button>
      <div className='mt-3'></div>
      {isLoading && <Loader />}
      {!isLoading && cholesterols.length > 0 && dataView}
      {!isLoading && cholesterols.length === 0 && <NoDataView />}
    </section>
  );
};

export default CholesterolScreen;
