import { useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { PageTourProps } from '../../interface/CommonInterface';

const PageTour = ({ steps, run, page }: { steps: PageTourProps[]; run: boolean; page: string }) => {
  const [stepIndex, setStepIndex] = useState(0);

  const joyrideCallback = (data: any) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      //   setRun(false);
      localStorage.setItem(`${page}Tour`, 'completed');
    }
  };

  return (
    <Joyride
      continuous
      showProgress
      showSkipButton
      disableCloseOnEsc
      disableOverlayClose
      run={run}
      steps={steps}
      stepIndex={stepIndex}
      callback={joyrideCallback}
    />
  );
};

export default PageTour;
