// import React from 'react'
import HeroCTA from '../components/HeroCTA'
import InfographicComponent from '../components/InfographicComponent'
import PartnerSection from '../components/PartnerSection'
import SalesComponent from '../components/SalesComponent'
import ManagementComponent from '../components/ManagementComponent'
import DevelopmentComponent from '../components/DevelopmentComponent'
import FAQComponent from '../components/FAQComponent'
import FooterComponent from '../components/FooterComponent'
import NavBar from '../components/NavBar'

const Home = () => {
  return (
    <div>
      <NavBar />
      <HeroCTA />
			<InfographicComponent />
			<PartnerSection />
			<SalesComponent />
			<ManagementComponent />
			<DevelopmentComponent />
			<FAQComponent />
      <FooterComponent />
    </div>
  )
}

export { Home }