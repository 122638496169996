import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import {
  IMedicationDetail,
  IMedicationModal,
  defaultMedication,
} from '../../../interface/MedicationEntryInterface';
import { MEDICATION_MAPPING } from '../../../utils/mappings';
import { useState } from 'react';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { dosageForm, frequencyList } from '../../../utils/drugUtil';
import DrugSearch from '../../common/DrugSearch';

const MedicationModal = ({ show, close, addMedication, selectedMedication }: IMedicationModal) => {
  const [medication, setMedication] = useState<IMedicationDetail>(defaultMedication);

  const handleChange = (input: string) => (event: any) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setMedication({
      ...medication,
      [input]: value,
    });
  };

  const addToDataEntry = (event: any) => {
    if (!isFormValidated('medication-modal')) {
      return;
    }

    event.preventDefault();

    addMedication(medication);
    addToast('Medication added successfully', 'success');
    setMedication(defaultMedication);
  };

  const setDrugName = (drugName: string) => {
    setMedication({
      ...medication,
      drugName: drugName,
    });
  };

  useEffect(() => {
    if (selectedMedication) {
      setMedication(selectedMedication!);
    } else {
      setMedication(defaultMedication);
    }
  }, [selectedMedication]);

  return (
    <Modal show={show} onHide={() => close()} backdrop='static'>
      <form id='medication-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Add Medication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset className='row'>
            <div className='form-group col-12 mt-2'>
              <label className='required'>Drug name</label>
              <DrugSearch callback={setDrugName} inputStyle='' />
            </div>
            <div className='form-group col-6 mt-4'>
              <label>Dosage form</label>
              <input
                type='text'
                className='form-control'
                value={medication.drugDosage}
                placeholder='e.g Tabs, IV'
                onChange={handleChange('drugDosage')}
                list='dosage-list'
              />
              <datalist id='dosage-list'>
                {dosageForm.map((dosage, key) => (
                  <option key={key}>{dosage}</option>
                ))}
              </datalist>
            </div>
            <div className='form-group col-6 mt-4'>
              <label className='required'>Drug strength</label>
              <input
                type='text'
                className='form-control'
                placeholder='e.g 500mg'
                value={medication.drugStrength}
                onChange={handleChange('drugStrength')}
                required
              />
            </div>
            <div className='form-group col-6 mt-4'>
              <label className='required'>Drug frequency</label>
              <input
                type='text'
                className='form-control'
                value={medication.drugFrequency}
                onChange={handleChange('drugFrequency')}
                list='frequencylist'
                placeholder='e.g bd, nocte, tds'
                required
              />
              <datalist id='frequencylist'>
                {frequencyList?.map((drugFrequency, key) => (
                  <option key={key}>{drugFrequency}</option>
                ))}
              </datalist>
            </div>
            <div className='form-group col-6 mt-4'>
              <label>Medication type</label>
              <select
                className='form-select'
                value={medication.isChronic.toString()}
                onChange={handleChange('isChronic')}
              >
                {MEDICATION_MAPPING.map((setting) => (
                  <option key={setting.key} value={setting.value.toString()}>
                    {setting.text}
                  </option>
                ))}
              </select>
            </div>
            <div className='form-group col-6 mt-4'>
              <label className='required'>Duration (days)</label>
              <input
                type='number'
                className='form-control'
                value={medication.prescribedDays}
                onChange={handleChange('prescribedDays')}
                min={3}
                max={120}
                required
              />
            </div>

            <div className='form-group col-12 mt-4'>
              <label>Other instructions</label>
              <input
                type='text'
                className='form-control'
                placeholder='e.g after meals'
                value={medication.drugInstructions}
                onChange={handleChange('drugInstructions')}
              />
            </div>
            <div className=' col-12  mt-4'>
              <div className='form-check'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  checked={medication.isConfidential}
                  onChange={handleChange('isConfidential')}
                  id='med_check'
                />
                <label className='form-check-label' htmlFor='med_check'>
                  Is this medication confidential?
                </label>
              </div>
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' type='submit' onClick={addToDataEntry}>
            {selectedMedication ? 'Update Medication' : 'Add medication'}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default MedicationModal;
