export interface IEngageSubscription {
  subscriptionId: number;
  amount: number;
  startDate: string;
  endDate: string;
  paymentChannel: string;
  paymentReference: string;
  dateCreated: string;
  planId: number;
  planCode: string;
  planName: string;
}

export interface INewEngageSubscription {
  amount: number;
  startDate: string;
  months: number;
  paymentChannel: string;
  paymentReference: string;
  planId: number;
  planCode: string;
  planName: string;
  pharmacyId: number;
  smsUnits: number;
}

export interface IEngagePlans {
  planId: number;
  planCode: string;
  planName: string;
  amount: number;
  planFeatures: string;
}

export const initialSubscription: INewEngageSubscription = {
  amount: 0,
  startDate: new Date().toISOString().split('T')[0],
  paymentChannel: 'BankTransfer',
  paymentReference: '',
  planId: 1,
  planCode: '',
  planName: '',
  months: 1,
  pharmacyId: 0,
  smsUnits: 0,
};
