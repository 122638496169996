import axios from 'axios';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ButtonLoader from '../../../common/ButtonLoader';
import { endpoints } from '../../../../utils/URL';
import { addToast } from '../../../../utils/toastNotifications';
import { isFormValidated } from '../../../../utils/formUtils';
import { initialSubscription } from '../../../../interface/EngageSubscriptionInterface';
import { ICommonModal } from '../../../../interface/CommonInterface';
import { PaymentChannel, PaymentPlan } from '../../../../utils/mappings';

const AddEditSubscriptionModal = ({ show, close, dataId, callback }: ICommonModal) => {
  const [isLoading, setIsLoading] = useState(false);
  const [engageSubscription, setEngageSubscription] = useState(initialSubscription);
  const [selectedPaymentPlan, setPaymentPlan] = useState(PaymentPlan[0]);

  const handleChange = (input: string) => (event: any) => {
    const value = event.target.value;
    console.log(value);
    setEngageSubscription({
      ...engageSubscription,
      [input]: value,
    });
    if (input === 'planId') {
      const paymentPlan = PaymentPlan.filter((x) => x.value === parseInt(value))[0];

      const totalAmount = engageSubscription.months * paymentPlan.monthlyPrice;
      setPaymentPlan(paymentPlan!);
      setEngageSubscription({
        ...engageSubscription,
        amount: totalAmount,
        planId: value,
      });
    }

    if (input === 'months') {
      const totalAmount = value * selectedPaymentPlan.monthlyPrice;
      setEngageSubscription({
        ...engageSubscription,
        amount: totalAmount,
        months: value,
      });
    }
  };

  // const calculateTotalAmount = (months: number, monthlyPrice: number) => {

  // };

  const saveSubscription = async (event: any) => {
    if (!isFormValidated('subscription-modal')) {
      return;
    }

    event.preventDefault();
    engageSubscription.pharmacyId = dataId;
    console.log(engageSubscription);
    const url = `${endpoints.engageSubscription.mainUrl}`;

    setIsLoading(true);

    try {
      await axios.post(url, engageSubscription);
      addToast('Subscription saved successfully', 'success');
      if (callback) {
        callback();
      }
    } catch (error: any) {
      addToast('Error occured when saving', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={close}>
      <form id='subscription-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Add Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={isLoading}>
            <div className='form-group'>
              <label>Start Date</label>
              <input
                type='date'
                className='form-control'
                value={engageSubscription.startDate}
                onChange={handleChange('startDate')}
                required
              />
            </div>
            <div className='form-group mt-4'>
              <label>Months</label>
              <input
                type='number'
                min={1}
                className='form-control'
                value={engageSubscription.months}
                onChange={handleChange('months')}
                required
              />
            </div>
            <div className='form-group mt-4'>
              <label>Additional SMS units</label>
              <input
                type='number'
                min={0}
                className='form-control'
                value={engageSubscription.smsUnits}
                onChange={handleChange('smsUnits')}
                required
              />
            </div>
            <div className='form-group mt-4'>
              <label>Payment Channel</label>
              <select
                className='form-select'
                value={engageSubscription.paymentChannel}
                onChange={handleChange('paymentChannel')}
              >
                {PaymentChannel.map((channel) => (
                  <option key={channel.key} value={channel.value}>
                    {channel.text}
                  </option>
                ))}
              </select>
            </div>
            <div className='form-group mt-4'>
              <label>Payment Plan</label>
              <select
                className='form-select'
                value={engageSubscription.planId}
                onChange={handleChange('planId')}
              >
                {PaymentPlan.map((plan) => (
                  <option key={plan.key} value={plan.value}>
                    {plan.planName}
                  </option>
                ))}
              </select>
            </div>
            <div className='card' style={{ borderColor: 'inherit' }}>
              <div className='card-body row'>
                <div className='col-6 mt-2'>Plan Name</div>
                <strong className='col-6 mt-2'>{selectedPaymentPlan.planName}</strong>
                <div className='col-6 mt-2'>Plan Code</div>
                <strong className='col-6 mt-2'> {selectedPaymentPlan.planCode}</strong>
                <div className='col-6 mt-2'>Month price</div>
                <strong className='col-6 mt-2'>
                  ₦{selectedPaymentPlan.monthlyPrice.toLocaleString()}
                </strong>
                <div className='col-6 mt-2'>SMS units</div>
                <strong className='col-6 mt-2'>{selectedPaymentPlan.sms}</strong>

                <div className='col-6 mt-3'>Total mount</div>
                <strong className='col-6 mt-2'>
                  ₦{engageSubscription.amount.toLocaleString()}
                </strong>
              </div>
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <div className='col-4 d-grid'>
            <button className='btn btn-primary btn-block' type='submit' onClick={saveSubscription}>
              Save
              {isLoading ? <ButtonLoader /> : ''}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddEditSubscriptionModal;
