import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import GeneralInfoScreen from './GeneralInfoScreen';
import MedicationScreen from './MedicationScreen';
import MessagesScreen from './MessagesScreen';
import BloodSugarScreen from './BloodSugarScreen';
import BloodPressureScreen from './BloodPressureScreen';
import CholesterolScreen from './CholesterolScreen';
import PatientNotesScreen from './PatientNotesScreen';
import { useEffect, useState } from 'react';
import { IPatientSearchResult } from '../../../interface/PatientInterface';
import { PatientTabs } from '../../../utils/mappings';
import PatientSearch from '../../common/PatientSearch';
import { Link } from 'react-router-dom';

const Patients = () => {
  const [patientId, setPatientId] = useState(0);
  const [key, setKey] = useState(PatientTabs.general);

  const searchParam = new URLSearchParams(window.location.search);
  const searchQuery = searchParam.get('id');

  const patientSearchCallback = (selectedPatient: IPatientSearchResult) => {
    //search patient
    setPatientId(selectedPatient.patientId);
    //if a patient is selected, clear all medications
  };

  useEffect(() => {
    if (searchQuery !== null && typeof searchQuery !== 'undefined') {
      setPatientId(parseInt(searchQuery));
    }
  }, [searchQuery]);

  return (
    <div className='col-md-10 content-section' style={{ minHeight: '700px' }}>
      <div className='row'>
        <h4 className='col-7'>Patients</h4>
        <div className='col-5' title='Upload patient list'>
          <Link to='/patients/upload' className='btn btn-primary float-end'>
            Upload <span className='bi bi-upload'></span>
          </Link>
        </div>
      </div>

      <div className='row justify-content-left mt-4'>
        <div className='form-group col-sm-6'>
          <PatientSearch callback={patientSearchCallback} inputStyle='search-form-element' />
        </div>
      </div>
      {patientId > 0 ? (
        <div className='mt-5'>
          <Tabs
            fill
            id='controlled-tab-example'
            activeKey={key}
            onSelect={(k) => setKey(k as string)}
            className='patient-tab'
          >
            <Tab eventKey={PatientTabs.general} title='General Info'>
              <GeneralInfoScreen patientId={patientId} tabKey={key} />
            </Tab>
            <Tab eventKey={PatientTabs.medications} title='Medications'>
              <MedicationScreen patientId={patientId} tabKey={key} />
            </Tab>
            <Tab eventKey={PatientTabs.messages} title='Messages'>
              <MessagesScreen patientId={patientId} tabKey={key} />
            </Tab>
            <Tab eventKey={PatientTabs.notes} title='Notes'>
              <PatientNotesScreen patientId={patientId} tabKey={key} />
            </Tab>
            <Tab eventKey={PatientTabs.bloodPressure} title='Blood Pressure'>
              <BloodPressureScreen patientId={patientId} tabKey={key} />
            </Tab>
            <Tab eventKey={PatientTabs.bloodSugar} title='Blood Sugar'>
              <BloodSugarScreen patientId={patientId} tabKey={key} />
            </Tab>
            <Tab eventKey={PatientTabs.cholesterol} title='Cholesterol'>
              <CholesterolScreen patientId={patientId} tabKey={key} />
            </Tab>
            {/* <Tab eventKey={PatientTabs.heartRisk} title='Heart Risk' tabKey={key}>
              <HeartRiskScreen patientId={patientId} tabKey={key} />
            </Tab> */}
          </Tabs>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Patients;
