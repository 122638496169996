import { ReactNode } from 'react';

export interface IRoute {
  name: string;
  path: string;
}

export interface IPageInfo {
  pageIndex: number;
  pageSize: number;
  pageCount: number;
}

export interface IPaginationInfo {
  currentPageNumber: number;
  pageSize: number;
  numberOfPages: number;
  //callback?: () => void;
  callback?: (pageInfo: IPageInfo) => any;
}

export interface IPatientModal {
  show: boolean;
  close: () => void;
  patientId: number;
  callback?: () => void;
}

export interface ICommonModal {
  show: boolean;
  close: () => void;
  dataId: number;
  callback?: () => void;
}

export interface ISelectOptions {
  label: string;
  value: string;
}

export interface IStateLga {
  id: number;
  text: string;
  value: string;
}

//default initializations

export const defaultPageInfo: IPageInfo = {
  pageIndex: 1,
  pageSize: 10,
  pageCount: 1,
};

export interface PageTourProps {
  target: string;
  title: string;
  content: ReactNode;
  event: string;
  disableBeacon?: boolean;
}
