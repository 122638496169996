import { FormEvent, useState, useEffect, useCallback } from 'react';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import ButtonLoader from '../../common/ButtonLoader';
import { IVaccinationReport } from '../../../interface/VaccinationInterface';

// Main component for displaying and managing vaccination reports
const VaccinationReport = () => {
  // State to manage loading, data, and filtering
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [data, setData] = useState<IVaccinationReport[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [stateOfPremise, setStateOfPremise] = useState('');

  // List of states for dropdown
  const stateOptions = [
    { value: '', label: 'All States' },
    { value: 'Nasarawa', label: 'Nasarawa' },
    { value: 'Adamawa', label: 'Adamawa' },
    { value: 'Federal Capital Territory', label: 'Federal Capital Territory' },
  ];

  // Memoized function to fetch vaccination reports with optional filtering
  const fetchReport = useCallback(async () => {
    const url = `${endpoints.vaccination.mainUrl}/reports`;
    try {
      setLoading(true);
      // Fetch reports from API with pagination and filter parameters
      const response = await axios.get(url, {
        params: {
          pageNumber: currentPage,
          pageSize,
          download: false,
          from: fromDate,
          to: toDate,
          stateOfPremise: stateOfPremise,
        },
      });
      // Update state with fetched vaccination data
      setData(response.data.vaccinationData);
    } catch (error) {
      // Display error toast and log error if fetching fails
      addToast('An error occurred while fetching the report', 'error');
      console.error(error);
    } finally {
      // Ensure loading state is set to false after request
      setLoading(false);
    }
  }, [currentPage, pageSize, fromDate, toDate, stateOfPremise]);

  // Trigger report fetching when component mounts or filter parameters change
  useEffect(() => {
    fetchReport();
  }, [fetchReport]);

  // Handle report download
  const handleDownload = async (event: FormEvent) => {
    event.preventDefault();
    const url = `${endpoints.vaccination.mainUrl}/reports`;
    try {
      setDownloadLoading(true);
      // Fetch report data for download with full dataset
      const response = await axios.get(url, {
        params: {
          pageNumber: 1,
          pageSize: 1000,
          download: true,
          from: fromDate,
          to: toDate,
          stateOfPremise: stateOfPremise,
        },
        responseType: 'blob', // Important for handling the CSV file
      });

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'vitals-report.csv';
      document.body.appendChild(link);
      link.click();
      // Clean up download resources
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
      addToast('Report downloaded successfully', 'success');
    } catch (error) {
      // Display error toast if download fails
      addToast('Download failed. Please try again', 'error');
    } finally {
      setDownloadLoading(false);
    }
  };

  // Validate and trigger filtering of reports
  const handleFilter = () => {
    // Ensure from date is not after to date
    if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
      addToast('From date cannot be greater than To date', 'error');
      return;
    }
    // Fetch reports with applied filters
    fetchReport();
  };

  // Render vaccination report interface
  return (
    <div className='container-fluid mt-4'>
      <div className='card shadow-sm p-3'>
        {/* Header with title and export button */}
        <div className='d-flex justify-content-between align-items-center mb-4'>
          <h4 className='mb-0'>Vital Project Report</h4>
          <button
            className='btn btn-primary d-flex align-items-center gap-2'
            onClick={handleDownload}
            disabled={downloadLoading}
          >
            {downloadLoading ? (
              <>
                <ButtonLoader />
                <span>Exporting...</span>
              </>
            ) : (
              'Export Report'
            )}
          </button>
        </div>

        {/* Filter section with date and state inputs */}
        <div className='row g-3 mb-4'>
          <div className='col-md-3'>
            <label className='form-label'>From Date</label>
            <input
              type='date'
              className='form-control'
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div className='col-md-3'>
            <label className='form-label'>To Date</label>
            <input
              type='date'
              className='form-control'
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
          <div className='col-md-3'>
            <label className='form-label'>State of Premise</label>
            <div className='position-relative'>
              <select
                className='form-select'
                value={stateOfPremise}
                onChange={(e) => setStateOfPremise(e.target.value)}
              >
                {stateOptions.map((state) => (
                  <option key={state.value} value={state.value}>
                    {state.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='col-md-3 d-flex align-items-end'>
            <button className='btn btn-primary w-100' onClick={handleFilter}>
              Filter
            </button>
          </div>
        </div>

        {/* Conditional rendering based on loading state */}
        {loading ? (
          <div className='text-center p-4'>
            <ButtonLoader />
          </div>
        ) : (
          <>
            {/* Table to display vaccination reports */}
            <div className='table-responsive'>
              <table className='table table-striped table-bordered'>
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Phone Number</th>
                    <th>Pharmacy Name</th>
                    <th>Date Created</th>
                    <th>State of Premise</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Render data rows or display 'No records found' */}
                  {data.length > 0 ? (
                    data.map((item) => (
                      <tr key={item.id}>
                        <td className='p-2'>{item.fullName}</td>
                        <td className='p-2'>{item.phoneNumber}</td>
                        <td className='p-2'>{item.pharmacyName}</td>
                        <td className='p-2'>{new Date(item.dateCreated).toLocaleDateString()}</td>
                        <td className='p-2'>{item.stateOfPremise}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5} className='text-center py-4'>
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination controls */}
            <div className='d-flex justify-content-center mt-4'>
              <nav>
                <ul className='pagination'>
                  {/* Previous page button */}
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                      className='page-link'
                      onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    >
                      Previous
                    </button>
                  </li>
                  {/* Current page indicator */}
                  <li className='page-item active'>
                    <span className='page-link'>{currentPage}</span>
                  </li>
                  {/* Next page button */}
                  <li className={`page-item ${data.length < pageSize ? 'disabled' : ''}`}>
                    <button
                      className='page-link'
                      onClick={() => setCurrentPage((prev) => prev + 1)}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VaccinationReport;
