import { ReactNode } from 'react';
import { defaultPharmacyList, IPharmacyListDTO } from './PharmacyInterface';
import { WalletAccountDetails } from './WalletInterface';

export interface IAppState {
  pharmacyId: number;
  pharmacyCode: string;
  pharmacyName: string;
  userId: number;
  token: string;
  isAuthenticated: boolean;
  entitlements: IEntitlement;
  pharmacyList: Array<IPharmacyListDTO>;
  clientPharmacyList: Array<WalletAccountDetails>;
  selectedPharmacy: IPharmacyListDTO;
  menuItems: IMenuItem;
  clientId: number;
}

export interface IEntitlement {
  pharmacyId: number;
  userId: number;
  package: string;
  lastLogin: string;
  role: string;
  senderId: string;
  email: string;
}

export interface IAuthToken {
  pharmacyId: number;
  pharmacyCode: string;
  pharmacyName: string;
  phone: string;
  userId: number;
  startDate: string;
  clientId: number;
  token: string;
  isComplianceCompleted: boolean;
  entitlements: IEntitlement;
}

export interface IDispatch {
  type: string;
  payload: any;
}

export interface IContextProps {
  children: ReactNode;
}

export interface IPrivateRouteProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

export interface IProps {
  history: any;
}

export interface ILoginProps {
  username: string;
  password: string;
}

export interface IMenuItem {
  expiryDate: string;
  smsUnits: number;
  patientCount: number;
  messageCount: number;
  refillCount: number;
  notificationCount: number;
}

export const initialMenu: IMenuItem = {
  expiryDate: '',
  smsUnits: 0,
  patientCount: 0,
  messageCount: 0,
  refillCount: 0,
  notificationCount: 0,
};

export const initialEntitlement: IEntitlement = {
  pharmacyId: 0,
  userId: 0,
  package: 'Bronze',
  lastLogin: '',
  role: 'Staff',
  senderId: '',
  email: '',
};

export const initialAppState: IAppState = {
  token: '',
  isAuthenticated: false,
  pharmacyId: 0,
  pharmacyCode: '',
  pharmacyName: '',
  userId: 0,
  entitlements: initialEntitlement,
  pharmacyList: [],
  clientPharmacyList: [],
  selectedPharmacy: defaultPharmacyList,
  menuItems: initialMenu,
  clientId: 0,
};

export const initialLoginState: ILoginProps = {
  username: '',
  password: '',
};
