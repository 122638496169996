import axios from 'axios';
import { FormEvent, useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IUserModal } from '../../../../interface/UserInterface';
import ButtonLoader from '../../../common/ButtonLoader';
import { BOOL_MAPPING, LOGIN_ROLES, LOGIN_ROLES_ADMIN, Roles } from '../../../../utils/mappings';
import { endpoints } from '../../../../utils/URL';
import { addToast } from '../../../../utils/toastNotifications';
import { isFormValidated } from '../../../../utils/formUtils';
import { AppContext } from '../../../../context/AppContext';

const UserModal = ({ show, close, user, handleChange, callback }: IUserModal) => {
  const [isLoading, setIsLoading] = useState(false);
  const { appState } = useContext(AppContext);

  const saveUser = async (event: FormEvent) => {
    if (!isFormValidated('user-modal')) {
      return;
    }
    event.preventDefault();

    let url = endpoints.users.mainUrl;

    if (user.userId > 0) {
      url = `${url}/${user.userId}`;
    }
    user.pharmacyId = appState.selectedPharmacy.pharmacyId;

    setIsLoading(true);
    try {
      if (user.userId === 0) {
        await axios.post(url, user);
      } else {
        await axios.put(url, user);
      }

      addToast('User saved successfully', 'success');
      if (callback) {
        callback();
      }
      close();
    } catch (error) {
      addToast('An error occured while saving', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={close}>
      <form id='user-modal'>
        <Modal.Header closeButton>
          <Modal.Title>User Details for {user.username}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={isLoading}>
            <div className='form-group'>
              <label>Username (phone)</label>
              <input
                type='text'
                className='form-control'
                value={user.username}
                onChange={handleChange('username')}
                required
              />
            </div>
            <div className='form-group mt-4'>
              <label>Phone</label>
              <input
                type='text'
                pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                className='form-control'
                value={user.phone}
                onChange={handleChange('phone')}
                required
              />
            </div>
            <div className='form-group mt-4'>
              <label>Role</label>
              <select className='form-select' value={user.role} onChange={handleChange('role')}>
                {appState.entitlements.role === Roles.SysAdmin
                  ? LOGIN_ROLES_ADMIN.map((setting) => (
                      <option key={setting.key} value={setting.value.toString()}>
                        {setting.text}
                      </option>
                    ))
                  : LOGIN_ROLES.map((setting) => (
                      <option key={setting.key} value={setting.value.toString()}>
                        {setting.text}
                      </option>
                    ))}
              </select>
            </div>
            <div className='form-group mt-4'>
              <label>Is Active</label>
              <select
                className='form-select'
                value={user.isActive.toString()}
                onChange={handleChange('isActive')}
              >
                {BOOL_MAPPING.map((setting) => (
                  <option key={setting.key} value={setting.value.toString()}>
                    {setting.text}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-grid col-md-4'>
            <button className='btn btn-primary' type='submit' onClick={saveUser}>
              Save
              {isLoading ? <ButtonLoader /> : ''}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default UserModal;
