import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { defaultPageInfo } from '../../../../interface/CommonInterface';
import { defaultPharmacy, IPharmacy } from '../../../../interface/PharmacyInterface';
import { modifyUrl } from '../../../../utils/formUtils';
import { addToast } from '../../../../utils/toastNotifications';
import { endpoints } from '../../../../utils/URL';
import ButtonLoader from '../../../common/ButtonLoader';
import Loader from '../../../common/Loader';
import NoDataView from '../../../common/NoData';
import Pagination from '../../../common/Pagination';
import PharmacyModal from './PharmacyModal';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../../context/AppContext';
import { IAppState } from '../../../../interface/AppInterface';
import { Roles } from '../../../../utils/mappings';

const PharmacyList = () => {
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [pharmacyList, setPharmacyList] = useState<IPharmacy[]>([]);
  const [allData, setAllData] = useState<IPharmacy[]>([]);

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPharmacy, setSelectedPharmacy] = useState(defaultPharmacy);
  const [isSearch, setIsSearch] = useState(false);
  const [advSearch, setAdvSearch] = useState(false);
  const [showPagination, setShowPagination] = useState(false);
  const { appState }: { appState: IAppState } = useContext(AppContext);

  const getPharmacies = useCallback(async (pageIndex: number) => {
    const url = `${endpoints.pharmacies.mainUrl}?pageIndex=${pageIndex}&pageSize=100`;
    setIsLoading(true);
    try {
      const response = await axios.get(url);
      setPharmacyList(response.data.data);
      setAllData(response.data.data);

      const responsePageInfo = {
        pageIndex: response.data.pageIndex,
        pageSize: response.data.pageSize,
        pageCount: response.data.pageCount,
      };
      //set page info
      setPageInfo(responsePageInfo);
      setShowPagination(true);
    } catch (error) {
      addToast('An error occured while loading pharmacy list', 'error');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleChange = () => {};

  const pharmacyModal = (
    <PharmacyModal
      show={showModal}
      close={toggleModal}
      pharmacy={selectedPharmacy}
      handleChange={handleChange}
      callback={() => getPharmacies(1)}
    />
  );

  const selectPharmacy = (pharmacy: IPharmacy) => (event: any) => {
    setSelectedPharmacy(pharmacy);
    toggleModal();
  };

  /*######### Search methods ######### */

  const searchTable = (event: any) => {
    //search loaded data
    let searchParam = event.target.value;

    modifyUrl(searchParam);

    if (searchParam === '') {
      setPharmacyList(allData);
      setAdvSearch(false);
      setShowPagination(true);
      return;
    }

    //if not found, search remote?
    setShowPagination(false);

    searchParam = searchParam.toLowerCase();
    const searchResult = allData.filter(
      (x) =>
        x.pharmacyName?.toLowerCase().includes(searchParam) ||
        x.pharmacyAddress?.toLowerCase().includes(searchParam) ||
        x.lga?.toLowerCase().includes(searchParam) ||
        x.stateOfPremise?.toLowerCase().includes(searchParam) ||
        x.phone?.toLowerCase().includes(searchParam)
    );

    // //set table data to search result
    setPharmacyList(searchResult);

    if (searchParam.length >= 3) {
      setAdvSearch(true);
    } else {
      //setIsLoading(false);
    }
  };

  const searchRemoteData = async (event: any) => {
    let searchParamInput = document.getElementById('advSearchParam') as HTMLInputElement;
    const advSearchParam = searchParamInput.value;

    if (advSearchParam === '') {
      return;
    }
    event.preventDefault();

    //call remote api
    //set table data to result. allData still holds initial result? (or not)
    const url = `${endpoints.pharmacies.mainUrl}/search?pharmacyName=${advSearchParam}`;
    //setDataUrl(url);
    setIsSearch(true);
    try {
      const response = await axios.get(url);
      setPharmacyList(response.data.data);
    } catch (error) {
      addToast('An error occured');
    } finally {
      setIsSearch(false);
    }
  };

  /*######### Search methods ######### */

  useEffect(() => {
    getPharmacies(1);
  }, [getPharmacies]);

  //check if user is Admin
  useEffect(() => {
    if (appState.entitlements.role !== Roles.SysAdmin) {
      window.location.href = '/entry';
    }
  }, [appState.entitlements]);
  const dataView = (
    <div className='card pt-5 pb-5 ps-4 pe-4'>
      <div className='table-responsive'>
        <table className='table table-hover table-bordered'>
          <thead>
            <tr>
              <th>Status</th>
              <th>Pharmacy Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {pharmacyList.map((pharmacy) => (
              <tr key={pharmacy.pharmacyId}>
                <td>{pharmacy.status}</td>
                <td>{pharmacy.pharmacyName}</td>
                <td>{pharmacy.phone}</td>
                <td>{pharmacy.email}</td>
                <td>
                  <span
                    className='bi bi-pencil custom-group-icon text-info'
                    onClick={selectPharmacy(pharmacy)}
                    title='Edit'
                  ></span>
                  <Link
                    to={`/admin/${pharmacy.pharmacyId}/subscriptions`}
                    className='bi bi-card-checklist custom-group-icon text-info'
                    title='Subscriptions'
                  ></Link>

                  {/* <span
                    className='bi bi-trash custom-group-icon n text-danger'
                    title='Delete'
                  ></span>
                  <span
                    className='bi bi-x-circle custom-group-icon n text-succes'
                    title='Actions'
                  ></span> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showPagination ? (
        <Pagination
          currentPageNumber={pageInfo.pageIndex}
          numberOfPages={pageInfo.pageCount}
          callback={getPharmacies}
        />
      ) : (
        ''
      )}
    </div>
  );

  return (
    <section className=''>
      {pharmacyModal}
      <div className='d-flex'>
        <h4 className='me-auto'>Pharmacies ({pharmacyList.length * pageInfo.pageCount})</h4>
        <button type='button' className='btn btn-primary' onClick={selectPharmacy(defaultPharmacy)}>
          Add new Pharmacy
        </button>
      </div>
      <div className='col-sm-6'>
        <div className='form-inline'>
          <input
            className='form-control '
            type='search'
            placeholder='Search by name'
            title='Search by name'
            onChange={searchTable}
            id='advSearchParam'
          />
          {advSearch ? (
            <button className='btn btn-success' onClick={searchRemoteData}>
              {isSearch ? <ButtonLoader /> : 'Search'}
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
      <br />
      {isLoading && <Loader />}
      {!isLoading && pharmacyList.length > 0 && dataView}
      {!isLoading && pharmacyList.length === 0 && <NoDataView />}
    </section>
  );
};

export default PharmacyList;
