export const defaultMap = {};

export const NairaSymbol = '₦';

export const NOTIFICATION_SETTINGS = [
  {
    key: 0,
    value: 'None',
    text: 'None',
  },
  {
    key: 1,
    value: 'Email',
    text: 'Email Only',
  },
  {
    key: 2,
    value: 'Sms',
    text: 'Sms Only',
  },
  {
    key: 3,
    value: 'EmailSms',
    text: 'Email and SMS',
  },
];

export const PRINT_LABEL_SETTINGS = [
  {
    key: 0,
    text: 'Small 29mm x 90.3mm (Landscape)',
    value: 'Small',
  },
  {
    key: 1,
    text: 'Medium 62mm x 40mm (Potrait)',
    value: 'Medium1',
  },
  {
    key: 2,
    text: 'Medium Format 2 62mm x 40mm (Potrait)',
    value: 'Medium2',
  },
  {
    key: 3,
    text: 'Big 60mm x 90mm (Lansacape)',
    value: 'Big',
  },
];

export const PatientTabs = {
  general: 'general',
  medications: 'medications',
  messages: 'messages',
  notes: 'notes',
  bloodSugar: 'bloodsugar',
  bloodPressure: 'bloodPressure',
  cholesterol: 'cholesterol',
  heartRisk: 'heartRisk',
};

export const Roles = {
  Staff: 'Staff',
  Manager: 'Manager',
  Client: 'Client',
  SysAdmin: 'SysAdmin',
};

export const LOGIN_ROLES = [
  {
    key: 0,
    text: 'Staff',
    value: 'Staff',
  },
  {
    key: 1,
    text: 'Manager',
    value: 'Manager',
  },
];

export const LOGIN_ROLES_ADMIN = [
  {
    key: 0,
    text: 'Staff',
    value: 'Staff',
  },
  {
    key: 1,
    text: 'Manager',
    value: 'Manager',
  },
  {
    key: 2,
    text: 'SysAdmin',
    value: 'SysAdmin',
  },
  {
    key: 3,
    text: 'Client',
    value: 'Client',
  },
];

export const BOOL_MAPPING = [
  {
    key: 0,
    value: true,
    text: 'Yes',
  },
  {
    key: 1,
    value: false,
    text: 'No',
  },
];

export const MEDICATION_MAPPING = [
  {
    key: 0,
    value: false,
    text: 'Acute',
  },
  {
    key: 1,
    value: true,
    text: 'Chronic',
  },
];

export const MESSAGE_ACTION = [
  {
    key: 0,
    value: '',
    text: '--Select Action --',
  },
  {
    key: 1,
    value: 'Queued',
    text: 'Re-Queue',
  },
  {
    key: 2,
    value: 'Sent',
    text: 'Mark as sent',
  },
  {
    key: 3,
    value: 'Cancelled',
    text: 'Cancel',
  },
];

export const MESSAGE_STATUS = [
  {
    key: 0,
    value: '',
    text: '--All Status --',
  },
  {
    key: 1,
    value: 'NoCredit',
    text: 'No Credit',
  },
  {
    key: 2,
    value: 'Queued',
    text: 'Queued',
  },
  {
    key: 3,
    value: 'Sent',
    text: 'Sent',
  },
  {
    key: 4,
    value: 'Delivered',
    text: 'Delivered',
  },
  {
    key: 5,
    value: 'Cancelled',
    text: 'Cancelled',
  },
];

export const CountryCodes = [
  {
    text: 'Nigeria (+234)',
    value: '234',
  },
  {
    text: 'Algeria (+213)',
    value: '213',
  },
  {
    text: 'Angola (+244)',
    value: '244',
  },
  {
    text: 'Anguilla (+264)',
    value: '264',
  },
  {
    text: 'Antigua (+268)',
    value: '268',
  },
  {
    text: 'Aruba (+297)',
    value: '297',
  },
  {
    text: 'Australia (+61)',
    value: '61',
  },
  {
    text: 'Botswana (+267)',
    value: '267',
  },
  {
    text: 'Burkina Faso (+226)',
    value: '226',
  },
  {
    text: 'Burundi (+257)',
    value: '257',
  },
  {
    text: 'Cameroon (+237)',
    value: '237',
  },
  {
    text: 'Cape Verde Island (+238)',
    value: '238',
  },
  {
    text: 'Central Africa Republic (+236)',
    value: '236',
  },
  {
    text: 'Chad Republic (+235)',
    value: '235',
  },
  {
    text: 'Comoros (+269)',
    value: '269',
  },
  {
    text: 'Congo (+242)',
    value: '242',
  },
  {
    text: 'Dem. Rep. of Congo (+243)',
    value: '243',
  },
  {
    text: 'Djibouti (+253)',
    value: '253',
  },
  {
    text: 'Equatorial Guinea (+240)',
    value: '240',
  },
  {
    text: 'Eritrea (+291)',
    value: '291',
  },
  {
    text: 'Ethiopia (+251)',
    value: '251',
  },
  {
    text: 'Gabon (+241)',
    value: '241',
  },
  {
    text: 'Gambia (+220)',
    value: '220',
  },
  {
    text: 'Ghana (+233)',
    value: '233',
  },
  {
    text: 'Guinea (+224)',
    value: '224',
  },
  {
    text: 'Ivory Coast (+225)',
    value: '225',
  },
  {
    text: 'Kenya (+254)',
    value: '254',
  },
  {
    text: 'Lesotho (+266)',
    value: '266',
  },
  {
    text: 'Liberia (+231)',
    value: '231',
  },
  {
    text: 'Libya (+218)',
    value: '218',
  },
  {
    text: 'Madagascar (+261)',
    value: '261',
  },
  {
    text: 'Malawi (+265)',
    value: '265',
  },
  {
    text: 'Mali Republic (+223)',
    value: '223',
  },
  {
    text: 'Mauritania (+222)',
    value: '222',
  },
  {
    text: 'Mauritius (+230)',
    value: '230',
  },
  {
    text: 'Mayotte Island (+269)',
    value: '269',
  },
  {
    text: 'Mozambique (+258)',
    value: '258',
  },
  {
    text: 'Namibia (+264)',
    value: '264',
  },
  {
    text: 'Niger (+227)',
    value: '227',
  },
  {
    text: 'Rwanda (+250)',
    value: '250',
  },
  {
    text: 'Senegal Republic (+221)',
    value: '221',
  },
  {
    text: 'Sierra Leone (+232)',
    value: '232',
  },
  {
    text: 'Somalia (+252)',
    value: '252',
  },
  {
    text: 'South Africa (+27)',
    value: '27',
  },
  {
    text: 'Sudan (+249)',
    value: '249',
  },
  {
    text: 'Suriname (+597)',
    value: '597',
  },
  {
    text: 'Swaziland (+268)',
    value: '268',
  },
  {
    text: 'Tanzania (+255)',
    value: '255',
  },
  {
    text: 'Togo (+228)',
    value: '228',
  },
  {
    text: 'Uganda (+256)',
    value: '256',
  },

  {
    text: 'Zambia (+260)',
    value: '260',
  },

  {
    text: 'Zanzibar (+255)',
    value: '255',
  },
  {
    text: 'Zimbabwe (+263)',
    value: '263',
  },
  {
    text: 'United Kingdom (+44)',
    value: '44',
  },
  {
    text: 'Ireland (+353)',
    value: '353',
  },
  {
    text: 'United States of America (+1)',
    value: '1',
  },
];

export const BloodGroup = [
  {
    key: 0,
    text: 'A+',
    value: 'A_positive',
  },
  {
    key: 1,
    text: 'A-',
    value: 'A_negative',
  },
  {
    key: 2,
    text: 'B+',
    value: 'B_positive',
  },
  {
    key: 3,
    text: 'B-',
    value: 'B_negative',
  },
  {
    key: 4,
    text: 'AB+',
    value: 'AB_positive',
  },
  {
    key: 5,
    text: 'AB-',
    value: 'AB_negative',
  },
  {
    key: 6,
    text: 'O+',
    value: 'O_positive',
  },
  {
    key: 7,
    text: 'O-',
    value: 'O_negative',
  },
];

export const PharmacyPackage = [
  {
    key: 0,
    value: 'Bronze',
    text: 'Bronze',
  },
  {
    key: 1,
    value: 'Silver',
    text: 'Silver',
  },
  {
    key: 1,
    value: 'Gold',
    text: 'Gold',
  },
];
export const PharmacyStatus = [
  {
    key: 0,
    value: 'Active',
    text: 'Active',
  },
  {
    key: 1,
    value: 'Inactive',
    text: 'Inactive',
  },
  {
    key: 1,
    value: 'Cancelled',
    text: 'Cancelled',
  },
];

export const SmsProvider = [
  {
    key: 0,
    value: 'Africastalking',
    text: 'Africastalking',
  },
  {
    key: 1,
    value: 'SNS',
    text: 'SNS',
  },
];

export const PaymentChannel = [
  {
    key: 0,
    value: 'Paystack',
    text: 'Paystack',
  },
  {
    key: 1,
    value: 'BankTransfer',
    text: 'BankTransfer',
  },
  {
    key: 2,
    value: 'Cash',
    text: 'Cash',
  },
];

export const PaymentPlan = [
  {
    key: 1,
    value: 1,
    planName: 'WellaPartner Lite',
    planCode: 'WHELite',
    monthlyPrice: 1000,
    sms: 0,
  },
  {
    key: 2,
    value: 2,
    planName: 'WellaPartner Basic',
    planCode: 'WHEBasic',
    monthlyPrice: 2500,
    sms: 0,
  },
  {
    key: 3,
    value: 3,
    planName: 'WellaPartner Engage',
    planCode: 'WHEStandard',
    monthlyPrice: 5000,
    sms: 500,
  },
  {
    key: 4,
    value: 4,
    planName: 'WellaPartner Plus',
    planCode: 'WHEPlus',
    monthlyPrice: 7000,
    sms: 500,
  },
];

export const BloodSugarType = [
  {
    key: 1,
    value: 'FastingBloodSugar',
    text: 'Fasting Blood Sugar',
  },
  {
    key: 2,
    value: 'RandomBloodSugar',
    text: 'Random Blood Sugar',
  },
];

export const VitalScreenTypes = {
  bioData: 'Biodata',
  questionnaire: 'Questionnaire',
  feedback: 'Feedback',
};
