// import React from 'react'
import NavBar from '../components/NavBar';
import TestimonialCard from '../components/TestimonialCard';
import FooterComponent from '../components/FooterComponent';
import Container from 'react-bootstrap/Container';
import { testimonials } from '../constants';

const Testimonial = () => {
  return (
    <div>
      <NavBar />
      <div className='bg-white'>
        <Container className='py-5 d-flex flex-column justify-content-center align-items-center'>
          <p className='bg-dark-subtle px-2 py-1 rounded-4'>Customer Stories</p>
          <h2 className='text-center fs-4 fw-semibold pt-3'>Our Wall of love</h2>
          <p className='text-center fs-5 mb-5'>
            Read what our community members are saying about us
          </p>
          <div>
            <iframe
              title='WellaPartner Video'
              className='img-fluid mt-3'
              src={`https://www.youtube.com/embed/pLqUPNUPwBw?si=lYIbWjH3yEth-scY`}
              allowFullScreen
              style={{ width: '500px', height: '250px', borderRadius: '10px' }}
            ></iframe>
          </div>
          <TestimonialCard testimonials={testimonials} />
        </Container>
      </div>
      <FooterComponent />
    </div>
  );
};

export { Testimonial };
