import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { defaultPageInfo } from '../../../interface/CommonInterface';
import { IPatientNote } from '../../../interface/NotesInterface';
import { IPatientTabProps } from '../../../interface/PatientInterface';
import { formatDateTime } from '../../../utils/dateTimeUtils';
import { PatientTabs } from '../../../utils/mappings';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import Loader from '../../common/Loader';
import NoDataView from '../../common/NoData';
import Pagination from '../../common/Pagination';
import PatientNoteModal from './PatientNoteModal';

const PatientNotesScreen = ({ patientId, tabKey }: IPatientTabProps) => {
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [notes, setNotes] = useState<IPatientNote[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getNotes = useCallback(
    async (pageIndex: number) => {
      if (patientId === 0) {
        return;
      }

      const url = `${endpoints.notes.getByPatient}/${patientId}?pageIndex=${pageIndex}&pageSize=10`;
      setIsLoading(true);
      try {
        const response = await axios.get(url);
        setNotes(response.data.data);

        const responsePageInfo = {
          pageIndex: response.data.pageIndex,
          pageSize: response.data.pageSize,
          pageCount: response.data.pageCount,
        };
        //set page info
        setPageInfo(responsePageInfo);
      } catch (error) {
        addToast('An error occured');
      } finally {
        setIsLoading(false);
      }
    },
    [patientId]
  );

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (tabKey !== PatientTabs.notes) {
      return;
    }
    getNotes(1);
  }, [patientId, tabKey, getNotes]);

  let patientNoteModal = (
    <PatientNoteModal
      show={showModal}
      close={toggleModal}
      patientId={patientId}
      callback={() => getNotes(1)}
    />
  );

  const dataView = (
    <div>
      <div className='table-responsive'>
        <table className='table table-hover table-bordered'>
          <thead>
            <tr>
              <th>Date Saved</th>
              <th>Note</th>

              {/* <th>Actions </th> */}
            </tr>
          </thead>
          <tbody>
            {notes.map((note) => (
              <tr key={note.noteId}>
                <td>{formatDateTime(note.dateSaved)}</td>
                <td>{note.notes}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPageNumber={pageInfo.pageIndex}
        numberOfPages={pageInfo.pageCount}
        callback={getNotes}
      />
    </div>
  );

  return (
    <section className='mt-5'>
      {patientNoteModal}
      <h5 className='mb-4'>Clinic Notes</h5> <br />
      <button className='btn btn-primary' onClick={toggleModal}>
        Add Note
      </button>
      <div className='mt-3'></div>
      {isLoading && <Loader />}
      {!isLoading && notes.length > 0 && dataView}
      {!isLoading && notes.length === 0 && <NoDataView />}
    </section>
  );
};

export default PatientNotesScreen;
