let BASE_URL = process.env.REACT_APP_BASE_URL + '/api';

let endpoints = {
  pharmacies: {
    mainUrl: BASE_URL + '/pharmacies',
    search: BASE_URL + '/pharmacies/search',
    pharmacyList: BASE_URL + '/pharmacies/list',
    searchList: BASE_URL + '/pharmacies/searchlist',
    client: BASE_URL + '/pharmacies/clients',
    postQuickActionData: BASE_URL + '/pharmacies/topup/',
  },

  paystack: {
    VerifyTransaction: BASE_URL + '/paystackhook/verify/',
  },

  managers: {
    PharmacyTopUp: BASE_URL + '/managers/topup/',
  },

  patients: {
    mainUrl: BASE_URL + '/patients',
    getByPharmacy: BASE_URL + '/patients/pharmacy',
    getByName: BASE_URL + '/patients/patientname',
    getByDiseaseProfile: BASE_URL + '/patients/diseasetype',
  },
  patientDrugs: {
    mainUrl: BASE_URL + '/patientdrugs',
    refills: BASE_URL + '/patientdrugs/refills',
    getByPatient: BASE_URL + '/patientdrugs/patient',
    deleteMedicationId: BASE_URL + '/patientdrugs',
    bulk: BASE_URL + '/patientdrugs/bulk',
    diseaseProfile: BASE_URL + '/patientdrugs/diseasetypes',
  },
  notifications: {
    mainUrl: BASE_URL + '/notifications',
    getByPharmacy: BASE_URL + '/notifications/pharmacy',
    markAsRead: BASE_URL + '/notifications/read',
  },
  notificationSettings: {
    mainUrl: BASE_URL + '/notificationsettings',
    getByPharmacy: BASE_URL + '/notificationsettings/pharmacy',
  },
  notes: {
    mainUrl: BASE_URL + '/notes',
    getByPatient: BASE_URL + '/notes/patient',
  },
  messaging: {
    mainUrl: BASE_URL + '/messaging',
    deleteBulk: BASE_URL + '/messaging/deletebulk',
    queue: BASE_URL + '/messaging/queue',
    getByPatient: BASE_URL + '/messaging/patients',
  },
  messages: {
    mainUrl: BASE_URL + '/messages',
    getByPhone: BASE_URL + '/messages/phone',
    getByPharmacy: BASE_URL + '/messages/pharmacy',
    deleteBulk: BASE_URL + '/messages/bulk',
    getByDate: BASE_URL + '/messages/date',
    getByFilter: BASE_URL + '/messages/filter',
  },
  users: {
    mainUrl: BASE_URL + '/users',
    changePassword: BASE_URL + '/users/changepassword',
    getByPharmacy: BASE_URL + '/users/pharmacy',
  },
  healthDetails: {
    mainUrl: BASE_URL + '/healthdetails',
  },
  entry: {
    mainUrl: BASE_URL + '/entry',
    saveTransactions: BASE_URL + '/entry/savetransactions',
  },
  drugs: {
    mainUrl: BASE_URL + '/drugs',
    getByName: BASE_URL + '/drugs/drugname/',
  },
  cholesterol: {
    mainUrl: BASE_URL + '/cholesterols',
    getByPatient: BASE_URL + '/cholesterols/patient',
    bulk: BASE_URL + '/cholesterols/bulk',
  },
  bloodSugar: {
    mainUrl: BASE_URL + '/bloodsugars',
    getByPatient: BASE_URL + '/bloodsugars/patient',
    bulk: BASE_URL + '/bloodsugars/bulk',
  },
  bloodPressures: {
    mainUrl: BASE_URL + '/bloodpressures',
    getByPatient: BASE_URL + '/bloodpressures/patient',
    bulk: BASE_URL + '/bloodpressures/bulk',
  },
  templates: {
    mainUrl: BASE_URL + '/templates',
    getByPharmacy: BASE_URL + '/templates/pharmacy',
  },
  auth: {
    mainUrl: BASE_URL + '/auth',
    getToken: BASE_URL + '/auth/gettoken',
    getEntitlements: BASE_URL + `/auth/entitlements`,
    changePassword: BASE_URL + '/auth/changepassword',
  },
  engageSubscription: {
    mainUrl: BASE_URL + '/engagesubscriptions',
  },
  wallets: {
    walletAccounts: {
      mainUrl: BASE_URL + '/walletaccounts',
    },
    walletTransactions: {
      mainUrl: BASE_URL + '/wallettransactions',
    },
  },
  vaccination: {
    mainUrl: BASE_URL + '/vaccinations',
  },
};

let externalEndpoints = {
  DrugInteraction: {
    CheckRxcui: 'https://rxnav.nlm.nih.gov/REST/rxcui.json?name=',
    CheckForInteractions: 'https://rxnav.nlm.nih.gov/REST/interaction/list.json?rxcuis=',
  },
};

//removed modules.exports for Typescript due to --isolatedModules error
export { endpoints, BASE_URL, externalEndpoints };
