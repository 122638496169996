import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../context/AppContext';
import { defaultUser, IUserDTO } from '../../../../interface/UserInterface';
import { addToast } from '../../../../utils/toastNotifications';
import { endpoints } from '../../../../utils/URL';
import Loader from '../../../common/Loader';
import NoDataView from '../../../common/NoData';
import UserModal from './UserModal';

const Users = () => {
  const [users, setUsers] = useState<IUserDTO[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUserDTO>(defaultUser);
  const { appState } = useContext(AppContext);
  const [tableLoading, setTableLoading] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const getUsers = useCallback(async () => {
    if (appState.selectedPharmacy.pharmacyId === 0) {
      return;
    }

    const pharmacyId = appState.selectedPharmacy.pharmacyId;
    const url = `${endpoints.users.getByPharmacy}/${pharmacyId}`;
    setTableLoading(true);

    try {
      const response = await axios.get(url);
      setUsers(response.data);
    } catch (error) {
      addToast('An error occured while getting users', 'error');
    } finally {
      setTableLoading(false);
    }
  }, [appState.selectedPharmacy]);

  const handleChange = (input: string) => (event: any) => {
    setSelectedUser({
      ...selectedUser,
      [input]: event.target.value,
    });
  };

  const selectUser = (user: IUserDTO) => {
    setSelectedUser(user);
    toggleModal();
  };

  let userModal = (
    <UserModal
      show={showModal}
      close={toggleModal}
      user={selectedUser}
      handleChange={handleChange}
      callback={getUsers}
    />
  );

  const resetPassword = async (userId: number) => {
    const reply = window.confirm('Are you sure you want to reset this password?');
    if (!reply) {
      return;
    }

    const url = `${endpoints.users.mainUrl}/${userId}/newpassword`;

    try {
      await axios.post(url);
      addToast('Password reset successful', 'success');
    } catch (error) {
      addToast('Password could not be reset', 'error');
    }
  };

  const deleteUser = async (userId: number) => {
    const reply = window.confirm('Are you sure you want to delete this user?');
    if (!reply) {
      return;
    }

    const url = `${endpoints.users.mainUrl}/${userId}`;

    try {
      await axios.delete(url);
      addToast('User deleted', 'success');
      await getUsers();
    } catch (error) {
      addToast('User cannot be deleted', 'error');
    }
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const dataView = (
    <div className='table-responsive card pt-5 pb-5 ps-4 pe-4'>
      <table className='table table-hover table-bordered'>
        <thead>
          <tr>
            <th>Username</th>
            <th>Phone Number</th>
            <th>Role</th>
            <th>Active</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.userId}>
              <td>{user.username}</td>
              <td>{user.phone}</td>
              <td>{user.role}</td>
              <td>{user.isActive.toString() === 'true' ? 'Yes' : 'No'}</td>
              <td>
                <button className='btn text-info' onClick={() => resetPassword(user.userId)}>
                  <span className='bi bi-recycle custom-single-icon' title='Reset Password'></span>
                </button>
                <button className='btn text-success' onClick={() => selectUser(user)}>
                  <span className='bi bi-pencil custom-single-icon' title='Edit'></span>
                </button>
                <button className='btn text-danger' onClick={() => deleteUser(user.userId)}>
                  <span className='bi bi-trash custom-single-icon' title='Edit'></span>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div>
      {userModal}
      <div className='d-flex'>
        <h4 className='me-auto'>Users ({users.length})</h4>
        <button type='button' className='btn btn-primary' onClick={() => selectUser(defaultUser)}>
          Add new User
        </button>
      </div>
      <div className='mt-3'></div>
      {tableLoading && <Loader />}
      {!tableLoading && users.length > 0 && dataView}
      {!tableLoading && users.length === 0 && <NoDataView />}
    </div>
  );
};

export default Users;
