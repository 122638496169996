import axios from 'axios';
import React, { FormEvent, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IPatientNote } from '../../../interface/NotesInterface';
import { IPatientModal } from '../../../interface/CommonInterface';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';

const PatientNoteModal = ({ show, close, patientId, callback }: IPatientModal) => {
  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  const saveNote = async (event: FormEvent) => {
    if (!isFormValidated('add-note-modal')) {
      addToast('Please fill all fields', 'warning');
      return;
    }
    event.preventDefault();
    const noteData: IPatientNote = {
      noteId: 0,
      notes: note,
      patientId: patientId,
      dateSaved: new Date().toISOString(),
      dateCreated: '',
      dateUpdated: '',
    };

    setIsLoading(true);
    const url = `${endpoints.notes.mainUrl}`;

    try {
      await axios.post(url, noteData);
      addToast('Notes added successfully', 'success');
      if (callback) {
        callback();
      }
      close();
    } catch (error) {
      addToast('An error occured while saving data');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={close} backdrop={isLoading ? 'static' : true}>
      <form id='add-note-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={isLoading}>
            <div className='form-group'>
              <label className='required'>Notes</label>
              <textarea
                className='form-control'
                rows={5}
                value={note}
                onChange={handleChange}
                required
              />
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' type='submit' onClick={saveNote}>
            Save
            {isLoading ? <ButtonLoader /> : ''}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default PatientNoteModal;
