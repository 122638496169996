import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { WalletAccountDetails } from '../../../interface/WalletInterface';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import { isFormValidated } from '../../../utils/formUtils';
import { NairaSymbol } from '../../../utils/mappings';

const WithdrawalModal = ({
  open,
  close,
  clientId,
}: {
  open: boolean;
  close: Function;
  clientId: number;
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [walletDetails, setWalletDetails] = useState<WalletAccountDetails[]>([]);
  const [selectedWallet, setSelectedWallet] = useState<WalletAccountDetails | undefined>();

  const changeWallet = (event: any) => {
    const pharmacyCode = event.target.value;
    if (pharmacyCode === '') {
      return;
    }
    const wallet = walletDetails.find((x) => x.pharmacyCode === pharmacyCode);
    setSelectedWallet(wallet);
  };

  const withdraw = async (event: any) => {
    if (!isFormValidated('withdrawal-form')) {
      return;
    }
    event.preventDefault();

    if (selectedWallet === null || typeof selectedWallet === 'undefined') {
      addToast('Please select a wallet', 'error');
      return;
    }

    if (withdrawalAmount < 2000) {
      addToast('Withdrawal amount must be greater than or equal to 2000', 'warning');
      return;
    }
    if (withdrawalAmount > selectedWallet?.walletBalance) {
      addToast(
        `Withdrawal amount must be less than wallet balance: ${NairaSymbol} ${selectedWallet.walletBalance}`,
        'warning'
      );
      return;
    }

    setDisableButton(true);
    try {
      const payload = {
        amount: withdrawalAmount,
        pharmacyCode: selectedWallet?.pharmacyCode,
      };
      const url = `${endpoints.wallets.walletAccounts.mainUrl}/withdrawal`;
      await axios.post(url, payload);

      addToast('Withdrawal Request successfully sent.', 'success');
      close();
    } catch (error) {
      addToast('Error sending withdrawal request', 'error');
    } finally {
      setDisableButton(false);
    }
  };

  const loadAllWallets = useCallback(async () => {
    let url = `${endpoints.wallets.walletAccounts.mainUrl}/client/${clientId}/details`;

    try {
      const response = await axios.get(url);
      setWalletDetails(response.data);
    } catch (error) {
      addToast('Error getting wallet balances', 'error');
    } finally {
    }
  }, [clientId]);

  useEffect(() => {
    if (open) {
      loadAllWallets();
    }
  }, [loadAllWallets, open]);

  return (
    <Modal show={open} onHide={() => close()}>
      <Modal.Header closeButton>
        <Modal.Title>Withdrawal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=''>
          <div className='mb-3'>
            <label>Select Pharmacy to withdraw</label>
            <select disabled={disableButton} className='form-select' onChange={changeWallet}>
              <option value=''>--Select a pharmacy--</option>
              {walletDetails.map((wallet) => (
                <option key={wallet.accountId} value={wallet.pharmacyCode}>
                  {wallet.pharmacyName}
                </option>
              ))}
            </select>
          </div>
          <form id='withdrawal-form'>
            <fieldset>
              <div className='form-group'>
                <label>Account number</label>
                <input
                  className='form-control'
                  value={selectedWallet?.bankAccountNumber}
                  disabled
                />
              </div>
              <div className='form-group mt-4'>
                <label>Account Name</label>
                <input className='form-control' value={selectedWallet?.bankAccountName} disabled />
              </div>
              <div className='form-group mt-4'>
                <label>Bank Name</label>
                <input className='form-control' value={selectedWallet?.bankName} disabled />
              </div>
              <div className='form-group mt-4'>
                <label>Amount: (Minimum of ₦2,000)</label>
                <input
                  className='form-control'
                  type='number'
                  value={withdrawalAmount}
                  onChange={(e) => setWithdrawalAmount(parseInt(e.target.value))}
                  required
                  min={2000}
                  max={selectedWallet?.walletBalance}
                />
              </div>
              <div className='mt-5 d-grid'>
                <button
                  disabled={disableButton}
                  onClick={withdraw}
                  className='btn btn-primary btn-block'
                >
                  Withdraw
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WithdrawalModal;
