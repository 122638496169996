import axios from 'axios';
import React, { FormEvent, useContext, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import { IPasswordChange, initialPasswordChange } from '../../../interface/AccountInterface';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';

const AccountSettings = () => {
  const [passwordData, setPasswordData] = useState<IPasswordChange>(initialPasswordChange);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordShown, setPasswordShown] = useState(false);
  const { appState } = useContext(AppContext);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!isPasswordShown);
  };
  const handleChange = (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (input === 'repeatPassword') {
      //check if the passwords match, if not return;
      if (event.target.value !== passwordData.newPassword) {
        addToast('Passwords do not match');
      }
    }
    setPasswordData({
      ...passwordData,
      [input]: event.target.value,
    });
  };

  const saveChanges = async (event: FormEvent) => {
    if (!isFormValidated('password-change-form')) {
      return;
    }

    event.preventDefault();
    passwordData.userId = appState.userId;
    const url = endpoints.auth.changePassword;

    setIsLoading(true);

    try {
      await axios.post(url, passwordData);
      addToast('Password changed successfully', 'success');
      setPasswordData(initialPasswordChange);
    } catch (error) {
      addToast('Error occured while setting new password', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='mt-4 card pt-5 pb-5 ps-4 pe-4'>
      <br />
      <h4 className='text-center mt-4'>Accounts Setting</h4>
      <div className='row justify-content-center mt-4'>
        <div className='col-lg-4 col-md-6 col-sm-8'>
          <button
            className='float-right btn'
            onClick={togglePasswordVisiblity}
            title='Show / Hide password'
          >
            <span className={isPasswordShown ? 'bi bi-eye' : 'bi bi-eye-slash'}></span>
          </button>
          <br />
          <form id='password-change-form'>
            <fieldset disabled={isLoading}>
              <div className='form-group mt-4'>
                <label>Current password</label>
                <input
                  type={isPasswordShown ? 'text' : 'password'}
                  className='form-control'
                  required
                  value={passwordData.oldPassword}
                  onChange={handleChange('oldPassword')}
                />
              </div>
              <div className='form-group mt-4'>
                <label>New password</label>
                <input
                  type={isPasswordShown ? 'text' : 'password'}
                  className='form-control'
                  value={passwordData.newPassword}
                  onChange={handleChange('newPassword')}
                  required
                  pattern='^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,24}$'
                  title='Password must be atleast 6 characters, with atleast one number and one letter'
                />
              </div>
              <div className='form-group mt-4'>
                <label>Repeat new password</label>
                <input
                  type={isPasswordShown ? 'text' : 'password'}
                  className='form-control'
                  value={passwordData.repeatPassword}
                  onChange={handleChange('repeatPassword')}
                  required
                  pattern='^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,24}$'
                  title='Password must be atleast 6 characters, with atleast one number and one letter'
                />
              </div>
              <br />
              <div className='mt-3 d-grid'>
                <button type='submit' className='btn btn-primary btn-block' onClick={saveChanges}>
                  Change Password {isLoading ? <ButtonLoader /> : ''}
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
