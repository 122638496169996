import { Modal } from 'react-bootstrap';
import { IPatientUploadData } from '../../../interface/PatientInterface';
import NoTableData from '../../common/NoTableData';

const PatientUploadModal = ({
  patients,
  show,
  close,
}: {
  patients: IPatientUploadData[] | undefined;
  show: boolean;
  close: Function;
}) => {
  return (
    <Modal show={show} onHide={() => close()} size='xl'>
      <div className='table-responsive' style={{ minHeight: '480px' }}>
        <table className='table table-hovered '>
          <thead>
            <tr>
              <th>Patient name</th>
              <th>Phone number</th>
              <th>Email</th>
              <th>Date of birth</th>
              <th>Gender</th>
              <th>Address</th>
              <th>Notes</th>
            </tr>
          </thead>
          {patients && patients.length > 0 ? (
            <tbody>
              {patients.map((patient) => (
                <tr key={patient.phoneNumber}>
                  <td>{patient.patientName}</td>
                  <td>{patient.phoneNumber}</td>
                  <td>{patient.email}</td>
                  <td>{patient.dateOfBirth}</td>
                  <td>{patient.gender}</td>
                  <td>{patient.address}</td>
                  <td>{patient.reason}</td>
                </tr>
              ))}
            </tbody>
          ) : (
            <NoTableData />
          )}
        </table>
      </div>
    </Modal>
  );
};

export default PatientUploadModal;
