// import React from "react";
import { Row, Col, Card, Container } from 'react-bootstrap';

export interface TestimonialProps {
  id: number;
  name: string;
  testimony: string;
  city: string;
  country: string;
}

const TestimonialCard = ({ testimonials }: { testimonials: TestimonialProps[] }) => {
  return (
    <Container className="mt-5 d-flex align-items-center justify-content-center">
			<div>

				<Row xs={1} md={2} lg={2} className="g-4">
					{testimonials.map((testimonial) => (
						<Col key={testimonial.id}>
							<Card className="bg-dark-subtle p-2">
								<Card.Body>
									<Card.Text>{testimonial.testimony}</Card.Text>
									<p className="fw-bold mb-0">{testimonial.name}</p>
									<p className="mb-0">{`${testimonial.city}, ${testimonial.country}`}</p>
								</Card.Body>
							</Card>
						</Col>
					))}
				</Row>
			</div>
		</Container>
  );
};

export default TestimonialCard;
