export interface INotification {
  notificationId: number;
  notificationContent: string;
  dateCreated: string;
  isRead: boolean;
}

export interface INotificationSettings {
  settingId: number;
  pharmacyId: number;
  weeklyStats: string;
  accountExpiry: string;
  lowCredit: string;
  notifications: string;
}

export const initialNotificationSettings: INotificationSettings = {
  settingId: 0,
  pharmacyId: 0,
  weeklyStats: 'None',
  accountExpiry: 'None',
  lowCredit: 'None',
  notifications: 'None',
};
