import {
  IDrugRxcui,
  IMedicationDetail,
  IMedicationDispense,
} from '../../../interface/MedicationEntryInterface';
import { useState, useContext } from 'react';
import { IPatientSearchResult } from '../../../interface/PatientInterface';
import { addToast } from '../../../utils/toastNotifications';
import MedicationModal from './MedicationModal';
import PatientModal from './PatientModal';
import PatientSearch from '../../common/PatientSearch';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { AppContext } from '../../../context/AppContext';
import { IAppState } from '../../../interface/AppInterface';
import ButtonLoader from '../../common/ButtonLoader';
import { Link } from 'react-router-dom';
import PageTour from '../../common/PageTour';
import { entrySteps } from '../../../utils/pageTourSteps';

const EntryPage = () => {
  const { appState } = useContext(AppContext);

  const [medications, setMedications] = useState<IMedicationDetail[]>([]);
  const [selectedMedication, setSelectedMedication] = useState<IMedicationDetail | undefined>();
  const [selectedPatient, setSelectedPatient] = useState<IPatientSearchResult | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [showPatientModal, setShowPatientModal] = useState(false);
  const [interactionState, setInteractionState] = useState(0); //0: not shown, 1: checking, 2: found, 3: not found
  const [interactionLink, setInteractionLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDrugEntry, setShowDrugEntry] = useState(false);

  const [rxcuis, setRxcui] = useState<IDrugRxcui[]>([]);

  const patientSearchCallback = (searchResult: IPatientSearchResult) => {
    //search patient
    setSelectedPatient(searchResult);
    //if a patient is selected, clear all medications
  };

  const editPatient = () => {
    setShowPatientModal(true);
  };

  const addMedication = (medication: IMedicationDetail) => {
    //if medicationId > 0 then we are editing
    if (medication.medicationId > 0) {
      //delete existing medication with the id
      const existingMedication = medications.filter(
        (x) => x.medicationId !== medication.medicationId
      );

      //add updated medication
      existingMedication.push(medication);
      setMedications(existingMedication);
    } else {
      //else we are adding
      let medicationId = medications.length + 1;

      const lastMedication = medications[medications.length - 1];

      if (lastMedication) {
        medicationId = lastMedication.medicationId + 1;
      }

      medication.medicationId = medicationId;
      medications.push(medication);
      setMedications(medications);
    }

    checkDrugsRxcui(medication.drugName, medication.medicationId);
  };

  const toggleMedicationModal = () => {
    setSelectedMedication(undefined);
    setShowModal(!showModal);
  };

  const editMedication = (medicationId: number) => {
    //get the medication using id
    const medication = medications.filter((x) => x.medicationId === medicationId)[0];

    if (medication === null || typeof medication === 'undefined') {
      return;
    }
    setSelectedMedication(medication);
    setShowModal(true);
    //open modal to edit
  };

  const deleteMedication = (medicationId: number) => {
    const existingMedication = medications.filter((x) => x.medicationId !== medicationId);
    medications.length = existingMedication.length;
    //add updated medication
    setMedications(existingMedication);
    addToast('Medication deleted', 'success');

    //rerun interaction
    //remove deleted
    const updatedRxcui = rxcuis.filter((x) => x.drugId !== medicationId);
    setRxcui(updatedRxcui);
    checkInteraction(updatedRxcui);
  };
  /* Patient modal */
  const openPatientModal = () => {
    setSelectedPatient(undefined);
    setShowPatientModal(!showPatientModal);
  };

  const closePatientModal = () => {
    setShowPatientModal(false);
  };

  const patientModalCallback = (searchResult: IPatientSearchResult) => {
    setSelectedPatient(searchResult);
  };

  /*drug interactions section */

  //Algo to implement interaction check
  //once you add a drug,
  //split on +
  //for each item, find the rxcui for the drugs
  //store results in object and store object in array of objects
  //for all results in array, get the interations using the rxcui

  const findRxcui = async (drugName: string) => {
    //if compound drug, split on +
    const url = 'https://rxnav.nlm.nih.gov/REST/rxcui.json?name=' + drugName;
    let rxcui = 0;
    try {
      const response = await axios.get(url);
      const data = response.data;
      if (data.idGroup.rxnormId && data.idGroup.rxnormId.length >= 0) {
        //check if the drug has a rxcui number
        rxcui = data.idGroup.rxnormId[0];
      } else {
        console.log('No rxcui');
      }
    } catch (error) {
      console.error(error);
    }

    return rxcui;
  };

  const checkDrugsRxcui = async (drugName: string, drugId: number) => {
    let oldRxcuis = rxcuis;

    if (drugName.indexOf('+') > -1) {
      const items = drugName.split('+');

      if (items.length) {
        return;
      }

      items.forEach(async (item) => {
        const rxcui = await findRxcui(item.trim());
        const drugRxcui: IDrugRxcui = {
          rxcui: rxcui,
          drugId: drugId,
        };
        oldRxcuis.push(drugRxcui);
      });

      return;
    }
    const rxcui = await findRxcui(drugName.trim());
    const drugRxcui: IDrugRxcui = {
      rxcui: rxcui,
      drugId: drugId,
    };
    oldRxcuis.push(drugRxcui);
    checkInteraction(oldRxcuis);
  };

  const checkInteraction = async (allRxcuis: IDrugRxcui[]) => {
    let url = 'https://rxnav.nlm.nih.gov/REST/interaction/list.json?rxcuis=';
    let drugInteractList = '';

    if (allRxcuis.length < 2) {
      setInteractionState(0);
      return;
    }

    for (let j = 0; j < allRxcuis.length; j++) {
      url = `${url}+${allRxcuis[j].rxcui}`; //adds the drugs to the url //
      drugInteractList += '+' + allRxcuis[j].rxcui;
    }

    //call api
    setInteractionState(1);
    try {
      const response = await axios.get(url);
      const data = response.data;
      if (data.fullInteractionTypeGroup && data.fullInteractionTypeGroup.length > 0) {
        //checks if interaction exists and all drugs have rxcui
        setInteractionState(2);
        setInteractionLink(`/entry/drugInteractions?drugs=${drugInteractList}`);
      } else {
        setInteractionState(3);
      }
    } catch (error) {
      setInteractionState(0);

      console.error(error);
    }
  };

  /* end drug interaction here */

  const submit = async (event: any) => {
    //check is a patient is selected
    //check if drugs entered
    if (!selectedPatient || selectedPatient === null) {
      addToast('Please select a patient', 'error');
      return;
    }
    event.preventDefault();
    if (medications.length === 0) {
      addToast('Please enter atleast one medication', 'error');
      return;
    }
    event.preventDefault();
    const applicationState = appState as IAppState;

    const sendSmsElement = document.getElementById('send-reminder') as HTMLInputElement;

    //map object
    const medicationDispense: IMedicationDispense = {
      patientId: selectedPatient.patientId,
      patientName: selectedPatient.patientName,
      patientPhone: selectedPatient.phoneNumber,
      sendSms: sendSmsElement.checked,
      pharmacyId: applicationState.pharmacyId,
      countryCode: '234',
      drugs: medications,
    };

    //save
    const url = endpoints.entry.saveTransactions;
    setLoading(true);
    try {
      await axios.post(url, medicationDispense);

      setSelectedMedication(undefined);
      setSelectedPatient(undefined);
      medications.length = 0;
      setMedications([]);

      addToast('1 Message sent. Subsequent messages queued. Transaction successful', 'success');
    } catch (error) {
      addToast('An error occured', 'error');
    } finally {
      setLoading(false);
    }
    //show result
  };

  const medicationModal = (
    <MedicationModal
      close={toggleMedicationModal}
      show={showModal}
      addMedication={addMedication}
      selectedMedication={selectedMedication}
    />
  );

  const patientModal = (
    <PatientModal
      close={closePatientModal}
      show={showPatientModal}
      selectedPatient={selectedPatient}
      callback={patientModalCallback}
    />
  );

  return (
    <section className='col-md-10 content-section'>
      <PageTour
        steps={entrySteps}
        page='entry'
        run={localStorage.getItem('entryTour') === 'completed' ? false : true}
      />
      <h4>Entry</h4>
      <div className='row'>
        <section className='col-md-8 mx-auto' style={{ marginBottom: '10em' }}>
          <section className='row justify-content-center'>
            <div className='form-group col-md-8 '>
              <PatientSearch callback={patientSearchCallback} inputStyle='search-form-element' />
            </div>
            <div className='form-group col-md-4 d-grid gap-2'>
              <button className='btn btn-success btn-rounded' onClick={openPatientModal}>
                Add new Patient
              </button>
            </div>
            {patientModal}
          </section>
          <br />
          <fieldset disabled={loading}>
            <section className='row justify-content-center'>
              <div className='col-sm-12'>
                {selectedPatient ? (
                  <div className='card prescription-card'>
                    <div className='card-body'>
                      <div className='row'>
                        <h6 className='card-title col-10 col-sm-10'>
                          <span>Patient Name</span>:
                          <span className='ms-1 font-weight-bold'>
                            {selectedPatient.patientName}
                          </span>
                        </h6>
                        <div className='col-2 col-sm-2' title='Edit' onClick={editPatient}>
                          <span className='bi bi-pencil text-success me-3'></span>
                          Edit
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12 card-text prescription-card-text'>
                          <span>Phone Number</span>:
                          <span className='ms-1 font-weight-bold'>
                            {selectedPatient.phoneNumber}
                          </span>
                        </div>
                      </div>

                      <div className='row justify-content-center card-text prescription-card-text'>
                        <div className='col-12'>
                          <span>Patient ID</span>:
                          <span className='ms-1 font-weight-bold'>{selectedPatient.patientId}</span>
                        </div>
                        {/* <div className='col-6'>
                    <span>Registered</span>:
                    <span className='ms-1 font-weight-bold'>Jan 23 2021</span>
                  </div> */}
                      </div>

                      <div className='row justify-content-center card-text prescription-card-text mt-3'>
                        <div className='col-6'>
                          <button
                            className='btn btn-primary'
                            onClick={() => setShowDrugEntry(true)}
                          >
                            Dispense Medication
                          </button>
                        </div>
                        <div className='col-6 text-end'>
                          <Link
                            className='btn btn-success'
                            to={`/patients?id=${selectedPatient.patientId}`}
                          >
                            View Patient Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </section>
            <br />

            {selectedPatient && selectedPatient?.patientId > 0 ? (
              <section>
                {medicationModal}
                {showDrugEntry ? (
                  <div>
                    <section className='row justify-content-center'>
                      <h5 className='col-6 text-start mt-3'>Medications ({medications.length})</h5>
                      <div className='col-6 mt-2'>
                        <button
                          className='btn btn-primary float-end'
                          onClick={toggleMedicationModal}
                        >
                          Add Medication
                        </button>
                      </div>
                      {medications.map((medication) => (
                        <div className='col-sm-12' key={medication.medicationId}>
                          <div className='card prescription-card'>
                            <div className='card-body'>
                              <div className='row'>
                                <h6 className='card-title col-8 col-sm-10 font-weight-bold'>
                                  {medication.drugName}
                                </h6>
                                <div
                                  className='col-2 col-sm-1'
                                  onClick={() => editMedication(medication.medicationId)}
                                  title='Edit'
                                >
                                  <span className='bi bi-pencil text-success'></span>
                                </div>
                                <div
                                  className='col-2 col-sm-1'
                                  onClick={() => deleteMedication(medication.medicationId)}
                                  title='Delete'
                                >
                                  <span className='bi bi-trash text-danger'></span>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-12 card-text prescription-card-text'>
                                  {medication.drugDosage} {''}
                                  {medication.drugStrength} {''} {medication.drugFrequency} {''}
                                  {medication.drugInstructions}
                                </div>
                              </div>

                              <div className='row justify-content-center card-text prescription-card-text'>
                                <div className='col-sm-4'>
                                  <span>Type</span>:
                                  <span className='ms-1 font-weight-bold'>
                                    {medication.isChronic ? 'Chronic' : 'Acute'}
                                  </span>
                                </div>
                                <div className='col-sm-4'>
                                  <span>Duration</span>:
                                  <span className='ms-1 font-weight-bold'>
                                    {medication.prescribedDays} days
                                  </span>
                                </div>
                                <div className='col-sm-4'>
                                  <span>Confidential?</span>:
                                  <span className='ms-1 font-weight-bold'>
                                    {medication.isConfidential ? 'Yes' : 'No'}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </section>
                    {interactionState > 0 ? (
                      <section className='row justify-content-center'>
                        <div className='col-sm-12 text-center'>
                          <div className='card prescription-card'>
                            <div className='card-body'>
                              <div className='row'>
                                {interactionState === 1 && (
                                  <h6 className='card-title col-12 font-weight-bold text-primary'>
                                    Checking for interaction
                                  </h6>
                                )}

                                {interactionState === 2 && (
                                  <h6 className='card-title col-12 font-weight-bold text-danger'>
                                    Drug interactions were found for some drugs. Review them
                                    <a
                                      className='ms-1'
                                      href={interactionLink}
                                      target='_blank'
                                      rel='noreferrer'
                                    >
                                      here
                                    </a>
                                  </h6>
                                )}
                                {interactionState === 3 && (
                                  <h6 className='card-title col-12 font-weight-bold text-success'>
                                    No interactions found
                                  </h6>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    ) : (
                      ''
                    )}

                    {medications.length > 0 && selectedPatient.patientId > 0 ? (
                      <section className='row mt-3'>
                        <div className='col-12'>
                          <label className='ms-2'>
                            <input
                              name='send-reminder'
                              id='send-reminder'
                              type='checkbox'
                              style={{ width: '17px', height: '17px' }}
                              className='me-3'
                            />
                            Send reminder SMS
                          </label>
                        </div>
                        <div className='col-sm-6 mt-5 d-grid'>
                          <button
                            className='btn btn-primary btn-block btn-lg'
                            type='submit'
                            onClick={submit}
                          >
                            Save
                            {loading ? <ButtonLoader /> : ''}
                          </button>
                        </div>
                      </section>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
              </section>
            ) : (
              ''
            )}
          </fieldset>
        </section>
      </div>
    </section>
  );
};

export default EntryPage;
