import { Container, Row, Col } from 'react-bootstrap';
import stats from '../../../../assets/images/stats.png';
import { services } from '../constants';

const SalesComponent = () => {
  return (
    <Container fluid className='sales_container py-5 d-flex flex-column align-items-center'>
      <h2 className='text-center fs-4 fw-semibold mb-0'>Boost your revenue</h2>
      <p className='text-center fs-4 mb-2'>with WellaPartner</p>
      <img src={stats} alt='Stats' className='img-fluid' />

      <Container className='mb-3'>
        <Row className='justify-content-center'>
          <Col lg={6}>
            <p className='text-center fs-4 mt-2'>
              With our innovative solutions and strategic expertise, we empower businesses like
              yours to reach new heights of success.
            </p>
          </Col>
        </Row>
      </Container>

      <a
        href='https://forms.gle/YCPcR9CLVwFB3Rxi8'
        className='btn wellapartner px-4 py-2 d-flex align-items-center gap-3'
      >
        <span className='fs-5'>Get Started</span>
        <span className='material-symbols-outlined fs-5'>
          <i className='bi bi-arrow-right' />
        </span>
      </a>
      <Container className='m-5'>
        <Row className='justify-content-center'>
          {services.map((service, index) => (
            <Col
              key={index}
              xs={12}
              lg={4}
              className={`mb-${index === services.length - 1 ? '0' : '5'} text-center`}
            >
              <div className='d-flex gap-3 align-items-center'>
                <span className='fs-2'>
                  <img src={service.icon} alt={service.title} />
                </span>
                <p className='fw-bold mb-0'>{service.title}</p>
              </div>
              <p>{service.description}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default SalesComponent;
