import React, { useState, useRef } from 'react';
import { endpoints } from '../../utils/URL';
import axios from 'axios';
import { useOutsideAlerter } from './OutsideClick';
import { IDrugSearchBar } from '../../interface/MedicationEntryInterface';

const DrugSearch = ({ callback, inputStyle }: IDrugSearchBar) => {
  const [drugSearchResult, setDrugSearchResult] = useState<string[]>([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  let timer: NodeJS.Timeout;
  const axiosInstance = axios.create();

  const hideSearchResult = (event: any) => {
    setShowSearch(false);
  };

  const inputFocus = () => {
    setShowSearch(true);
  };

  /*To handle when we clck outside the search */

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, hideSearchResult);

  /* end click outside */

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputSearch = event.target.value;
    setSearchTerm(inputSearch);
    if (callback) {
      callback(inputSearch);
    }
  };

  const clearTimer = () => {
    clearTimeout(timer);
  };

  const startTimer = (event: any) => {
    clearTimeout(timer);
    let searchTerm = event.target.value;
    timer = setTimeout(() => searchDrugs(searchTerm), 1000);
  };

  const searchDrugs = async (searchTerm: string) => {
    if (searchTerm.length < 3) {
      return;
    }

    //show loading
    const loading = 'Loading...';
    const loadingArray = [loading];
    setDrugSearchResult(loadingArray);
    setShowSearch(true);

    const url = `${endpoints.drugs.getByName}/${searchTerm}`;

    try {
      const response = await axiosInstance.get(url); //use a new instance of axios to avoid showing full screen loading
      setDrugSearchResult(response.data);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const selectDrug = (selectedDrug: string) => {
    setSearchTerm(selectedDrug);
    setShowSearch(false);

    //callback set, call the callback passing the selected patient data
    if (callback) {
      callback(selectedDrug);
    }
  };

  return (
    <div className='form-group ' ref={wrapperRef} onFocus={inputFocus}>
      {/* onFocus={toggleSearchResult} onBlur={toggleSearchResult}> */}
      <span className='bi bi-search search-icon mt-1'></span>
      <input
        type='text'
        className={inputStyle ? 'form-control ps-5 pe-4 ' + inputStyle : 'form-control ps-5 pe-4'}
        placeholder='Search. Start typing drug name'
        onChange={handleChange}
        value={searchTerm}
        onKeyUp={startTimer}
        onKeyDown={clearTimer}
      />
      {showSearch && drugSearchResult.length > 0 ? (
        <div className='search-result-panel'>
          <ul style={{ marginLeft: '-30px', backgroundColor: 'white' }}>
            {drugSearchResult.map((drug) => (
              <li key={drug} className='search-result' onClick={() => selectDrug(drug)}>
                {drug}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default DrugSearch;
