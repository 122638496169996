export interface IPatientMessage {
  messageId: number;
  phoneNumber: string;
  deliveryStatus: string;
  messageContent: string;
  queueDate: string;
}

export interface IMessageSearchParams {
  phoneNumber: string;
  startDate: string;
  endDate: string;
  status: string;
}

export interface IMessaging {
  pharmacyId: number;
  messageContent: string;
  phoneNumbers: Array<string>;
  sendLater: boolean;
  sendTime: string;
  sendDate: string;
  meridian: string;
}

export interface IMessageCount {
  pageCount: number;
  totalCount: number;
  characterCount: number;
}

export const defaultMessageCount: IMessageCount = {
  pageCount: 1,
  totalCount: 1,
  characterCount: 0,
};

export const defaultMessaging: IMessaging = {
  pharmacyId: 0,
  messageContent: '',
  phoneNumbers: [],
  sendLater: false,
  sendTime: '09:00',
  sendDate: new Date().toISOString().split('T')[0],
  meridian: 'AM',
};
