export interface IPharmacy {
  pharmacyId: number;
  pharmacyCode: string;
  pharmacyName: string;
  startDate: string;
  email: string;
  sender: string;
  smsprovider: string;
  defaultAcuteDays: number;
  smsBalance: number;
  expiryDate: string;
  phone: string;
  contacts: string;
  countryCode: string;
  stateOfPremise: string;
  lga: string;
  acquisitionChannel: string;
  package: string;
  clientId?: number;
  locationArea: string;
  pharmacyAddress: string;
  locationLongitude: string;
  locationLatitude: string;
  useGateway: boolean;
  dateCreated: string;
  status: string;
  relationshipOfficer: string;
}

export const defaultPharmacy: IPharmacy = {
  pharmacyId: 0,
  pharmacyCode: '',
  pharmacyName: '',
  startDate: new Date().toISOString(),
  email: '',
  sender: 'WellaHealth',
  smsprovider: 'africastalking',
  defaultAcuteDays: 3,
  smsBalance: 0,
  expiryDate: new Date().toISOString(),
  phone: '',
  contacts: '',
  countryCode: '234',
  stateOfPremise: 'Abia',
  lga: '',
  useGateway: true,
  acquisitionChannel: 'Web',
  package: 'Bronze',
  clientId: 0,
  locationArea: '',
  pharmacyAddress: '',
  locationLongitude: '',
  locationLatitude: '',
  dateCreated: '',
  status: 'Active',
  relationshipOfficer: '',
};

export interface IPharmacyModal {
  show: boolean;
  close: () => void;
  pharmacy: IPharmacy;
  callback?: () => void;
  handleChange?: Function;
}

export interface IPharmacyListDTO {
  pharmacyId: number;
  pharmacyName: string;
  pharmacyCode: string;
  clientId?: number;
  address: string;
}

export interface IPharmacyClient {
  clientId: number;
  clientName: string;
}
export const defaultPharmacyList: IPharmacyListDTO = {
  pharmacyId: 0,
  pharmacyName: '',
  pharmacyCode: '',
  address: '',
};
