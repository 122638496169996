export interface IPasswordChange {
  userId: number;
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
}

export const initialPasswordChange: IPasswordChange = {
  userId: 0,
  oldPassword: '',
  newPassword: '',
  repeatPassword: '',
};
