import React, { useContext } from 'react';
import Header from './Header';
import SideMenu from './SideMenu';
import { Redirect, Route } from 'react-router-dom';
import { IPrivateRouteProps } from '../../interface/AppInterface';
import { AppContext } from '../../context/AppContext';
import { Roles } from '../../utils/mappings';
import ScreenLoading from '../common/ScreenLoading';

const AdminLayout = ({ component: Component, ...rest }: IPrivateRouteProps) => {
  const { appState } = useContext(AppContext);
  const isAuthenticated: boolean = appState.isAuthenticated;

  const isAdmin = () => {
    const userRole: string = appState.entitlements.role;
    if (userRole === '' || userRole === Roles.Staff) {
      return false;
    }
    return true;
  };
  return (
    <div>
      <ScreenLoading />
      <Header />
      <div className='container-fluid'>
        <span
          id='request-spinner'
          className='spinner-border spinner-border-sm text-success request-spinner d-none'
          role='status'
          aria-hidden='true'
        ></span>

        <div className='row'>
          <SideMenu />

          <Route
            {...rest}
            render={(props) =>
              isAuthenticated && isAdmin() ? (
                <main
                  role='main'
                  id='main-view'
                  className='col-md-9 ms-sm-auto col-lg-10 px-md-4'
                  style={{ marginTop: '30px', marginBottom: '100px' }}
                >
                  <Component {...props} />
                </main>
              ) : (
                <Redirect to='/entry' />
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
