// import React from 'react'

const Stars = ({ count, className }: { count: number; className?: string }) => {
  const starsArray = Array.from({ length: count });

  return (
    <div className={className}>
      {starsArray.map((_, index) => (
        <i key={index} className='bi bi-star-fill'></i>
      ))}
    </div>
  );
};

export default Stars;
