export interface IUserDTO {
  userId: number;
  username: string;
  phone: string;
  isActive: boolean;
  role: string;
  pharmacyId: number;
}

export interface IUserModal {
  show: boolean;
  close: () => void;
  user: IUserDTO;
  callback?: () => void;
  handleChange: Function;
}

export const defaultUser: IUserDTO = {
  username: '',
  role: 'Staff',
  phone: '',
  userId: 0,
  isActive: false,
  pharmacyId: 0,
};
