//https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
let fileTypes = ['text/plain', 'text/csv', 'application/vnd.ms-excel'];

export const validFileType = (file: any) => {
  for (let i = 0; i < fileTypes.length; i++) {
    if (file.type === fileTypes[i]) {
      return true;
    }
  }

  return false;
};

export const returnFileSize = (sizeInBytes: number) => {
  if (sizeInBytes < 1024) {
    return sizeInBytes + 'bytes';
  } else if (sizeInBytes >= 1024 && sizeInBytes < 1048576) {
    return (sizeInBytes / 1024).toFixed(1) + 'KB';
  } else if (sizeInBytes >= 1048576) {
    return (sizeInBytes / 1048576).toFixed(1) + 'MB';
  }
};
