import axios from 'axios';
import React, { FormEvent, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';
import { IPatientModal } from '../../../interface/CommonInterface';
import { defaultHealthDetail, IHealthDetail } from '../../../interface/HealthDetailInterface';
import { BloodGroup } from '../../../utils/mappings';

const HealthDetailModal = ({ show, close, patientId, callback }: IPatientModal) => {
  const [isLoading, setIsLoading] = useState(false);
  const [healthInfo, setHealthInfo] = useState<IHealthDetail>(defaultHealthDetail);

  const handleChange = (input: string) => (event: any) => {
    setHealthInfo({
      ...healthInfo,
      [input]: event.target.value,
    });
  };

  const saveReading = async (event: FormEvent) => {
    if (!isFormValidated('add-hd-modal')) {
      addToast('Please fill all fields', 'warning');
      return;
    }
    event.preventDefault();
    healthInfo.patientId = patientId;

    setIsLoading(true);
    const url = `${endpoints.healthDetails.mainUrl}`;

    try {
      await axios.post(url, healthInfo);
      addToast('Health detail added successfully', 'success');
      if (callback) {
        callback();
      }
      close();
    } catch (error) {
      addToast('An error occured while saving data', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={close} backdrop={isLoading ? 'static' : true}>
      <form id='add-hd-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Add Health Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={isLoading}>
            <div className='form-group'>
              <label className='required'>Height (cm)</label>
              <input
                type='number'
                className='form-control'
                value={healthInfo.height}
                onChange={handleChange('height')}
                required
                min={20}
                max={250}
                step={0.01}
              />
            </div>
            <div className='form-group mt-4'>
              <label className='required'>Weight (kg)</label>
              <input
                type='number'
                className='form-control'
                value={healthInfo.weight}
                onChange={handleChange('weight')}
                required
                min={1}
                max={250}
                step={0.01}
              />
            </div>
            <div className='form-group mt-4'>
              <label className='required'>Blood group</label>
              <select
                className='form-select'
                value={healthInfo.bloodGroup}
                onClick={handleChange('bloodGroup')}
                required
              >
                {BloodGroup.map((data) => (
                  <option key={data.key} value={data.value}>
                    {data.text}
                  </option>
                ))}
              </select>
            </div>
            <div className='form-group mt-4'>
              <label>Allergies</label>
              <textarea
                className='form-control'
                value={healthInfo.allergies}
                onChange={handleChange('allergies')}
              />
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-grid col-12'>
            <button className='btn btn-primary' type='submit' onClick={saveReading}>
              Save
              {isLoading ? <ButtonLoader /> : ''}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default HealthDetailModal;
