// import React from 'react';
import { Container } from 'react-bootstrap';
import check from "../../../../assets/images/icons/check.svg";
import white from "../../../../assets/images/icons/whitegood.svg";
import whitebad from "../../../../assets/images/icons/whitebad.svg";
import bad from "../../../../assets/images/icons/blackbad.svg";

const PlanComparison = () => {
  return (
    <Container className='my-5 pt-5'>
      <h3 className='text-center display-6 mb-2 fs-4 fw-semibold'>Compare plans & features</h3>
      <p className='text-center fs-5'>Overview of what's included in our different plans.</p>

		<div className='d-none d-lg-block'>
	  <table className='table table-borderless'>
		<thead>
			<tr>
				<th className='text-center invisible'>Wellapartner Features</th>
				<th className='text-center'>Wellapartner Basic</th>
				<th className='text-center'>Wellapartner Standard</th>
				<th className='text-center rounded-top-3' style={{backgroundColor: '#033', color: 'white'}}>Wellapartner Business</th>
				<th className='text-center'>Wellapartner Premium</th>
			</tr>
			<tr>
				<th className='text-center invisible'>0month</th>
				<th className='text-center'>1000/month</th>
				<th className='text-center'>2500/month</th>
				<th className='text-center' style={{backgroundColor: '#033', color: 'white'}}>5000/month</th>
				<th className='text-center'>7500/month</th>
			</tr>
			<tr>
				<th className='text-center invisible'><button className='btn btn-secondary'>Get Started</button></th>
				<th className='text-center'><button className='btn btn-secondary'>Get Started</button></th>
				<th className='text-center'><button className='btn btn-secondary'>Get Started</button></th>
				<th className='text-center' style={{backgroundColor: '#033'}}><button className='btn wellapartner text-white'>Get Started</button></th>
				<th className='text-center'><button className='btn btn-secondary'>Get Started</button></th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td className='text-center'>HMO Drug Refills</td>
				<td className='text-center'><img src={check} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
				<td className='text-center' style={{backgroundColor: '#033'}}><img src={white} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
			</tr>
			<tr>
				<td className='text-center'>Handle Malaria Claims</td>
				<td className='text-center'><img src={check} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
				<td className='text-center' style={{backgroundColor: '#033'}}><img src={white} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
			</tr>
			<tr>
				<td className='text-center'>Wellapartner App Access</td>
				<td className='text-center'><img src={check} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
				<td className='text-center' style={{backgroundColor: '#033'}}><img src={white} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
			</tr>
			<tr>
				<td className='text-center'>Drug Pick Ups for Telemedicne</td>
				<td className='text-center'><img src={check} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
				<td className='text-center' style={{backgroundColor: '#033'}}><img src={white} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
			</tr>
			<tr>
				<td className='text-center'>Patient Records Tracking</td>
				<td className='text-center'><img src={check} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
				<td className='text-center' style={{backgroundColor: '#033'}}><img src={white} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
			</tr>
			<tr>
				<td className='text-center'>Business Support/Staff Training</td>
				<td className='text-center'><img src={check} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
				<td className='text-center' style={{backgroundColor: '#033'}}><img src={white} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
			</tr>
			<tr>
				<td className='text-center'>Pharmacy listing on Wella Platform</td>
				<td className='text-center'><img src={check} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
				<td className='text-center' style={{backgroundColor: '#033'}}><img src={white} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
			</tr>
			<tr>
				<td className='text-center'>Patient access to telemedicine services</td>
				<td className='text-center'><img src={check} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
				<td className='text-center' style={{backgroundColor: '#033'}}><img src={white} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
			</tr>
			<tr>
				<td className='text-center'>Bulk SMS</td>
				<td className='text-center'><img src={bad} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
				<td className='text-center' style={{backgroundColor: '#033'}}><img src={white} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
			</tr>
			<tr>
				<td className='text-center'>Automated Patient Follow up</td>
				<td className='text-center'><img src={bad} alt="" /></td>
				<td className='text-center'><img src={bad} alt="" /></td>
				<td className='text-center' style={{backgroundColor: '#033'}}><img src={white} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
			</tr>
			<tr>
				<td className='text-center'>Chronic Disease Management Dasboard</td>
				<td className='text-center'><img src={bad} alt="" /></td>
				<td className='text-center'><img src={bad} alt="" /></td>
				<td className='text-center' style={{backgroundColor: '#033'}}><img src={white} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
			</tr>
			<tr>
				<td className='text-center'>Medication Group Purchase</td>
				<td className='text-center'><img src={bad} alt="" /></td>
				<td className='text-center'><img src={bad} alt="" /></td>
				<td className='text-center' style={{backgroundColor: '#033'}}><img src={whitebad} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
			</tr>
			<tr>
				<td className='text-center'>Inventory Insights</td>
				<td className='text-center'><img src={bad} alt="" /></td>
				<td className='text-center'><img src={bad} alt="" /></td>
				<td className='text-center' style={{backgroundColor: '#033'}}><img src={whitebad} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
			</tr>
			<tr>
				<td className='text-center'>Marketing Support</td>
				<td className='text-center'><img src={bad} alt="" /></td>
				<td className='text-center'><img src={bad} alt="" /></td>
				<td className='text-center' style={{backgroundColor: '#033'}}><img src={whitebad} alt="" /></td>
				<td className='text-center'><img src={check} alt="" /></td>
			</tr>
		</tbody>
	  </table>
	  </div>

	  
      {/* Mobile View */}
	  <div className='d-lg-none'>
		<table className='table table-borderless'>
			<thead>
				<tr>
					<th className='text-center'><button className='btn btn-secondary'>Basic</button></th>
					<th className='text-center'><button className='btn btn-secondary'>Standard</button></th>
					<th className='text-center'><button className='btn wellapartner'>Business</button></th>
					<th className='text-center'><button className='btn btn-secondary'>Premium</button></th>
				</tr>
			</thead>
			<tbody>
				<tr className='mb-3'>
					<td colSpan={4} className='text-center'>HMO Drug Refills</td>
				</tr>
				<tr className='mb-3'>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
				</tr>
				<tr className='mb-3'>
					<td colSpan={4} className='text-center'>Handle Malaria Claims</td>
				</tr>
				<tr className='mb-3'>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
				</tr>
				<tr className='mb-3'>
					<td colSpan={4} className='text-center'>Wellapartner App Access</td>
				</tr>
				<tr className='mb-3'>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
				</tr>
				<tr className='mb-3'>
					<td colSpan={4} className='text-center'>Drug Pick Ups for Telemedicne</td>
				</tr>
				<tr className='mb-3'>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
				</tr>
				<tr className='mb-3'>
					<td colSpan={4} className='text-center'>Patient Records Tracking</td>
				</tr>
				<tr className='mb-3'>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
				</tr>
				<tr className='mb-3'>
					<td colSpan={4} className='text-center'>Business Support/Staff Training</td>
				</tr>
				<tr className='mb-3'>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
				</tr>
				<tr className='mb-3'>
					<td colSpan={4} className='text-center'>Pharmacy listing on Wella Platform</td>
				</tr>
				<tr className='mb-3'>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
				</tr>
				<tr className='mb-3'>
					<td colSpan={4} className='text-center'>Patient access to telemedicine services</td>
				</tr>
				<tr className='mb-3'>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
				</tr>
				<tr className='mb-3'>
					<td colSpan={4} className='text-center'>Bulk SMS</td>
				</tr>
				<tr className='mb-3'>
					<td className='text-center'><img src={bad} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
				</tr>
				<tr className='mb-3'>
					<td colSpan={4} className='text-center'>Automated Patient Follow up</td>
				</tr>
				<tr className='mb-3'>
					<td className='text-center'><img src={bad} alt="" /></td>
					<td className='text-center'><img src={bad} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
				</tr>
				<tr className='mb-3'>
					<td colSpan={4} className='text-center'>Chronic Disease Management Dasboard</td>
				</tr>
				<tr className='mb-3'>
					<td className='text-center'><img src={bad} alt="" /></td>
					<td className='text-center'><img src={bad} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
				</tr>
				<tr className='mb-3'>
					<td colSpan={4} className='text-center'>Medication Group Purchase</td>
				</tr>
				<tr className='mb-3'>
					<td className='text-center'><img src={bad} alt="" /></td>
					<td className='text-center'><img src={bad} alt="" /></td>
					<td className='text-center'><img src={bad} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
				</tr>
				<tr className='mb-3'>
					<td colSpan={4} className='text-center'>Inventory Insights</td>
				</tr>
				<tr className='mb-3'>
					<td className='text-center'><img src={bad} alt="" /></td>
					<td className='text-center'><img src={bad} alt="" /></td>
					<td className='text-center'><img src={bad} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
				</tr>
				<tr className='mb-3'>
					<td colSpan={4} className='text-center'>Marketing Support</td>
				</tr>
				<tr className='mb-3'>
					<td className='text-center'><img src={bad} alt="" /></td>
					<td className='text-center'><img src={bad} alt="" /></td>
					<td className='text-center'><img src={bad} alt="" /></td>
					<td className='text-center'><img src={check} alt="" /></td>
				</tr>
			</tbody>
		</table>
	  </div>
    </Container>
  );
};

export default PlanComparison;
