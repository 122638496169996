import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import { Roles } from '../../utils/mappings';
import logo from '../../assets/images/logo.svg';

const AuthenticatedHeader = () => {
  const { appState, dispatch } = useContext(AppContext);
  const isAdmin = () => {
    const userRole: string = appState.entitlements.role;
    if (userRole === '' || userRole === Roles.Staff) {
      return false;
    }
    return true;
  };

  const logout = () => {
    dispatch({
      type: 'LOG_OUT',
    });
  };

  return (
    <header>
      {/* <PageTour
        steps={headerSteps}
        page='header'
        run={localStorage.getItem('headerTour') === 'completed' ? false : true}
      /> */}
      <nav className='navbar navbar-expand-lg navbar-light'>
        <div className='container'>
          <div className='col-md-3'>
            <Link className='navbar-brand' to='/entry'>
              <img className='logo-header img-fluid' alt='logo' src={logo} />
            </Link>
            <button
              className='navbar-toggler float-end'
              type='button'
              data-toggle='collapse'
              data-target='#topmenu'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span
                className='navbar-toggler-icon'
                data-toggle='collapse'
                data-target='#topmenu'
              ></span>
            </button>
          </div>
          <div className='col-md-9'>
            <div
              id='topmenu'
              className='mt-3 mt-sm-0 collapse navbar-collapse float-md-end float-start'
            >
              <ul className='navbar-nav me-auto mt-2 mt-lg-0'>
                <li className='nav-item active entry'>
                  <Link className='nav-link' to='/entry'>
                    ENTRY
                  </Link>
                </li>
                {/* <li className='nav-item '>
                  <Link className='nav-link' to='/dashboard'>
                    DASHBOARD
                  </Link>
                </li> */}
                <li className='nav-item wallets'>
                  <Link className='nav-link' to='/wallets'>
                    WALLETS
                  </Link>
                </li>
                <li className='nav-item settings'>
                  <Link className='nav-link' to='/settings'>
                    SETTINGS
                  </Link>
                </li>
                {isAdmin() ? (
                  <li className='nav-item admin'>
                    <Link className='nav-link' to='/admin/pharmacy/details'>
                      ADMIN
                    </Link>
                  </li>
                ) : (
                  ''
                )}
                <li className='nav-item'>
                  <strong className='nav-link'>{appState.pharmacyName}</strong>
                </li>
                {appState.pharmacyName.toLowerCase().includes('medplus') ? (
                  <Link className='nav-item align-items-middle' to='/entry'>
                    <img
                      className='img-fluid'
                      alt='logo'
                      src={require('../../assets/images/medplus.png').default}
                      width='82'
                    />
                  </Link>
                ) : (
                  ''
                )}
                <li className='nav-item mt-3 mt-sm-0' title='Logout'>
                  <button className='btn btn-outline-success' onClick={logout}>
                    <span className='bi bi-box-arrow-right'></span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default AuthenticatedHeader;
