import { useContext, useEffect, useState } from 'react';
import Loader from '../../../common/Loader';
import { AppContext } from '../../../../context/AppContext';
import { IAppState } from '../../../../interface/AppInterface';
import WalletFragment from '../../wallets/WalletFragment';

const AdminWallet = () => {
  const { appState }: { appState: IAppState } = useContext(AppContext);
  const [pharmacyCode, setPharmacyCode] = useState('');
  const [clientId, setClientId] = useState(0);

  useEffect(() => {
    let clientId = appState.selectedPharmacy.clientId;
    let pharmacyCode = appState.selectedPharmacy.pharmacyCode;
    setPharmacyCode(pharmacyCode ?? '');
    setClientId(clientId ?? 0);
  }, [appState.selectedPharmacy]);

  let walletFragment = <WalletFragment pharmacyCode={pharmacyCode} clientId={clientId} />;

  return (
    <div className='container'>
      {pharmacyCode !== '' && clientId > 0 ? walletFragment : <Loader />}
    </div>
  );
};

export default AdminWallet;
