import { Container, Row, Col } from 'react-bootstrap';
import management from '../../../../assets/images/management.png';
import { offerings } from '../constants';

const ManagementComponent = () => {
  return (
    <div className='bg-white' id='features'>
      <Container fluid className='management_container py-5 d-flex flex-column align-items-center'>
        <p className='text-center fs-4 mb-2'>Better Patient </p>
        <h2 className='text-center fs-4 fw-semibold mb-4'>Management & Care</h2>
        <Col xs={12} md={6}>
          <img src={management} alt='Stats' className='img-fluid' />
        </Col>

        <Container className='mb-3'>
          <Row className='justify-content-center'>
            <Col lg={6}>
              <p className='text-center fs-4 mt-2'>
                Our platform has redefined the way you interact with patients, ensuring a seamless,
                personalized, and efficient experience.
              </p>
            </Col>
          </Row>
        </Container>

        <button className='btn wellapartner px-4 py-2 d-flex align-items-center gap-3'>
          <span className='fs-5'>Get Started</span>
          <span className='material-symbols-outlined fs-5'>
            <i className='bi bi-arrow-right' />
          </span>
        </button>

        <Container className='m-5'>
          <Row className='justify-content-center'>
            {offerings.map((offering, index) => (
              <Col
                key={index}
                xs={12}
                lg={4}
                className={`mb-${index === offerings.length - 1 ? '0' : '5'}`}
              >
                <div className='d-flex flex-column align-items-center align-items-md-start'>
                  <div className='d-flex align-items-center gap-2 my-3'>
                    <img src={offering.icon} alt='' />
                    <p className='fw-bold m-0'>{offering.title}</p>
                  </div>

                  <p className='text-center text-md-start'>{offering.description}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default ManagementComponent;
