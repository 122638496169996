export interface IMedication {
  drugId: number;
  startDate: string;
  endDate: string;
  drugName: string;
  dose: string;
  duration: number;
  frequency: number;
  strength: number;
  chronic: boolean;
  patientId: number;
  diseaseTag: string;
  dateCreated: string;
  dateUpdated: string;
}

export interface IPatientBasicInfo {
  patientName: string;
  phoneNumber: string;
  bloodGroup: string;
  dateOfBirth: string;
  height: number;
  weight: number;
}

export interface IPatientData {
  pharmacyId: number;
  patientId: number;
  patientName: string;
  phone: string;
  countryCode: string;
  dateOfBirth: string;
  email: string;
  patientAddress: string;
  dateCreated: string;
}

export interface IPatientModal {
  show: boolean;
  close: () => void;
  selectedPatient: IPatientSearchResult | undefined;
  callback?: Function;
}

export interface IPatientSearchResult {
  patientId: number;
  patientName: string;
  phoneNumber: string;
}

export interface IPatientTabProps {
  patientId: number;
  tabKey: string;
}

export interface IPatientSearchBar {
  callback?: (patient: IPatientSearchResult) => void;
  inputStyle?: string;
}

export interface IPatientCondition {
  diseaseType: string;
  diseaseCount: number;
}

export interface IPatientUploadData {
  patientName: string;
  phoneNumber: string;
  email: string;
  dateOfBirth: string;
  gender: string;
  address: string;
  isDataValid: boolean;
  reason: string;
}

export const defaultPatientData: IPatientData = {
  pharmacyId: 0,
  patientId: 0,
  patientName: '',
  phone: '',
  countryCode: '234',
  dateOfBirth: '',
  email: '',
  patientAddress: '',
  dateCreated: '',
};
