import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';

const HeaderLogo = () => {
  const { appState } = useContext(AppContext);

  return (
    <nav className='navbar navbar-light sticky-top flex-md-nowrap p-0 shadow navbar-custom'>
      <div className='' style={{ margin: '10px 0px 15px 70px' }}>
        <img
          src={require('../../assets/images/wellahealth.svg').default}
          alt='wellahealth'
          width='100'
        />
      </div>
      <div className='me-2'>
        {appState.selectedPharmacy.pharmacyId !== 0 ? (
          appState.selectedPharmacy.pharmacyName
        ) : (
          <span className='text-danger'>No Pharmacy Selected</span>
        )}
      </div>
      <button
        // className='navbar-toggler position-absolute d-md-none collapsed'
        className='navbar-toggler position-absolute collapsed'
        type='button'
        data-toggle='collapse'
        data-target='#sidebarMenu'
        aria-controls='sidebarMenu'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span
          className='navbar-toggler-icon'
          data-toggle='collapse'
          data-target='#sidebarMenu'
        ></span>
      </button>
    </nav>
  );
};

export default HeaderLogo;
