import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import { IMenuItem } from '../../../interface/AppInterface';
import {
  defaultMessageCount,
  defaultMessaging,
  IMessageCount,
} from '../../../interface/MessageInterface';
import { IPatientCondition, IPatientSearchResult } from '../../../interface/PatientInterface';
import { calculateMessageCount, isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import ButtonLoader from '../../common/ButtonLoader';

const Messaging = () => {
  const [patients, setPatients] = useState<IPatientSearchResult[]>([]);
  const [allPatients, setAllPatients] = useState<IPatientSearchResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);
  const [messageCount, setMessageCount] = useState<IMessageCount>(defaultMessageCount);
  const { appState } = useContext(AppContext);
  const [messaging, setMessaging] = useState(defaultMessaging);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [conditions, setConditions] = useState<IPatientCondition[]>([]);
  const [selectedCondition, setSelectedCondition] = useState('All');

  const pharmacyId: number = appState.pharmacyId;
  const appMenuItems: IMenuItem = appState.menuItems as IMenuItem;

  const loadPatients = useCallback(async () => {
    let pageIndex = 0;
    let pageCount = 1;

    let allData: IPatientSearchResult[] = [];

    setLoading(true);
    while (pageCount > pageIndex) {
      pageIndex += 1;

      const url = `${endpoints.patients.mainUrl}/pharmacy/${pharmacyId}?pageIndex=${pageIndex}&pageSize=100`;

      try {
        const response = await axios.get(url);

        const data = response.data;
        allData = allData.concat(data.data);

        pageCount = data.pageCount;
        setPatients(allData);
        setAllPatients(allData);
        checkboxesToggle(false);
      } catch (error) {
        addToast('Error loading patients', 'error');
      }
    }
    setPatients(allData);
    setAllPatients(allData);
    setLoading(false);
  }, [pharmacyId]);

  const loadConditions = useCallback(async () => {
    const url = `${endpoints.patientDrugs.mainUrl}/conditions/${pharmacyId}`;

    try {
      const response = await axios.get(url);
      const data = response.data;
      setConditions(data);
    } catch (error) {
      addToast('Error loading patients', 'error');
    } finally {
      setLoading(false);
    }
  }, [pharmacyId]);

  const filterPatients = (event: any) => {
    const searchParam = event.target.value;
    checkboxesToggle(false);
    const filteredPatient = allPatients.filter(
      (x) =>
        x.patientName.toLowerCase().includes(searchParam.toLowerCase()) ||
        x.phoneNumber.toLowerCase().includes(searchParam.toLowerCase())
    );

    setPatients(filteredPatient);
  };

  const selectSingle = (event: any) => {
    const checked = event.target.checked;
    const value = event.target.value;
    //if checked
    //add phone number to array if not exist
    if (checked) {
      //if not duplicate
      const numberExists = phoneNumbers.filter((x) => x.trim() === value.trim());

      if (numberExists.length === 0) {
        //https://reactjs.org/docs/hooks-reference.html#functional-updates
        setPhoneNumbers((phoneNumbers) => [...phoneNumbers, value]);
      }
    }
    //if unchecked
    //remove phone number from array
    else {
      const updatedPhoneNumbers = phoneNumbers.filter((x) => x.trim() !== value.trim());
      setPhoneNumbers(updatedPhoneNumbers);
    }
  };

  const selectAll = (event: any) => {
    const checked = event.target.checked;

    //if checked.
    //add all phone numbers to array
    //uncheck all single checkboxes
    const checkboxes = document.getElementsByClassName('single-check');
    const conditionDropdown = document.getElementById('patient-condition') as HTMLSelectElement;
    const searchParam = document.getElementById('search-param') as HTMLInputElement;

    const selectedNumbers = patients.map((x) => {
      return x.phoneNumber;
    });

    if (checked) {
      if (conditionDropdown.value === 'all' && searchParam.value === '') {
        setPhoneNumbers(selectedNumbers);
      } else {
        const existingPhoneNumbers = phoneNumbers;
        const combinedPhoneNumbers = existingPhoneNumbers.concat(selectedNumbers);
        setPhoneNumbers(combinedPhoneNumbers);
      }

      //check all boxes
      for (let i = 0; i < checkboxes.length; i++) {
        const checkbox = checkboxes[i] as HTMLInputElement;
        checkbox.checked = true;
      }
    }
    //remove all phone numbers
    //clear all checkboxes
    else {
      if (conditionDropdown.value === 'all' && searchParam.value === '') {
        setPhoneNumbers([]);
      } else {
        const existingPhoneNumbers = phoneNumbers;

        const combinedPhoneNumbers = existingPhoneNumbers.filter(
          (el) => !selectedNumbers.includes(el)
        );
        setPhoneNumbers(combinedPhoneNumbers);
      }

      for (let i = 0; i < checkboxes.length; i++) {
        const checkbox = checkboxes[i] as HTMLInputElement;
        checkbox.checked = false;
      }
    }
  };

  const checkboxesToggle = (checked: boolean) => {
    const checkboxes = document.getElementsByClassName('single-check');
    const allCheck = document.getElementById('all-check') as HTMLInputElement;
    if (checked) {
      //check all boxes
      for (let i = 0; i < checkboxes.length; i++) {
        const checkbox = checkboxes[i] as HTMLInputElement;
        checkbox.checked = true;
      }
      allCheck.checked = true;
    }
    //remove all phone numbers
    //clear all checkboxes
    else {
      for (let i = 0; i < checkboxes.length; i++) {
        const checkbox = checkboxes[i] as HTMLInputElement;
        checkbox.checked = false;
      }
      allCheck.checked = false;
    }
  };

  const handleChange = (inputName: string) => (event: any) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setMessaging({
      ...messaging,
      [inputName]: value,
    });

    if (inputName === 'messageContent') {
      const countCalc = calculateMessageCount(value.length);
      setMessageCount(countCalc);
    }
  };

  const sendMessage = async (event: any) => {
    if (!isFormValidated('messaging-form')) {
      return;
    }
    event.preventDefault();

    if (phoneNumbers.length === 0) {
      addToast('Please select atleast one recepient', 'warning');
      return;
    }

    event.preventDefault();

    //convert time to 24 hours if not already
    const timeParts = messaging.sendTime.split(':');
    let hours = parseInt(timeParts[0].trim());
    if (messaging.meridian === 'PM') {
      hours += +12;
    }
    const sendTime = `${hours}:${timeParts[1]}`;

    messaging.pharmacyId = pharmacyId;
    messaging.sendTime = sendTime;
    messaging.phoneNumbers = phoneNumbers;

    const url = endpoints.messaging.mainUrl;
    setBtnLoading(true);
    //
    try {
      await axios.post(url, messaging);
      addToast('Messages sent successfully', 'success');
      setMessaging(defaultMessaging);
      setPhoneNumbers([]);
      phoneNumbers.length = 0;
    } catch (error) {
      addToast('Messages could not be sent', 'error');
    } finally {
      setBtnLoading(false);
    }
  };

  const changeCondition = async (event: any) => {
    const condition = event.target.value;
    setSelectedCondition(event.target.value);

    if (condition === 'all') {
      await loadPatients();
      return;
    }

    const url = `${endpoints.patients.mainUrl}/condition/${pharmacyId}?diseaseType=${condition}`;
    setLoading(true);

    try {
      const response = await axios.get(url);

      const data = response.data;
      setPatients(data);
      setAllPatients(data);
      checkboxesToggle(false);
    } catch (error) {
      addToast('Error loading patients', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadPatients();
    loadConditions();
  }, [loadPatients, loadConditions]);

  return (
    <div className='col-md-10 content-section'>
      <h4>Messaging</h4>
      <div className='row mt-5'>
        <div className='col-sm-4'>
          <div style={{ maxHeight: '600px', overflow: 'scroll' }}>
            <div className='mb-4'>
              <label>Load Phone Numbers</label>
              <select
                className='form-select'
                onChange={changeCondition}
                value={selectedCondition}
                id='patient-condition'
              >
                <option value={'all'}>All</option>
                {conditions.map((condition, key) => (
                  <option key={key} value={condition.diseaseType}>
                    {condition.diseaseType} - ({condition.diseaseCount})
                  </option>
                ))}
              </select>
            </div>
            <label className='card-body patient-message-card'>
              <input type='checkbox' id='all-check' name='all-check' onChange={selectAll} />
              <strong className='ms-2'>Select all {patients.length} recepients</strong>
            </label>
            {loading ? (
              <div>
                Loading patient list...
                <ButtonLoader />
              </div>
            ) : (
              ''
            )}
            <div className='mt-3'>
              <input
                type='search'
                placeholder='Filter by name or number'
                className='form-control '
                onChange={filterPatients}
                id='search-param'
              />
            </div>
            {patients.map((patient, key) => (
              <div key={key} className='card prescription-card mt-3'>
                <label className='card-body patient-message-card'>
                  <input
                    type='checkbox'
                    name='single-check'
                    value={patient.phoneNumber}
                    onChange={selectSingle}
                    className='single-check'
                  />
                  <strong className='ms-2'>{patient.patientName}</strong>
                  <div className='ms-3'>{patient.phoneNumber}</div>
                </label>
              </div>
            ))}
          </div>
        </div>
        {/* section to load drugs */}
        <div
          className='col-sm-8 mb-5'
          style={{ borderLeft: '2px solid black', maxHeight: '600px' }}
        >
          <form id='messaging-form'>
            <fieldset disabled={isBtnLoading}>
              <div className=' form-group mt-3'>
                <label className='control-label required'>Message</label>
                <textarea
                  className='form-control'
                  value={messaging.messageContent}
                  rows={5}
                  required
                  onChange={handleChange('messageContent')}
                ></textarea>
                <div className='text-end'>
                  {messageCount.characterCount}/{messageCount.totalCount} [{messageCount.pageCount}]
                </div>
              </div>
              <div className='form-group mt-3'>
                <label className='control-label'>
                  Unique Recepients
                  <strong className='ms-2'>{phoneNumbers.length}</strong>
                </label>
                <textarea
                  className='form-control'
                  rows={5}
                  disabled
                  value={phoneNumbers.join(',')}
                ></textarea>
                <div className='mt-3'>
                  Total Cost of Message:
                  <strong className='me-4 ms-1'>
                    {phoneNumbers.length * messageCount.pageCount}
                  </strong>
                  Credits:
                  <strong className='me-3 ms-1'>{appMenuItems.smsUnits}</strong>
                </div>
              </div>
              <div className='form-group mt-4'>
                <div className='col-md-'>
                  <label className='me-2'>
                    Send Later
                    <input
                      type='checkbox'
                      checked={messaging.sendLater}
                      className='ms-2 custom-checkbox'
                      onChange={handleChange('sendLater')}
                    />
                  </label>
                </div>
                {messaging.sendLater ? (
                  <div className='row'>
                    <div className='form-group col-md-6 col-sm-6 col-12'>
                      <label>Date</label>
                      <input
                        type='date'
                        value={messaging.sendDate}
                        className='form-control'
                        onChange={handleChange('sendDate')}
                        required={messaging.sendLater}
                        min={messaging.sendDate}
                      />
                    </div>
                    <div className='form-group col-md-4 col-sm-4 col-6'>
                      <label>Time ( e.g 12:34) </label>
                      <input
                        type='time'
                        max={'12:00'}
                        className='form-control'
                        value={messaging.sendTime}
                        onChange={handleChange('sendTime')}
                        required={messaging.sendLater}
                      />
                    </div>
                    <div className='form-group col-md-2 col-sm-2 col-6'>
                      <label>.</label>
                      <select
                        className='custom-select'
                        value={messaging.meridian}
                        onChange={handleChange('meridian')}
                      >
                        <option>AM</option>
                        <option>PM</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className='mb-5 mt-4'>
                <button className='btn btn-primary btn-md' type='submit' onClick={sendMessage}>
                  Send messages
                  {isBtnLoading ? (
                    <span>
                      <ButtonLoader />
                    </span>
                  ) : (
                    ''
                  )}
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Messaging;
