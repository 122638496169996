export interface IBloodSugarReading {
  readingId: number;
  readingTime: string;
  glucose: number;
  patientId: number;
  readingType: string;
  dateCreated: string;
  dateUpdated: string;
}

export interface IBloodSugarReadingDTO {
  patientId: number;
  readingDate: string;
  readingTime: string;
  readingType: string;
  glucose: number;
}

export const defaultBloodSugarReading: IBloodSugarReadingDTO = {
  patientId: 0,
  readingDate: new Date().toISOString().split('T')[0],
  readingTime: new Date().toLocaleTimeString(),
  readingType: 'FastingBloodSugar',
  glucose: 1,
};
