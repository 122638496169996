export interface IVaccinationInterface {
  fullName: string;
  phoneNumber: string;
  relationship: 'Parent' | 'Guardian' | 'Others';
  gender: 'Female' | 'Male' | 'Others';
  hasHeardOfVaccine: boolean;
  hasWardReceivedVaccine: boolean;
  didDiscussVaccine: boolean;
  willingToDiscussVaccine: boolean;
  isReferredToHealthFacility: boolean;
  commitedToVaccination: boolean;
  additionalNotes: string;
  wards: Array<IWardDTO>;
}

export interface IWardDTO {
  id: number;
  fullName: string;
  age: number;
  isAttendingSchool: boolean;
  ageRange: '9 - 11' | '12 - 14' | '15 - 17';
}

export interface IVaccinationSummary {
  totalCount: number;
  totalForPharmacy: number;
}

export interface IVaccinationReport {
  fullName: string;
  phoneNumber: string;
  relationship: number;
  wards: IWardDTO[];
  gender: number;
  hasHeardOfVaccine: boolean;
  hasWardReceivedVaccine: boolean;
  didDiscussVaccine: boolean;
  willingToDiscussVaccine: boolean;
  isReferredToHealthFacility: boolean;
  commitedToVaccination: boolean;
  stateOfPremise: string;
  additionalNotes: string;
  vaccineType: number;
  id: string;
  dateCreated: string;
  pharmacyName: string;
  pharmacyCode: string;
  username: string;
  caregiverGender: string;
  caregiverRelationship: string;
  vaccinationType: string;
}

export const defaultSummary: IVaccinationSummary = {
  totalCount: 0,
  totalForPharmacy: 0,
};

export const defaultVaccinationData: IVaccinationInterface = {
  fullName: '',
  phoneNumber: '',
  relationship: 'Parent',
  gender: 'Others',
  hasHeardOfVaccine: false,
  hasWardReceivedVaccine: false,
  didDiscussVaccine: false,
  willingToDiscussVaccine: false,
  isReferredToHealthFacility: false,
  commitedToVaccination: false,
  additionalNotes: '',
  wards: [],
};

export const defaultWard: IWardDTO = {
  id: 0,
  fullName: '',
  age: 9,
  isAttendingSchool: false,
  ageRange: '9 - 11',
};
