import { PageTourProps } from '../interface/CommonInterface';

export const walletTourSteps: PageTourProps[] = [
  {
    target: '.pharmacy-view',
    title: 'Pharmacy View',
    content: <h6>Search a pharmacy to view wallet details</h6>,
    event: 'hover',
    disableBeacon: true,
  },
  {
    target: '.balance-view',
    title: 'Balance View',
    content: <h6>Available balance</h6>,
    event: 'hover',
  },
  {
    target: '.total-inflow',
    title: 'Total Inflow',
    content: <h6>Total amount credited</h6>,
    event: 'hover',
  },
  {
    target: '.total-outflow',
    title: 'Total Outflow',
    content: <h6>Total amount debited</h6>,
    event: 'hover',
  },
  {
    target: '.transaction-history',
    title: 'Transaction History',
    content: <h6>View transaction history</h6>,
    event: 'hover',
  },
];

export const entrySteps: PageTourProps[] = [
  {
    target: '.logo-header',
    title: 'Welcome',
    content: (
      <h6>
        Welcome to the new and improved version of WellaHealth Engage. Click next to get started
      </h6>
    ),
    event: 'hover',
    disableBeacon: true,
  },
  {
    target: '.entry',
    title: 'Entry',
    content: <h6>View and Manage Patients</h6>,
    event: 'hover',
  },
  {
    target: '.dashboard',
    title: 'Dashboard',
    content: <h6>View your pharmacy dashboard</h6>,
    event: 'hover',
  },
  {
    target: '.wallets',
    title: 'Wallets',
    content: <h6>View financial history of your pharmacy</h6>,
    event: 'hover',
  },
  {
    target: '.settings',
    title: 'Settings',
    content: <h6>View/Change passwords and other settings</h6>,
    event: 'hover',
  },
  {
    target: '.admin',
    title: 'Admin',
    content: <h6>Enter the admin section of the application</h6>,
    event: 'hover',
  },

  {
    target: '.expiry',
    title: 'Expiration Date',
    content: <h6>Expiration date of subscription</h6>,
    event: 'hover',
  },
  {
    target: '.smsCredit',
    title: 'SMS Credits',
    content: <h6>Number of SMS credits available</h6>,
    event: 'hover',
  },

  {
    target: '.viewPatients',
    title: 'View Patients',
    content: <h6>View and manage patients</h6>,
    event: 'hover',
  },
  {
    target: '.sendMessages',
    title: 'Send Messages',
    content: <h6>Send messages to patients</h6>,
    event: 'hover',
  },
  {
    target: '.viewRefills',
    title: 'View Refills',
    content: <h6>View and manage refills</h6>,
    event: 'hover',
  },
  {
    target: '.tasks',
    title: 'Tasks and Notifications',
    content: <h6>View and manage notifications</h6>,
    event: 'hover',
  },
];

export const headerSteps: PageTourProps[] = [
  {
    target: '.entry',
    title: 'Entry',
    content: <h6>View and Manage Patients</h6>,
    event: 'hover',
    disableBeacon: true,
  },
  {
    target: '.dashboard',
    title: 'Dashboard',
    content: <h6>View your pharmacy dashboard</h6>,
    event: 'hover',
  },
  {
    target: '.wallets',
    title: 'Wallets',
    content: <h6>View financial history of your pharmacy</h6>,
    event: 'hover',
  },
  {
    target: '.settings',
    title: 'Settings',
    content: <h6>View/Change passwords and other settings</h6>,
    event: 'hover',
  },
  {
    target: '.admin',
    title: 'Admin',
    content: <h6>Enter the admin section of the application</h6>,
    event: 'hover',
  },
];

export const menuTourSteps: PageTourProps[] = [
  {
    target: '.expiry',
    title: 'Expiration Date',
    content: <h6>Expiration date of subscription</h6>,
    event: 'hover',
    disableBeacon: true,
  },
  {
    target: '.smsCredit',
    title: 'SMS Credits',
    content: <h6>Number of SMS credits available</h6>,
    event: 'hover',
  },
  {
    target: '.topup',
    title: 'Top Up Button',
    content: <h6>Top up your SMS credits</h6>,
    event: 'hover',
  },
  {
    target: '.viewPatients',
    title: 'View Patients',
    content: <h6>View and manage patients</h6>,
    event: 'hover',
  },
  {
    target: '.sendMessages',
    title: 'Send Messages',
    content: <h6>Send messages to patients</h6>,
    event: 'hover',
  },
  {
    target: '.viewRefills',
    title: 'View Refills',
    content: <h6>View and manage refills</h6>,
    event: 'hover',
  },
  {
    target: '.tasks',
    title: 'Tasks and Notifications',
    content: <h6>View and manage notifications</h6>,
    event: 'hover',
  },
];

export const adminTourSteps: PageTourProps[] = [
  {
    target: '.pharmacy-select',
    title: 'Pharmacy Dropdown',
    content: <h6>Select a pharmacy</h6>,
    event: 'hover',
    disableBeacon: true,
  },
  {
    target: '.pharmacy-details',
    title: 'Pharmacy Details',
    content: <h6>View details of selected Pharmacy</h6>,
    event: 'hover',
  },
  {
    target: '.pharmacies',
    title: 'Pharmacies',
    content: <h6>View all pharmacies in your account and add new pharmacies</h6>,
    event: 'hover',
  },
  {
    target: '.messages',
    title: 'Messages',
    content: <h6>View all messages sent by your pharmacy</h6>,
    event: 'hover',
  },
  {
    target: '.templates',
    title: 'Templates',
    content: <h6>View and manage SMS templates for messages sent out to customers</h6>,
    event: 'hover',
  },
  {
    target: '.users',
    title: 'Users',
    content: <h6>View and manage all users of your account</h6>,
    event: 'hover',
  },
  {
    target: '.wallet',
    title: 'Wallet',
    content: <h6>View all transactions of your pharmacy</h6>,
    event: 'hover',
  },
  {
    target: '.subscription',
    title: 'Subscription',
    content: <h6>View and manage your subscription</h6>,
    event: 'hover',
  },
  {
    target: '.go-to-pharmacy',
    title: 'Go to Pharmacy',
    content: <h6>Go to the selected pharmacy</h6>,
    event: 'hover',
  },
  {
    target: '.logout',
    title: 'Logout',
    content: <h6>Logout of your account</h6>,
    event: 'hover',
  },
];
