import AuthenticatedHeader from '../layout/AuthenticatedHeader';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { IPrivateRouteProps } from '../../interface/AppInterface';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
//import Footer from './Footer';
import Menu from '../layout/Menu';
import RefreshToast from '../common/RefreshToast';
import ScreenLoading from '../common/ScreenLoading';

const AuthenticatedLayout = ({ component: Component, ...rest }: IPrivateRouteProps) => {
  const { appState } = useContext(AppContext);
  const isAuthenticated: boolean = appState.isAuthenticated;
  const [isLoading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname.includes('entry') || history.location.pathname.includes('/')) {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
    return history.listen((location: any, action: any) => {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 5000);
    });
  }, [setLoading, history]);
  return (
    <div>
      <ScreenLoading />
      <RefreshToast />
      <AuthenticatedHeader />

      <span
        id='request-spinner'
        className='float-right spinner-border spinner-border-sm text-success request-spinner d-none'
        role='status'
        aria-hidden='true'
      ></span>
      {isLoading ? (
        <div className='progress progress-custom' style={{ height: '3px' }}>
          <div
            className='progress-bar progress-bar-custom'
            role='progressbar'
            aria-valuenow={0}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>
      ) : (
        ''
      )}
      <Menu />
      <div className='container'>
        <div className='row justify-content-center'>
          <Route
            {...rest}
            render={(props) =>
              isAuthenticated ? <Component {...props} /> : <Redirect to='/login' />
            }
          />
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default AuthenticatedLayout;
